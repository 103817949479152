import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Space,
  Table,
  Button,
  Popconfirm,
  Modal,
  Upload,
  message,
  Drawer,
  Switch,
  Image, Select
} from "antd";
import Resizer from "react-image-file-resizer";

import {getVideoList, addVideo, delVideo, updateVideo, getPageGroup, delPageGroup, addPageGroup,updatePageGroup} from "../../api/demo";
import { LoadingOutlined, PlusOutlined,PlusCircleTwoTone,MinusCircleTwoTone } from "@ant-design/icons";
import axios from "axios";
import { apiPerfix, resourceUrl } from "../../api/config";
import "./index.scss";

import ThreeChapter from "../threeChapter/index";
import Msg from "../Msg/index";
const { TextArea } = Input;

const Video = () => {
  const [chapterList, setchapterList] = useState([]);
  const [isModalVisible, setisModalVisible] = useState(false);
  const [htmlContent, sethtmlContent] = useState("");
  const [isShowPreview, setisShowPreview] = useState(false);
  const [nowChapterInfo, setnowChapterInfo] = useState({});

  const [isShowDrawer, setisShowDrawer] = useState(false);
  const [ShowThreeDrawer, setShowThreeDrawer] = useState(false);
  const [twoLevelInfo, settwoLevelInfo] = useState({});
  const [threeLevelInfo, setthreeLevelInfo] = useState({});
  const [threeColumn,setthreeColumn]=useState([])
  const [typeMsg,settypeMsg]=useState("明细管理")
  const expandableConfig = {
    expandIcon: ({ expanded, onExpand, record }) =>
        expanded ? (
            <MinusCircleTwoTone style={{fontSize:25}} onClick={e => onExpand(record, e)}></MinusCircleTwoTone>
        ) : (
            <PlusCircleTwoTone style={{fontSize:25}} onClick={e => onExpand(record, e)}></PlusCircleTwoTone>
        )
  };

  const columns = [
    // {
    //   title: "序号",
    //   dataIndex: "id",
    //   render: (a, i, b) => b + 1
    // },
    {
      title: "序号",
      dataIndex: "sort",
      key: "id"
    },
    {
      title: "标题",
      dataIndex: "title",
      key: "id"
    },
    // {
    //   title: "图片展示方式",
    //   dataIndex: "piclistType",
    //   key: "id",
    //   render: (a) => (a==0 ? "垂直": "横向")
    // },
    {
      title: "操作",
      key: "action",
      render: (_, record, index) => record.menuType!=0?(
        <Space size="middle">
          <Button
            type="primary"
            block
            onClick={() => editChapter(record, index)}
          >
            编辑
          </Button>
          <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delChapter(record)}
          okText="确定"
          cancelText="取消"
        >
          <Button type="danger" block>
            删除
          </Button></Popconfirm>
          <Button
              type="primary"
              block
              onClick={() => addfz(record)}
          >
            管理分组
          </Button>
          {/* <Button
              type="primary"
              block
              onClick={() => sjManager(record, index)}
          >
            明细管理
          </Button>
          <Button
              type="primary"
              block
              onClick={() => lbtManager(record, index)}
          >
            轮播图管理
          </Button> */}
          <Button type="danger" block onClick={() => manageTwoLevel(record)}>
            管理三级
          </Button>
          {/* <Button type="danger" block onClick={() => detailChapter(record)}>详情</Button> */}
        </Space>
      ):(
          <Space size="middle">
            <Button
                type="primary"
                block
                onClick={() => editChapter(record, index)}
            >
              编辑
            </Button>
            <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delChapter(record)}
          okText="确定"
          cancelText="取消"
        >
            <Button type="danger" block>
              删除
            </Button></Popconfirm>
            <Button type="danger" block onClick={() => manageTwoLevel(record)}>
              管理三级
            </Button>
            {/* <Button type="danger" block onClick={() => detailChapter(record)}>详情</Button> */}
          </Space>
      )
    }
  ];

  const manageTwoLevel = (obj) => {
    console.log(obj)
    setisShowDrawer(true);
    settwoLevelInfo(obj);
  };

  const closeTwoLevel = () => {
    setisShowDrawer(false);
  };
  const closeThreeLevel=()=>{
    setShowThreeDrawer(false)
  }

  // 编辑 handler
  const editChapter = (obj) => {
    let index = chapterList.findIndex((v) => v.id === obj.id);
    obj.indexsort = index + 1;
    setnowChapterInfo(obj);
    setisModalVisible(true);
  };
  const sjManager=(obj)=>{
    settypeMsg("明细管理")
    setthreeColumn([
      {
        title: "序号",
        dataIndex: "sort",
        key: "id"
      },
      {
        title: "标题",
        dataIndex: "title",
        key: "id"
      },
      {
        title: "媒体类型",
        dataIndex: "mediaType",
        key: "id"
      },
      {
        title: "链接",
        dataIndex: "picUrl",
        key: "id"
      },
      {
        title: "备注",
        dataIndex: "remark",
        key: "id"
      },
      {
        title: "操作",
        key: "action",
        render: (_, record, index) => (
          <Space size="middle">
            <Button
              type="primary"
              block
              onClick={() => editChapter(record, index)}
            >
              编辑
            </Button>
            <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delmMore(record,"明细管理")}
          okText="确定"
          cancelText="取消"
        >
            <Button type="danger" block>
              删除
            </Button></Popconfirm>
          </Space>
        )
      }
    ])
    setShowThreeDrawer(true)
    setthreeLevelInfo(obj);
  };

  const lbtManager=(obj)=>{
    settypeMsg("轮播图管理")
    setthreeColumn([
      {
        title: "序号",
        dataIndex: "sort",
        key: "id"
      },
      {
        title: "图片地址",
        dataIndex: "picUrl",
        render: (a) => (a ? <Image width={200} src={a} /> : "暂无链接"),
        key: "id"
      },
      {
        title: "操作",
        key: "action",
        render: (_, record, index) => (
          <Space size="middle">
            <Button
              type="primary"
              block
              onClick={() => editChapter(record, index)}
            >
              编辑
            </Button>
            <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delmMore(record,"轮播图管理")}
          okText="确定"
          cancelText="取消"
        >
            <Button type="danger" block>
              删除
            </Button></Popconfirm>
          </Space>
        )
      }
    ])
    setShowThreeDrawer(true)
    setthreeLevelInfo(obj);
  };

  // 详情
  // const detailChapter = (obj) => {
  //   setisShowPreview(true);
  //   sethtmlContent(obj.content);
  // };

  // 删除
  const delChapter = (obj) => {
    delPageGroup({
      id: obj.id
    }).then((res) => {
      let arr = [...chapterList];
      let index = chapterList.findIndex((v) => v.id === obj.id);
      arr.splice(index, 1);
      setchapterList([...arr]);
      message.success("删除成功");
    });
  };
  const delmMore = (obj,msg) => {
    if(msg==="明细管理"){
      //删除明细
    }else{
      //删除轮播图
    }
  };


  // 新增
  const addDialog = () => {
    let arr = [...chapterList];
    console.log("arr");
    console.log(arr);
    setnowChapterInfo({
      sort: arr.length + 1,
      treeLimit:1
    });
    setTimeout(() => {
      setisModalVisible(true);
    }, 0);
  };

  const addfz = (obj) => {
    setnowChapterInfo({parentId:obj.id});
    setTimeout(() => {
      setisModalVisible(true);
    }, 0);
  };

  // 关闭Modal
  const cancle = () => {
    setisModalVisible(false);
  };

  // 列表
  const getList = () => {
    getPageGroup().then((res) => {
      console.log(res)
      res.data.forEach((element, indexsort) => {
        element.key = element.id;
        element.indexsort = indexsort + 1;
      });
      setchapterList([...res.data]);
    });
  };

  // 监听Modal
  useEffect(() => {
    if (!isModalVisible) {
      getList();
      setnowChapterInfo({});
    }
  }, [isModalVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  // 初始化
  useEffect(() => {
    getList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="chapterContainer">
      {isModalVisible && (
        <EditCom
          cancle={cancle}
          isModalVisible={isModalVisible}
          chapterInfo={nowChapterInfo}
          chapterList={chapterList}
        ></EditCom>
      )}

      <TwoLevelCom
        info={twoLevelInfo}
        closeTwoLevel={closeTwoLevel}
        isShowDrawer={isShowDrawer}
      ></TwoLevelCom>
      <ThreeLevelCom 
      info={threeLevelInfo}
      closeThreeLevel={closeThreeLevel}
      isShowDrawer={ShowThreeDrawer}
      columns={threeColumn}
      typeMsg={typeMsg}
      lx={'video'}
      >
      </ThreeLevelCom>
      <div className="addWrapper">
        <Button type="primary" block onClick={addDialog}>
          新增
        </Button>
      </div>
      <div className="tableList">
        <Table columns={columns} dataSource={chapterList} expandable={expandableConfig} />
      </div>

      <Modal
        title="预览详情"
        footer={null}
        visible={isShowPreview}
        onOk={() => setisShowPreview(false)}
        onCancel={() => setisShowPreview(false)}
      >
        <div
          className=""
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        ></div>
      </Modal>
    </div>
  );
};

const TwoLevelCom = (props) => {
  const onClose = () => {
    props.closeTwoLevel();
  };

  useEffect(() => {
    console.log(props.isShowDrawer);
  });

  return (
    <div>
      <Drawer
        width={1000}
        title={props.info.title}
        placement="right"
        onClose={onClose}
        visible={props.isShowDrawer}
      >
        {props.isShowDrawer && <ThreeChapter parentId={0} videoId={props.info.id} nowLimit={1}></ThreeChapter>}
      </Drawer>
    </div>
  );
};

const ThreeLevelCom = (props) => {
  const onClose = () => {
    props.closeThreeLevel();
  };

  return (
    <div>
      <Drawer
        width={1000}
        title={props.info.title}
        placement="right"
        onClose={onClose}
        visible={props.isShowDrawer}
      >
        {props.isShowDrawer && <Msg parentId={0} videoId={props.info.id} lx={props.lx} typeMsg={props.typeMsg} msg={'video'} columns={props.columns}></Msg>}
      </Drawer>
    </div>
  );
};

// 编辑

const EditCom = (props) => {
  const htmlVal = useRef("");
  const [loading, setloading] = useState(false);

  const [nowUser, setnowUser] = useState({});

  const [myform] = Form.useForm();
  myform.setFieldsValue(props.chapterInfo);

  useEffect(() => {
    setnowUser(props.chapterInfo);
  }, [props.chapterInfo]);

  const handleChange=(val)=>{
    console.log("图片展示方式",val)
    setnowUser({ ...nowUser, picListType: val });
  }
  const handlemenuChange=(val)=>{
    console.log("菜单类型",val)
    if(val==0){
      props.chapterInfo.treeLimit=1;
    }
    setnowUser({ ...nowUser, menuType: val });
  }

  const handleOk = () => {
    console.log("1");
  };

  const handleCancel = () => {
    props.cancle();
  };

  const handleEditorChange = (editorValue) => {
    let html = editorValue.toHTML();
    htmlVal.current = html;
  };

  const onFinish = (vals) => {
    // vals.treeLimit=parseInt(vals.treeLimit)
    if(vals.sort){
      vals.sort=parseInt(vals.sort)
    }
    // 分情况是编辑还是新增
    if (props.chapterInfo.id) {
      delete vals.upload;
      let data={
        ...vals
      }
      if(props.chapterInfo.parentId!=0){
        data.parentId=props.chapterInfo.parentId
        data.id=props.chapterInfo.id
      }else{
        data.id=props.chapterInfo.id
      }
      updatePageGroup(data).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          href: "",
          content: ""
        });
        message.success("更新成功");
      });
    } else {
      if(props.chapterInfo.parentId!=0){
        addPageGroup({
          ...vals,
          parentId:props.chapterInfo.parentId
        }).then((res) => {
          props.cancle();
          myform.setFieldsValue({
            href: "",
            content: ""
          });
          message.success("新增成功");
        });
      }else{
        addPageGroup({
          ...vals
        }).then((res) => {
          props.cancle();
          myform.setFieldsValue({
            href: "",
            content: ""
          });
          message.success("新增成功");
        });
      }
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const beforeUpload = (file1) => {
    // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    // if (!isJpgOrPng) {
    //   message.error('只能上传图片!');
    // }
        var forms = new FormData();
        var configs = {
          headers: { "Content-Type": "multipart/form-data" }
        };
        forms.append("file", file1);
        setloading(true);
        axios
          .post(`${apiPerfix}/upload/qiniu`, forms, configs)
          .then((res) => {
            setloading(false);
            setnowUser({ ...nowUser, href: res });
          })
          .catch(() => {
            setloading(true);
          });

    return false;
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onChange = (checked) => {
    setnowUser({...nowUser,isTop:checked})
    console.log(`switch to ${checked}`);
  };

  return (
    <Modal
      title={props.chapterInfo.title ? (props.chapterInfo.parentId!=0?'编辑子级':"编辑") : (props.chapterInfo.parentId!=0?'新增子级':"新增三级管理")}
      footer={null}
      width={800}
      visible={props.isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {props.isModalVisible ? (
        <div className="editWrapper">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            form={{ ...myform }}
          >

            <Form.Item
                label="序号"
                name="sort"
            >
              <Input type="number" />
            </Form.Item>

            <Form.Item
              label="标题"
              name="title"
            >
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default Video;
