import { Col, Divider, Row, Card } from 'antd';
import { Button } from 'antd';
 import './index.scss'
const { Meta } = Card;
const MediuItem = (props) => {
  return (
    <div className="name">

      <Row justify="space-around">
        {
          props.list.map(item => (
            <Col className='colitem' span={6} style={
              {
                marginBottom: "16px"
              }
            }>
            <a href={item.href} target="_blank">
              <Card
                hoverable
                style={{ width: 240 }}
                cover={
                  props.type === 1 ? <img alt="加载中..." src={item.href} /> : props.type === 2 ? <video style={{height: '100px'}} src={item.href}/> : props.type === 3 ? <img alt="加载中..." src={item.logo} /> : ''
              }
              >
                <Meta title={item.title} description={item.detail} />
                {/* <div className='download'>
                  <Button type="primary" size='small'>点击下载</Button>
                </div> */}
              </Card>
              </a>
            </Col>
          ))
        }
      </Row>


    </div>
  )
}
export default MediuItem;