import React, { useEffect, useRef, useState } from "react";
import { Space, Table, Button, Modal, message, Drawer,Popconfirm } from "antd";
import * as qiniu from "qiniu-js"
import Resizer from "react-image-file-resizer";
import {
  getChapterList,
  addNewChapter,
  delNewChapter,
  addNewInvite,
  updateChapter,
  getChapterDetailDetail
} from "../../api/demo";
import { Form, Input, Image, Upload } from "antd";
import "./index.scss";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { apiPerfix, resourceUrl } from "../../api/config";
import { ContentUtils } from "braft-utils";
// 富文本
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";

const AddInvite = (props) => {
  const onFinish = (vals) => {
    props.submit(vals);
  };

  return (
      <Modal
          title="添加邀请码"
          onCancel={props.close}
          footer={null}
          visible={props.isShowAddInvite}
      >
        <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
        >
          <Form.Item
              label="邀请码"
              name="inviteCode"
              rules={[{ required: true, message: "请输入邀请码!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
              label="代理ID"
              name="proxyId"
              rules={[{ required: true, message: "请输入代理ID!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Modal>
  );
};

const Kuanshi = (props) => {
  const [chapterList, setchapterList] = useState([]);
  const [isModalVisible, setisModalVisible] = useState(false);
  const [htmlContent, sethtmlContent] = useState("");
  const [isShowPreview, setisShowPreview] = useState(false);
  const [isShowAddInvite, setisShowAddInvite] = useState(false);
  const [nowChapterInfo, setnowChapterInfo] = useState({});
  const [isShowDrawer, setisShowDrawer] = useState(false);
  const [twoLevelInfo, settwoLevelInfo] = useState({});

  const columns = [
    {
      title: "序号",
      dataIndex: "sort",
      key:"id"
    },
    {
      title: "标题",
      dataIndex: "title",
      key: "id"
    },
    {
      title: "视频",
      dataIndex: "appHref",
      key: "id",
      render: (a) => (a ? <video width={200} controls><source src={a} /></video> : "暂无链接")
      // render: (a) =>
      //   a ? (
      //     <a href={a} target="_blank">
      //       {a}
      //     </a>
      //   ) : (
      //     "暂无链接"
      //   )
    },
    {
      title: "封面",
      dataIndex: "thumbs",
      key: "id",
      render: (a) => (a ? <Image width={200} src={a} /> : "暂无封面")
      // render: (a) =>
      //   a ? (
      //     <a href={a} target="_blank">
      //       {a}
      //     </a>
      //   ) : (
      //     "暂无链接"
      //   )
    },
    {
      title: "点赞数",
      dataIndex: "zans",
      key: "id"
    },
    {
      title: "播放数",
      dataIndex: "bos",
      key: "id"
    },
    // {
    //   title: "序号",
    //   dataIndex: "sort",
    //   key: "id"
    // },
    {
      title: "操作",
      key: "action",
      render: (_, record, index) => (
          <Space size="middle">
            <Button
                type="primary"
                block
                onClick={() => editChapter(record, index)}
            >
              编辑
            </Button>
            <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delChapter(record)}
          okText="确定"
          cancelText="取消"
        >
            <Button type="danger" block>
              删除
            </Button></Popconfirm>
            {/* <Button type="danger" block onClick={() => detailChapter(record)}>
            详情
          </Button> */}
            {/* <Button type="danger" block onClick={() => setInviteCode(record)}>
            生成邀请码
          </Button> */}
          </Space>
      )
    }
  ];

  const manageTwoLevel = (obj) => {
    setisShowDrawer(true);
    console.log("obj");
    console.log(obj);
    settwoLevelInfo(obj);
  };

  const closeTwoLevel = () => {
    setisShowDrawer(false);
  };

  const editChapter = (obj) => {
    getChapterDetailDetail({
      id: obj.id
    }).then((res) => {
      let index = chapterList.findIndex((v) => v.id === obj.id);
      res.indexsort = index + 1;
      setnowChapterInfo(res);
    });
    setisModalVisible(true);
  };

  const setInviteCode = (obj) => {
    setnowChapterInfo(obj);
    setisShowAddInvite(true);
  };

  const saveNewInviteCode = (obj) => {
    addNewInvite({
      ...obj
    }).then((res) => {
      setisShowAddInvite(false);
      message.success("新增成功");
    });
  };

  const detailChapter = (obj) => {
    setisShowPreview(true);
    sethtmlContent(obj.content);
  };

  const delChapter = (obj) => {
    delNewChapter({
      id: obj.id
    }).then((res) => {
      let arr = [...chapterList];
      let index = chapterList.findIndex((v) => v.id === obj.id);
      arr.splice(index, 1);
      getList();
      message.success("删除成功");
    });
  };

  const addDialog = () => {
    let arr = [...chapterList];
    setnowChapterInfo({
      indexsort: arr.length + 1
    });
    setTimeout(() => {
      setisModalVisible(true);
    }, 0);
  };

  const cancle = () => {
    setisModalVisible(false);
  };

  const addInviteSubmit = (vals) => {
    saveNewInviteCode({
      ...vals,
      ...nowChapterInfo,
      chapterId: nowChapterInfo.id,
      viewCount: 0
    });
  };

  const closeAddInviteDialog = () => {
    setisShowAddInvite(false);
  };

  const getList = () => {
    getChapterList({
      parentId: props.parentId ? props.parentId : -1
    }).then((res) => {
      if (Array.isArray(res)) {
        res.forEach((element, indexsort) => {
          element.key = element.id;
          element.indexsort = indexsort + 1;
        });
        console.log("res");
        console.log(res);
        setchapterList([...res]);
      }
    });
  };

  useEffect(() => {
    if (!isModalVisible) {
      getList();
      setnowChapterInfo({});
    }
  }, [isModalVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
      <div className="chapterContainer">
        <TwoLevelCom
            info={twoLevelInfo}
            closeTwoLevel={closeTwoLevel}
            isShowDrawer={isShowDrawer}
        ></TwoLevelCom>
        {isModalVisible && (
            <EditCom
                cancle={cancle}
                isModalVisible={isModalVisible}
                chapterInfo={nowChapterInfo}
                parentId={props.parentId}
                chapterList={chapterList}
            ></EditCom>
        )}

        <div className="addWrapper">
          <Button type="primary" block onClick={addDialog}>
            新增
          </Button>
        </div>
        <div className="tableList">
          <Table columns={columns} dataSource={chapterList} />
        </div>

        <Modal
            title="预览详情"
            footer={null}
            visible={isShowPreview}
            onOk={() => setisShowPreview(false)}
            onCancel={() => setisShowPreview(false)}
        >
          <div
              className=""
              dangerouslySetInnerHTML={{ __html: htmlContent }}
          ></div>
        </Modal>
        <AddInvite
            close={closeAddInviteDialog}
            isShowAddInvite={isShowAddInvite}
            submit={addInviteSubmit}
        ></AddInvite>
      </div>
  );
};

const TwoLevelCom = (props) => {
  const onClose = () => {
    props.closeTwoLevel();
  };

  return (
      <Drawer
          title="Basic Drawer"
          placement="right"
          onClose={onClose}
          open={props.isShowDrawer}
      >
        <div>{}</div>
      </Drawer>
  );
};

// 编辑

const EditCom = (props) => {
  const htmlVal = useRef("");
  const [nowUser, setnowUser] = useState({});
  const [loading, setloading] = useState(false);
  const [loading2, setloading2] = useState(false);

  const [htmlStr, sethtmlStr] = useState(
      BraftEditor.createEditorState("<p></p>")
  );

  useEffect(() => {
    setnowUser(props.chapterInfo);
  }, [props.chapterInfo]);

  const [myform] = Form.useForm();
  myform.setFieldsValue(props.chapterInfo);

  useEffect(() => {
    sethtmlStr(BraftEditor.createEditorState(props.chapterInfo.content || ""));
  }, [props.chapterInfo.content]);

  const handleOk = () => {
    console.log("1");
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const normFile2=(e)=>{
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList2;
  }

  const handleCancel = () => {
    props.cancle();
  };

  const handleEditorChange = (editorValue) => {
    let html = editorValue.toHTML();
    htmlVal.current = html;
    sethtmlStr(html);
  };
  const beforeUpload2 = (file1) => {
      var forms = new FormData();
      var configs = {
        headers: { "Content-Type": "multipart/form-data" }
      };
      forms.append("file", file1);
      setloading(true);
      axios
          .post(`${apiPerfix}/upload/qiniu`, forms, configs)
          .then((res) => {
            setloading(false);
            setnowUser({ ...nowUser, thumbs: res });
          })
          .catch(() => {
            setloading(true);
          });
  return false;
  };
  const beforeUpload = (file1) => {
    setloading2(true);
          // var forms = new FormData();
          // var configs = {
          //   headers: { "Content-Type": "multipart/form-data" }
          // };
          // forms.append("file", file1);
          // setloading(true);
          // axios
          //     .post(`${apiPerfix}/upload/qiniu`, forms, configs)
          //     .then((res) => {
          //       setloading(false);
          //       setnowUser({ ...nowUser, appHref: res });
          //     })
          //     .catch(() => {
          //       setloading(true);
          //     });
      axios
        .post(`${apiPerfix}/upload/token`)
        .then((res) => {
          console.log("token=",res)
          let uploadToken=res;
          let config = {
            useCdnDomain: true,         // 表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
            region: null     // 上传域名区域（z1为华北）,当为 null 或 undefined 时，自动分析上传域名区域
          };

          let putExtra = {
            params: {},         // 放置自定义变量： 'x:name': 'sex'
            mimeType: null      // 限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
          };

          // observable是一个带有 subscribe 方法的类实例
          var observable = qiniu.upload(file1, null, uploadToken, putExtra, config);
          var subscription = observable.subscribe({   // 上传开始
            next: (result) => {
              // 接收上传进度信息，result是带有total字段的 Object
              // loaded: 已上传大小; size: 上传总信息; percent: 当前上传进度
              console.log("上传中",result);
            },
            error: (errResult) => {
              // 上传错误后失败报错
              message.error(errResult)
              console.log(errResult)
              
          setloading2(false);
            },
            complete: (result) => {
              // 接收成功后返回的信息
              console.log(result);
              
          setloading2(false);
              setnowUser({ ...nowUser, appHref:  "http://tyreqiniu.maerodisc.cn/"+result.key });
            }
          })
        })
        .catch(() => {
          message.error("token获取失败")
          
          setloading2(false);
        });
    return false;
  };

  const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
  );
  const uploadButton2 = (
    <div>
      {loading2 ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
);

  const onFinish = (vals) => {
    // let VAL = 2000;
    // let sort = Math.floor(Number(vals.indexsort));
    // let allListl = [];
    // if (props.chapterInfo.id) {
    //   allListl = [...props.chapterList];
    // } else {
    //   allListl = [...props.chapterList].reverse();
    // }

    // let flag = false;
    // let maxVal = 0;
    // if (props.chapterInfo.id) {
    //   flag = sort > allListl.length;
    //   maxVal = allListl.length;
    // } else {
    //   flag = sort > allListl.length + 1;
    //   maxVal = allListl.length + 1;
    // }

    // if (sort == 0) {
    //   message.error("排序不能为0!");
    //   return;
    // } else if (flag) {
    //   message.error("目前排序最大值为" + maxVal);
    //   return;
    // }
    // let sortval = 0;
    // let ns = allListl[sort];
    // let ls = allListl[sort - 1];
    // if (!allListl[sort - 1]) {
    //   let l2 = allListl[sort - 2];
    //   sortval = Math.floor((l2 && l2.sort) || 0) + VAL;
    // } else if (!allListl[sort]) {
    //   sortval = Math.floor(allListl[sort - 1].sort / 2);
    // } else if (ns && ls) {
    //   sortval = Math.floor(
    //       (((ns && ns.sort) || 0) + ((ls && ls.sort) || 0)) / 2
    //   );
    // }
    delete vals.upload2
    delete vals.upload
    // 分情况是编辑还是新增
    if (props.chapterInfo.id) {
      updateChapter({
        ...vals,
        sort: Number(vals.sort),
        appHref: nowUser.appHref,
        thumbs: nowUser.thumbs,
        id: props.chapterInfo.id,
        content: htmlVal.current
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          appHref: "",
          content: ""
        });
        message.success("更新成功");
      });
    } else {
      addNewChapter({
        ...vals,
        sort: Number(vals.sort),
        appHref: nowUser.appHref,
        thumbs: nowUser.thumbs,
        content: htmlVal.current,
        parentId: props.parentId
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          appHref: "",
          content: ""
        });
        message.success("新增成功");
      });
    }
  };

  const controls = [
    "undo",
    "redo",
    "separator",
    "font-size",
    "line-height",
    "letter-spacing",
    "separator",
    "text-color",
    "bold",
    "italic",
    "underline",
    "strike-through",
    "separator",
    "superscript",
    "subscript",
    "remove-styles",
    "emoji",
    "separator",
    "text-indent",
    "text-align",
    "separator",
    "headings",
    "list-ul",
    "list-ol",
    "blockquote",
    "code",
    "separator",
    "link",
    "separator",
    "hr",
    "separator",
    "separator",
    "clear"
  ];

  const handleUpload = async (option) => {
    const formData = new FormData();
    formData.append("file", option.file);
    let configs = {
      headers: { "Content-Type": "multipart/form-data" }
    };
    axios
        .post(`${apiPerfix}/upload/qiniu`, formData, configs)
        .then((res) => {
          console.log("====================================");
          console.log(ContentUtils);
          console.log(
              ContentUtils.insertMedias(
                  BraftEditor.createEditorState(htmlVal.current),
                  [{ type: "IMAGE", url: res }]
              )
          );
          console.log("====================================");
          sethtmlStr(
              ContentUtils.insertMedias(
                  BraftEditor.createEditorState(htmlVal.current),
                  [{ type: "IMAGE", url: res }]
              )
          );
        })
        .catch(() => {});
  };

  const extendControls = [
    {
      key: "antd-uploader",
      type: "component",
      component: (
          <Upload
              showUploadList={false}
              customRequest={handleUpload}
              accept=".png,.jpg"
          >
            <Button
                className="control-item button upload-button"
                data-title="上传图片"
            >
              <PlusOutlined />
            </Button>
          </Upload>
      )
    }
  ];

  return (
      <Modal
          title={props.chapterInfo.title ? "编辑" : "新增文章"}
          footer={null}
          width={800}
          visible={props.isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
      >
        {props.isModalVisible ? (
            <div className="editWrapper">
              <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  autoComplete="off"
                  form={{ ...myform }}
              >
                <Form.Item
                    label="标题"
                    name="title"
                    rules={[{ required: true, message: "请输入标题!" }]}
                >
                  <Input />
                </Form.Item>

                <div>
                  <Form.Item
                      label="序号"
                      name="sort"
                      rules={[{ required: true, message: "请输入序号!" }]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </div>

                <Form.Item
                    label="链接"
                    name="upload"
                    valuePropName="fileList2"
                    getValueFromEvent={normFile2}
                >
                  <Upload
                      name="href"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action="/"
                      beforeUpload={beforeUpload}
                  >
                    {nowUser.appHref ? (
                      <video width={100} height={100} controls autoPlay><source src={nowUser.appHref} /></video>
                    ) : (
                        uploadButton2
                    )}
                  </Upload>
                  <div className="warnImgSize">视频比例为：2.5:1</div>
                </Form.Item>

                <Form.Item
                    label="视频封面"
                    name="upload2"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                >
                  <Upload
                      name="href"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action="/"
                      beforeUpload={beforeUpload2}
                  >
                    {nowUser.thumbs ? (
                        <img
                            src={nowUser.thumbs}
                            alt="avatar"
                            style={{
                              width: "100%",
                              height:'100px'
                            }}
                        />
                    ) : (
                        uploadButton
                    )}
                  </Upload>
                </Form.Item>

                {/* <Form.Item
              label="app链接"
              name="appHref"
              rules={[{ required: true, message: "请输入app链接!" }]}
            >
              <Input />
            </Form.Item> */}
                <Form.Item label="内容" name="content">
                  <div
                      style={{
                        height: "0px",
                        overflow: "hidden"
                      }}
                  >
                    {JSON.stringify(htmlStr)}
                  </div>
                  <BraftEditor
                      controls={controls}
                      extendControls={extendControls}
                      style={{
                        border: "1px solid #999"
                      }}
                      value={htmlStr}
                      onChange={handleEditorChange}
                      placeholder="请输入内容"
                  ></BraftEditor>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="primary" htmlType="submit">
                    提交
                  </Button>
                </Form.Item>
              </Form>
            </div>
        ) : (
            ""
        )}
      </Modal>
  );
};

export default Kuanshi;