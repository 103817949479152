import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Space,
  Table,
  Button,
  Popconfirm,
  Modal,
  Upload,
  message,
  Drawer,
  Switch,
  Image, Select, Cascader
} from "antd";
import Resizer from "react-image-file-resizer";

import {
  getVideoList,
  addVideo,
  delVideo,
  updateVideo,
  getBlogBrandDynamic,
  delPageGroup,
  addPageGroup,
  updatePageGroup,
  updateBlogBrandDynamic,
  addBlogBrandDynamic,
  delBlogBrandDynamic, getBlogBrandCommonThirdPage, getPageGroup
} from "../../api/demo";
import { LoadingOutlined, PlusOutlined,PlusCircleTwoTone,MinusCircleTwoTone } from "@ant-design/icons";
import axios from "axios";
import { apiPerfix, resourceUrl } from "../../api/config";
import "./index.scss";

import ThreeChapter from "../threeChapter/index";
import Msg from "../Msg/index";
import * as qiniu from "qiniu-js";
const { TextArea } = Input;

const Video = () => {
  const [chapterList, setchapterList] = useState([]);
  const [threeList,setthreeList]=useState([])
  const [isModalVisible, setisModalVisible] = useState(false);
  const [htmlContent, sethtmlContent] = useState("");
  const [isShowPreview, setisShowPreview] = useState(false);
  const [nowChapterInfo, setnowChapterInfo] = useState({});

  const [isShowDrawer, setisShowDrawer] = useState(false);
  const [ShowThreeDrawer, setShowThreeDrawer] = useState(false);
  const [twoLevelInfo, settwoLevelInfo] = useState({});
  const [threeLevelInfo, setthreeLevelInfo] = useState({});
  const [threeColumn,setthreeColumn]=useState([])
  const [typeMsg,settypeMsg]=useState("明细管理")
  const expandableConfig = {
    expandIcon: ({ expanded, onExpand, record }) =>
        expanded ? (
            <MinusCircleTwoTone style={{fontSize:25}} onClick={e => onExpand(record, e)}></MinusCircleTwoTone>
        ) : (
            <PlusCircleTwoTone style={{fontSize:25}} onClick={e => onExpand(record, e)}></PlusCircleTwoTone>
        )
  };

  const columns = [
    // {
    //   title: "序号",
    //   dataIndex: "id",
    //   render: (a, i, b) => b + 1
    // },
    {
      title: "序号",
      dataIndex: "sort",
      key: "id"
    },
    {
      title: "标题",
      dataIndex: "title",
      key: "id"
    },
    {
      title: "媒体类型",
      dataIndex: "mediaType",
      key: "id"
    },
    {
      title: "链接",
      dataIndex: "picUrl",
      key: "id",
      render: (a) => (a ? <Image width={200} src={a} /> : "暂无链接"),
    },
    {
      title: "视频封面",
      dataIndex: "thums",
      key: "id",
      render: (a,record) => (record.mediaType=='image'?"图片没有封面":(a ?<Image width={200} src={a} /> :'暂无链接'))
    },
    {
      title: "操作",
      key: "action",
      render: (_, record, index) =>
        <Space size="middle">
          <Button
            type="primary"
            block
            onClick={() => editChapter(record, index)}
          >
            编辑
          </Button>
          <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delChapter(record)}
          okText="确定"
          cancelText="取消"
        >
          <Button type="danger" block>
            删除
          </Button>
          </Popconfirm>
        </Space>
    }
  ];

  const manageTwoLevel = (obj) => {
    console.log(obj)
    setisShowDrawer(true);
    settwoLevelInfo(obj);
  };

  const closeTwoLevel = () => {
    setisShowDrawer(false);
  };
  const closeThreeLevel=()=>{
    setShowThreeDrawer(false)
  }

  // 编辑 handler
  const editChapter = (obj) => {
    let index = chapterList.findIndex((v) => v.id === obj.id);
    obj.indexsort = index + 1;
     if(obj.thirdPageIds){
      setnowChapterInfo({...obj,thirdPageIds:((obj.thirdPageIds).indexOf(',')!=-1?obj.thirdPageIds.split(',').map(Number):[parseInt(obj.thirdPageIds)])});
    }else{
      setnowChapterInfo({...obj})
    }
    setisModalVisible(true);
  };
  const sjManager=(obj)=>{
    settypeMsg("明细管理")
    setthreeColumn([
      {
        title: "序号",
        dataIndex: "sort",
        key: "id"
      },
      {
        title: "标题",
        dataIndex: "title",
        key: "id"
      },
      {
        title: "媒体类型",
        dataIndex: "mediaType",
        key: "id"
      },
      {
        title: "链接",
        dataIndex: "picUrl",
        key: "id"
      },
      {
        title: "备注",
        dataIndex: "remark",
        key: "id"
      },
      {
        title: "操作",
        key: "action",
        render: (_, record, index) => (
          <Space size="middle">
            <Button
              type="primary"
              block
              onClick={() => editChapter(record, index)}
            >
              编辑
            </Button>
            <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delmMore(record,"明细管理")}
          okText="确定"
          cancelText="取消"
        >
              <Button type="danger" block>
                删除
              </Button>
              </Popconfirm>
          </Space>
        )
      }
    ])
    setShowThreeDrawer(true)
    setthreeLevelInfo(obj);
  };

  const lbtManager=(obj)=>{
    settypeMsg("轮播图管理")
    setthreeColumn([
      {
        title: "序号",
        dataIndex: "sort",
        key: "id"
      },
      {
        title: "图片地址",
        dataIndex: "picUrl",
        render: (a) => (a ? <Image width={200} src={a} /> : "暂无链接"),
        key: "id"
      },
      {
        title: "操作",
        key: "action",
        render: (_, record, index) => (
          <Space size="middle">
            <Button
              type="primary"
              block
              onClick={() => editChapter(record, index)}
            >
              编辑
            </Button>
            <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delmMore(record,"轮播图管理")}
          okText="确定"
          cancelText="取消"
        >
          <Button type="danger" block>
              删除
            </Button>
        </Popconfirm>
          </Space>
        )
      }
    ])
    setShowThreeDrawer(true)
    setthreeLevelInfo(obj);
  };

  // 详情
  // const detailChapter = (obj) => {
  //   setisShowPreview(true);
  //   sethtmlContent(obj.content);
  // };

  // 删除
  const delChapter = (obj) => {
    delBlogBrandDynamic({
      id: obj.id
    }).then((res) => {
      let arr = [...chapterList];
      let index = chapterList.findIndex((v) => v.id === obj.id);
      arr.splice(index, 1);
      setchapterList([...arr]);
      message.success("删除成功");
    });
  };
  const delmMore = (obj,msg) => {
    if(msg==="明细管理"){
      //删除明细
    }else{
      //删除轮播图
    }
  };


  // 新增
  const addDialog = () => {
    let arr = [...chapterList];
    console.log("arr");
    console.log(arr);
    setnowChapterInfo({
      sort: arr.length + 1
    });
    setTimeout(() => {
      setisModalVisible(true);
    }, 0);
  };

  const addfz = (obj) => {
    setnowChapterInfo({parentId:obj.id});
    setTimeout(() => {
      setisModalVisible(true);
    }, 0);
  };

  // 关闭Modal
  const cancle = () => {
    setisModalVisible(false);
  };
  const getThree=()=>{
    getPageGroup().then(res=>{
      console.log('三级分组',res)
      let arr=[];
      res.data.forEach(item=>{
        arr.push(item)
      })
      setthreeList([...arr])
    })
  }
  // 列表
  const getList = () => {
    getBlogBrandDynamic().then((res) => {
      console.log(res)
      setchapterList([...res]);
    });
  };

  // 监听Modal
  useEffect(() => {
    if (!isModalVisible) {
      getList();
      setnowChapterInfo({});
      getThree();
    }
  }, [isModalVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  // 初始化
  useEffect(() => {
    getList();
    getThree();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="chapterContainer">
      {isModalVisible && (
        <EditCom
          cancle={cancle}
          isModalVisible={isModalVisible}
          chapterInfo={nowChapterInfo}
          chapterList={chapterList}
          threeList={threeList}
        ></EditCom>
      )}

      <TwoLevelCom
        info={twoLevelInfo}
        closeTwoLevel={closeTwoLevel}
        isShowDrawer={isShowDrawer}
      ></TwoLevelCom>
      <ThreeLevelCom 
      info={threeLevelInfo}
      closeThreeLevel={closeThreeLevel}
      isShowDrawer={ShowThreeDrawer}
      columns={threeColumn}
      typeMsg={typeMsg}
      lx={'video'}
      >
      </ThreeLevelCom>
      <div className="addWrapper">
        <Button type="primary" block onClick={addDialog}>
          新增
        </Button>
      </div>
      <div className="tableList">
        <Table columns={columns} dataSource={chapterList} />
      </div>

      <Modal
        title="预览详情"
        footer={null}
        visible={isShowPreview}
        onOk={() => setisShowPreview(false)}
        onCancel={() => setisShowPreview(false)}
      >
        <div
          className=""
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        ></div>
      </Modal>
    </div>
  );
};

const TwoLevelCom = (props) => {
  const onClose = () => {
    props.closeTwoLevel();
  };

  useEffect(() => {
    console.log(props.isShowDrawer);
  });

  return (
    <div>
      <Drawer
        width={1000}
        title={props.info.title}
        placement="right"
        onClose={onClose}
        visible={props.isShowDrawer}
      >
        {props.isShowDrawer && <ThreeChapter parentId={0} videoId={props.info.id} nowLimit={1}></ThreeChapter>}
      </Drawer>
    </div>
  );
};

const ThreeLevelCom = (props) => {
  const onClose = () => {
    props.closeThreeLevel();
  };

  return (
    <div>
      <Drawer
        width={1000}
        title={props.info.title}
        placement="right"
        onClose={onClose}
        visible={props.isShowDrawer}
      >
        {props.isShowDrawer && <Msg parentId={0} videoId={props.info.id} lx={props.lx} typeMsg={props.typeMsg} msg={'video'} columns={props.columns}></Msg>}
      </Drawer>
    </div>
  );
};

// 编辑

const EditCom = (props) => {
  const htmlVal = useRef("");
  const [loading, setloading] = useState(false);

  const [loading2, setloading2] = useState(false);
  const [nowUser, setnowUser] = useState({});

  const [myform] = Form.useForm();
  myform.setFieldsValue(props.chapterInfo);

  useEffect(() => {
    console.log(props.chapterInfo.thirdPageIds)
    let group=props.chapterInfo.thirdPageIds;
    if(group?.length>0){
      getBlogBrandCommonThirdPage({
        groupId: group[group.length-1]
      }).then((res) => {
        if (Array.isArray(res.data)) {
          // res.forEach((element, indexsort) => {
          //   element.key = element.id;
          //   element.indexsort = indexsort + 1;
          // });
          setnowUser({...props.chapterInfo,thums:props.chapterInfo.thums,picUrl:props.chapterInfo.picUrl,threeItemList:[...res.data]});
        }
      });
    }else{
      setnowUser({...props.chapterInfo,thums:props.chapterInfo.thums,picUrl:props.chapterInfo.picUrl,threeItemList:[]});
    }
    // setnowUser(props.chapterInfo);
  }, [props.chapterInfo]);

  const handleChange=(val)=>{
    console.log("图片展示方式",val)
    setnowUser({ ...nowUser, mediaType: val });
  }
  const handlemenuChange=(val)=>{
    console.log("菜单类型",val)
    if(val==0){
      props.chapterInfo.treeLimit=1;
    }
    setnowUser({ ...nowUser, menuType: val });
  }

  const handleOk = () => {
    console.log("1");
  };

  const handleCancel = () => {
    props.cancle();
  };

  const handleEditorChange = (editorValue) => {
    let html = editorValue.toHTML();
    htmlVal.current = html;
  };

  const onFinish = (vals) => {
    // 分情况是编辑还是新增
    if (props.chapterInfo.id) {
      delete vals.upload;
      let data={
        ...vals,
        mediaType: nowUser.mediaType?nowUser.mediaType:"image",
        thums:nowUser.mediaType=='video'?nowUser.thums:null,
        thirdPageIds: nowUser.thirdPageIds?nowUser.thirdPageIds.join(","):null,
      }
      data.id=props.chapterInfo.id
      data.picUrl=nowUser.picUrl
      updateBlogBrandDynamic(data).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          href: "",
          content: ""
        });
        message.success("更新成功");
      });
    } else {
      addBlogBrandDynamic({
        ...vals,
        mediaType: nowUser.mediaType?nowUser.mediaType:"image",
        thums:nowUser.mediaType=='video'?nowUser.thums:null,
        thirdPageIds: nowUser.thirdPageIds?nowUser.thirdPageIds.join(","):null,
        picUrl:nowUser.picUrl
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          href: "",
          content: ""
        });
        message.success("新增成功");
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const uploadButton2 = (
      <div>
        {loading2 ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
  );
  const handleChangeThree=(val)=>{
    getBlogBrandCommonThirdPage({
      groupId: val[val.length-1]
    }).then((res) => {
      if (Array.isArray(res.data)) {
        // res.forEach((element, indexsort) => {
        //   element.key = element.id;
        //   element.indexsort = indexsort + 1;
        // });
        setnowUser({ ...nowUser,thirdPageIds:val,threeItemList:[...res.data] });
      }
    });
  }
  const beforeUpload = (file1) => {
    setnowUser({ ...nowUser, picUrl:  null })
    setloading(true);
    axios
        .post(`${apiPerfix}/upload/token`)
        .then((res) => {
          console.log("token=",res)
          let uploadToken=res;
          let config = {
            useCdnDomain: true,         // 表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
            region: null     // 上传域名区域（z1为华北）,当为 null 或 undefined 时，自动分析上传域名区域
          };

          let putExtra = {
            params: {},         // 放置自定义变量： 'x:name': 'sex'
            mimeType: null      // 限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
          };

          // observable是一个带有 subscribe 方法的类实例
          var observable = qiniu.upload(file1, null, uploadToken, putExtra, config);
          var subscription = observable.subscribe({   // 上传开始
            next: (result) => {
              // 接收上传进度信息，result是带有total字段的 Object
              // loaded: 已上传大小; size: 上传总信息; percent: 当前上传进度
              console.log("上传中",result);
            },
            error: (errResult) => {
              // 上传错误后失败报错
              message.error(errResult)
              console.log(errResult)

              setloading(false);
            },
            complete: (result) => {
              // 接收成功后返回的信息
              console.log(result);

              setloading(false);
              setnowUser({ ...nowUser, picUrl:  "http://tyreqiniu.maerodisc.cn/"+result.key });
            }
          })
        })
        .catch(() => {
          message.error("token获取失败")

          setloading(false);
        });

    return false;
  };

  const beforeUpload2 = (file1) => {
    setloading2(true);
    axios
        .post(`${apiPerfix}/upload/token`)
        .then((res) => {
          console.log("token=",res)
          let uploadToken=res;
          let config = {
            useCdnDomain: true,         // 表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
            region: null     // 上传域名区域（z1为华北）,当为 null 或 undefined 时，自动分析上传域名区域
          };

          let putExtra = {
            params: {},         // 放置自定义变量： 'x:name': 'sex'
            mimeType: null      // 限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
          };

          // observable是一个带有 subscribe 方法的类实例
          var observable = qiniu.upload(file1, null, uploadToken, putExtra, config);
          var subscription = observable.subscribe({   // 上传开始
            next: (result) => {
              // 接收上传进度信息，result是带有total字段的 Object
              // loaded: 已上传大小; size: 上传总信息; percent: 当前上传进度
              console.log("上传中",result);
            },
            error: (errResult) => {
              // 上传错误后失败报错
              message.error(errResult)
              console.log(errResult)

              setloading2(false);
            },
            complete: (result) => {
              // 接收成功后返回的信息
              console.log(result);

              setloading2(false);
              setnowUser({ ...nowUser, thums:  "http://tyreqiniu.maerodisc.cn/"+result.key });
            }
          })
        })
        .catch(() => {
          message.error("token获取失败")

          setloading2(false);
        });
    return false;
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onChange = (checked) => {
    setnowUser({...nowUser,isTop:checked})
    console.log(`switch to ${checked}`);
  };

  return (
    <Modal
      title={props.chapterInfo.title ? "编辑": "新增动态"}
      footer={null}
      width={800}
      visible={props.isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {props.isModalVisible ? (
        <div className="editWrapper">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            form={{ ...myform }}
          >

            <Form.Item
                label="序号"
                name="sort"
            >
              <Input type="number" />
            </Form.Item>

            <Form.Item
              label="标题"
              name="title"
            >
              <Input />
            </Form.Item>

            <Form.Item
                label="媒体类型"
            >
              <Select
                  defaultValue={props.chapterInfo.mediaType?props.chapterInfo.mediaType:'image'}
                  style={{ width: 120 }}
                  onChange={handleChange}
                  options={[
                    {
                      value: "video",
                      label: 'video',
                    },
                    {
                      value: "image",
                      label: 'image',
                    }
                  ]}
              />
            </Form.Item>
            <Form.Item
                label="链接"
                name="upload"
                valuePropName="fileList"
                getValueFromEvent={normFile}
            >
              <Upload
                  name="href"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="/"
                  beforeUpload={beforeUpload}
              >
                {nowUser.picUrl ? (nowUser.mediaType=='video'?
                        <video width={100} height={100} controls autoPlay><source src={nowUser.picUrl} /></video>
                        :<img
                            src={nowUser.picUrl}
                            alt="avatar"
                            style={{
                              width: "100%",
                              height: "100px"
                            }}
                        />
                ) : (
                    uploadButton
                )}
              </Upload>
            </Form.Item>

            {nowUser.mediaType=='video'?<Form.Item
                label="视频封面"
                name="upload"
                valuePropName="fileList"
                getValueFromEvent={normFile}
            >
              <Upload
                  name="thums"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="/"
                  beforeUpload={beforeUpload2}
              >
                {nowUser.thums!=0&&nowUser.thums!=null ? (
                    <img
                        src={nowUser.thums}
                        alt="avatar"
                        style={{
                          width: "100%",
                          height: "100px"
                        }}
                    />
                ) : (
                    uploadButton2
                )}
              </Upload>
            </Form.Item>:''}

            <Form.Item
                label="关联的三级页面组"
            >
              <Cascader
                  changeOnSelect
                  defaultValue={props.chapterInfo.thirdPageIds}
                  fieldNames={{ label: 'title', value: 'id', children: 'children' }}
                  style={{ width: 250 }}
                  onChange={handleChangeThree}
                  options={props.threeList}
              />
            </Form.Item>

            <Form.Item
                label="关联的三级页面项"
                name="thirdPageId"
            >
              <Select
                  fieldNames={{ label: 'title', value: 'id'}}
                  defaultValue={nowUser.threeItemList?.length>0?props.chapterInfo.thirdPageId:'请选择组'}
                  style={{ width: 120 }}
                  options={nowUser.threeItemList}
              />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default Video;
