import React, { useEffect, useRef, useState } from "react";
import { Space, Table,Row,Col, Button, Modal, Upload, message,Popconfirm } from "antd";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip,BarChart,Bar } from 'recharts';
import { apiPerfix, resourceUrl } from "../../api/config";
import Resizer from "react-image-file-resizer";

import {
  getTjBasicList,
  getTjOnlineList,
  addhaibao,
  delhaibao,
  updatehaibao
} from "../../api/demo";
import { Form, Input } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Image } from "antd";
import axios from "axios";
import "./index.scss";
const { TextArea } = Input;

const Video = () => {
  const [chapterList, setchapterList] = useState([]);
  const [BasicMsg,setBasicMsg]=useState({})
  const [OnlineMsg,setOnlineMsg]=useState({})
  const [mytime,setMytime]=useState(null)
  const [mytime2,setMytime2]=useState(null)
  const [isModalVisible, setisModalVisible] = useState(false);
  const [htmlContent, sethtmlContent] = useState("");
  const [isShowPreview, setisShowPreview] = useState(false);
  const [nowChapterInfo, setnowChapterInfo] = useState({});
  const [data,setData] = useState([
      {date: '10:40', 'todayCount': 400, 'yesterdayCount': 312}])
  const [Bardata,setBardata] = useState([{name: '10:40', value: 400},{name: '10:50', value: 500},{name: '11:40', value: 212},{name: '12:40', value: 312},{name: '13:40', value: 112},{name: '14:40', value: 512},{name: '10:40', value: 400},{name: '10:50', value: 500},{name: '11:40', value: 212},{name: '12:40', value: 312},{name: '13:40', value: 112},{name: '14:40', value: 512}])
  const columns = [
    {
      title: "用户ID",
      dataIndex: "openId",
      key: "id"
    },
    // {
    //   title: "标题",
    //   dataIndex: "title",
    //   key: "id"
    // },
    {
      title: "图片",
      dataIndex: "picUrl",
      key: "id",
      render: (a) => (a ? <Image width={200} src={a} /> : "暂无" +
          "接")
    },
    // {
    //   title: "描述",
    //   dataIndex: "detail",
    //   key: "id"
    // },
    {
      title: "操作",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" block onClick={() => editChapter(record)}>
            编辑
          </Button>
          <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delChapter(record)}
          okText="确定"
          cancelText="取消"
        >
          <Button type="danger" block>
            删除
          </Button></Popconfirm>
          {/* <Button type="danger" block onClick={() => detailChapter(record)}>详情</Button> */}
        </Space>
      )
    }
  ];

  // 编辑 handler
  const editChapter = (obj) => {
    setnowChapterInfo(obj);
    setisModalVisible(true);
  };

  // 详情
  const detailChapter = (obj) => {
    setisShowPreview(true);
    sethtmlContent(obj.content);
  };

  // 删除
  const delChapter = (obj) => {
    delhaibao({
      id: obj.id
    }).then((res) => {
      let arr = [...chapterList];
      let index = chapterList.findIndex((v) => v.id === obj.id);
      arr.splice(index, 1);
      setchapterList([...arr]);
      message.success("删除成功");
    });
  };

  // 新增
  const addDialog = () => {
    setnowChapterInfo({});
    setTimeout(() => {
      setisModalVisible(true);
    }, 0);
  };

  // 关闭Modal
  const cancle = () => {
    setisModalVisible(false);
  };
  const getBasic=()=>{
    getTjBasicList().then((res) => {
      // setchapterList([...res]);
      setBasicMsg(res)
      console.log("基本数据",res)
      setData(res.onlineAccounts)
    });
  }
  const getOnline=()=>{
    getTjOnlineList().then((res) => {
      setOnlineMsg(res)
      console.log("实时数据",res)
    });
  }
  // 列表
  const getList = () => {
    if(mytime||mytime2){
      clearInterval(mytime)
      clearInterval(mytime2)
    }else{
      setMytime(setInterval(()=>{
        getOnline()
      },30000))
      setMytime2(setInterval(()=>{
        getBasic()
      },1000*60*5))
    }
    getBasic()
    getOnline()
  };

  // 监听Modal
  useEffect(() => {
    if (!isModalVisible) {
      getList();
      setnowChapterInfo({});
    }
  }, [isModalVisible]); // eslint-disable-line react-hooks/exhaustive-deps
  const [time, setTime] = useState(new Date());

  // 初始化
  useEffect(() => {
    getList();
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);
    // 组件卸载时清除定时器
    return () => {
      clearInterval(timer);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const formattedTime = time.toLocaleTimeString();
  return (
    <div className="chapterContainer">
      <Row>
        <Col span={24} className="textLeft">实时监控:{formattedTime} </Col>
      </Row>
      <Row style={{marginTop:'30px'}}>
        <Col span={8} className="leftNr">
          <div className="Title">
            <div>实时在线</div>
            <div className="Tip1">每30秒更新</div>
          </div>
          <div className="Title bottom">
            <div className="context">
              <div>同时在线活跃用户数</div>
              <div className="number">{OnlineMsg.todayHuoyue}</div>
              <div className="tips">昨日峰值 {OnlineMsg.yesterdayHuoyue}&nbsp;&nbsp;&nbsp;</div>
            </div>
            <div>
              <BarChart width={230} height={100} data={Bardata}>
                <Bar dataKey="value" fill="rgba(0,0,0,0.5)" />
              </BarChart>
            </div>
          </div>
          <hr />
          <div className="Title bottom">
            <div className="context">
              <div>同时在线新用户数</div>
              <div className="number">{OnlineMsg.todayNewCount}</div>
              <div className="tips">昨日峰值 {OnlineMsg.yesterdayNewCount}&nbsp;&nbsp;&nbsp;</div>
            </div>
            <div>
              <BarChart width={230} height={100} data={Bardata}>
                <Bar dataKey="value" fill="rgba(0,0,0,0.5)" />
              </BarChart>
            </div>
          </div>
          <hr />
          <div className="Title bottom">
            <div className="context">
              <div>分享回流数</div>
              <div className="number">{OnlineMsg.todayShareCount}</div>
              <div className="tips">昨日峰值 {OnlineMsg.yesterdayShareCount}&nbsp;&nbsp;&nbsp;</div>
            </div>
            <div>
              <BarChart width={230} height={100} data={Bardata}>
                <Bar dataKey="value" fill="rgba(0,0,0,0.5)" />
              </BarChart>
            </div>
          </div>
        </Col>
        <Col span={15} offset={1} className="rightNr">
          <div className="Title">
            <div>基本指标</div>
            <div className="Tip">每5分钟更新</div>
          </div>
          <Row gutter={24} align="center">
            <Col className="gutter-row" span={6}>
              <div>今日新增用户</div>
              <div className="Number">{BasicMsg.todayNewCount}</div>
              {/*<div className="tip">昨日同期&nbsp;&nbsp;&nbsp;<span>-4.75%</span></div>*/}
            </Col>
            <Col className="gutter-row" span={6}>
              <div>今日活跃用户</div>
              <div className="Number">{BasicMsg.todayHuoyueCount}</div>
              {/*<div className="tip">昨日同期&nbsp;&nbsp;&nbsp;<span>-2.16%</span></div>*/}
            </Col>
            <Col className="gutter-row" span={6}>
              <div>今日打开次数</div>
              <div className="Number">{BasicMsg.todayOpenCount}</div>
              {/*<div className="tip">昨日同期&nbsp;&nbsp;&nbsp;<span>-3.57%</span></div>*/}
            </Col>
            <Col className="gutter-row" span={6}>
              <div>今日分享回流数</div>
              <div className="Number">{BasicMsg.todayShareCount}</div>
              {/*<div className="tip">昨日同期&nbsp;&nbsp;&nbsp;<span>-1.79%</span></div>*/}
            </Col>
          </Row>
          <Row gutter={24} align="center" style={{marginTop:'50px'}}>
          <LineChart width={950} height={420} data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
            <Line type="monotone" dataKey="todayCount" stroke="#8884d8" />
            <Line type="monotone" dataKey="yesterdayCount" stroke="#82ca9d" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
          </LineChart>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

// 编辑

const EditCom = (props) => {
  const htmlVal = useRef("");
  const [loading, setloading] = useState(false);

  const [nowUser, setnowUser] = useState({});

  const [myform] = Form.useForm();
  myform.setFieldsValue(props.chapterInfo);

  useEffect(() => {
    setnowUser({...props.chapterInfo,href:props.chapterInfo.picUrl});
  }, [props.chapterInfo]);

  const handleOk = () => {
    console.log("1");
  };

  const handleCancel = () => {
    props.cancle();
  };

  const handleEditorChange = (editorValue) => {
    let html = editorValue.toHTML();
    htmlVal.current = html;
  };

  const onFinish = (vals) => {
    console.log("vals");
    console.log(vals);
    // 分情况是编辑还是新增
    if (props.chapterInfo.id) {
      delete vals.upload;
      updatehaibao({
        ...vals,
        picUrl: nowUser.href,
        id: props.chapterInfo.id
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          href: "",
          content: ""
        });
        message.success("更新成功");
      });
    } else {
      addhaibao({
        ...vals,
        picUrl: nowUser.href
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          href: "",
          content: ""
        });
        message.success("新增成功");
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const beforeUpload = (file1) => {
        const isJpgOrPng =
          file1.type === "image/jpeg" || file1.type === "image/png";
        if (!isJpgOrPng) {
          message.error("只能上传图片!");
        }

        var forms = new FormData();
        var configs = {
          headers: { "Content-Type": "multipart/form-data" }
        };
        forms.append("file", file1);
        setloading(true);
        axios
          .post(`${apiPerfix}/upload/qiniu`, forms, configs)
          .then((res) => {
            setloading(false);
            setnowUser({ ...nowUser, href: res });
          })
          .catch(() => {
            setloading(true);
          });
    return false;
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Modal
      title={props.chapterInfo.id ? "编辑" : "新增轮播图"}
      footer={null}
      width={800}
      visible={props.isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {props.isModalVisible ? (
        <div className="editWrapper">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            form={{ ...myform }}
          >
            {/* <Form.Item
              label="标题"
              name="title"
              rules={[{ required: true, message: "请输入标题!" }]}
            >
              <Input />
            </Form.Item> */}

            <Form.Item
                label="用户Id"
                name="openId"
            >
              <Input type="text" />
            </Form.Item>

            <Form.Item
              label="图片"
              name="upload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                name="href"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="/"
                beforeUpload={beforeUpload}
              >
                {nowUser.href ? (
                  <img
                    src={nowUser.href}
                    alt="avatar"
                    style={{
                      width: "100%"
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
              <div className="warnImgSize">图片比例为：2:1</div>
            </Form.Item>
            {/* <Form.Item label="描述" name="detail">
              <TextArea rows={4} />
            </Form.Item> */}

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default Video;
