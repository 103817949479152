import React, { useEffect, useRef, useState } from "react";
import {Space, Table, Button, Modal, message, Drawer, Select,Popconfirm} from "antd";
import Resizer from "react-image-file-resizer";
import {
  getChapterList,
  addBlogBrandVideoMenuTree,
  addBlogBrandCommonThirdPage,
  delBlogBrandVideoMenuTree,
  delBlogBrandCommonThirdPage,
  addNewInvite,
  updateBlogBrandVideoMenuTree,
  updateBlogBrandCommonThirdPage,
  getChapterDetailDetail,
  BlogBrandVideoMenuTree,
  getBlogBrandCommonThirdPage
} from "../../api/demo";
import { Form, Input, Image, Upload } from "antd";
import "./index.scss";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { apiPerfix, resourceUrl } from "../../api/config";
import { ContentUtils } from "braft-utils";
// 富文本
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
import Msg from "../Msg";

const AddInvite = (props) => {
  const onFinish = (vals) => {
    console.log(vals)
    props.submit(vals);
  };

  return (
    <Modal
      title="添加邀请码"
      onCancel={props.close}
      footer={null}
      visible={props.isShowAddInvite}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="邀请码"
          name="inviteCode"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="代理ID"
          name="proxyId"
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ThreeChapter = (props) => {
  console.log(props.videoId)
  const [chapterList, setchapterList] = useState([]);
  const [isModalVisible, setisModalVisible] = useState(false);
  const [htmlContent, sethtmlContent] = useState("");
  const [isShowPreview, setisShowPreview] = useState(false);
  const [isShowAddInvite, setisShowAddInvite] = useState(false);
  const [ShowThreeDrawer, setShowThreeDrawer] = useState(false);
  const [nowChapterInfo, setnowChapterInfo] = useState({});
  const [isShowDrawer, setisShowDrawer] = useState(false);
  const [twoLevelInfo, settwoLevelInfo] = useState({});
  const [threeLevelInfo, setthreeLevelInfo] = useState({});
  const [threeColumn,setthreeColumn]=useState([])
  const [typeMsg,settypeMsg]=useState("明细管理")
  const columns = [
    {
      title: "序号",
      dataIndex: "sort",
      key: "id"
    },
    {
      title: "标题",
      dataIndex: "title",
      key: "id"
    },
    {
      title: "图片展示方式",
      dataIndex: "picListType",
      key: "id",
      render: (a) => (a==0 ? "垂直": a==1? "横向":"大图")
      // render: (a) =>
      //   a ? (
      //     <a href={a} target="_blank">
      //       {a}
      //     </a>
      //   ) : (
      //     "暂无链接"
      //   )
    },
    {
      title: "视频展示方式",
      dataIndex: "videoShowType",
      key: "id",
      render: (a) => (a==0 ? "一列": "两列")
    },
    {
      title: "操作",
      key: "action",
      render: (_, record, index) => (
        <Space size="middle">
          <Button
            type="primary"
            block
            onClick={() => editChapter(record, index)}
          >
            编辑
          </Button>
          <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delChapter(record)}
          okText="确定"
          cancelText="取消"
        >
          <Button type="danger" block>
            删除
          </Button>
          </Popconfirm>
          <Button
              type="primary"
              block
              onClick={() => sjManager(record, index)}
          >
            明细管理
          </Button>
          <Button
              type="primary"
              block
              onClick={() => lbtManager(record, index)}
          >
            轮播图管理
          </Button>
          <Button
                type="primary"
                block
                onClick={() => shujuManager(record, index)}
            >
              数据管理
            </Button>
          {/* <Button type="danger" block onClick={() => detailChapter(record)}>
            详情
          </Button> */}
          {/* <Button type="danger" block onClick={() => setInviteCode(record)}>
            生成邀请码
          </Button> */}
          {
            props.nowLimit<props.treeLimit?<Button type="danger" block onClick={() => manageTwoLevel(record)}>
              管理菜单
            </Button>:''
          }
        </Space>
      )
    }
  ];

  const manageTwoLevel = (obj) => {
    setisShowDrawer(true);
    console.log("obj");
    console.log(obj,props.treeLimit);
    console.log("二级菜单管理")
    settwoLevelInfo({...obj,treeLimit:props.treeLimit,menuType:props.menuType});
  };

  const closeTwoLevel = () => {
    setisShowDrawer(false);
  };
  const closeThreeLevel=()=>{
    setShowThreeDrawer(false)
  }
  const editChapter = (obj) => {
    console.log("11111",obj)
    let res={
      sort:obj.sort,
      title:obj.title,
      picListType:obj.picListType,
      videoShowType:obj.videoShowType,
      id:obj.id
    }
    setnowChapterInfo(res);
    setisModalVisible(true);
  };

  const setInviteCode = (obj) => {
    setnowChapterInfo(obj);
    setisShowAddInvite(true);
  };

  const saveNewInviteCode = (obj) => {
    addNewInvite({
      ...obj
    }).then((res) => {
      setisShowAddInvite(false);
      message.success("新增成功");
    });
  };

  const detailChapter = (obj) => {
    setisShowPreview(true);
    sethtmlContent(obj.content);
  };

  const sjManager=(obj)=>{
    settypeMsg("明细管理")
    setthreeColumn([
      {
        title: "序号",
        dataIndex: "sort",
        key: "id"
      },
      {
        title: "ID",
        dataIndex: "id",
        key: "id"
      },
      {
        title: "标题",
        dataIndex: "title",
        key: "id"
      },
      {
        title: "媒体类型",
        dataIndex: "mediaType",
        key: "id"
      },
      {
        title: "是否显示明细",
        dataIndex: "isShow",
        key: "id",
        render: (a,record) => a?'显示':'不显示'
      },
      {
        title: "是否显示明细数据表",
        dataIndex: "isDataShow",
        key: "id",
        render: (a,record) => a?'显示':'不显示'
      },
      {
        title: "链接",
        dataIndex: "picUrl",
        key: "id",
        render: (a,record) => (record.mediaType=='image'?(a ? <Image width={200} src={a} /> : "暂无链接"):(a ?<video width={200} height={100} controls autoPlay><source src={a} /></video>:'暂无链接'))
      },
      {
        title: "视频封面",
        dataIndex: "thums",
        key: "id",
        render: (a,record) => (record.mediaType=='image'?"图片没有封面":(a ?<Image width={200} src={a} /> :'暂无链接'))
      },
      {
        title: "备注",
        dataIndex: "remark",
        key: "id"
      },
      {
        title: "操作",
        key: "action",
        render: (_, record, index) => (
            <Space size="middle">
              <Button
                  type="primary"
                  block
                  onClick={() => editChapter(record, index)}
              >
                编辑
              </Button>
              <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delChapter(record)}
          okText="确定"
          cancelText="取消"
        >
              <Button type="danger" block>
                删除
              </Button></Popconfirm>
            </Space>
        )
      }
    ])
    setShowThreeDrawer(true)
    setthreeLevelInfo(obj);
  };

  const shujuManager=(obj)=>{
    settypeMsg("数据管理")
    setthreeColumn([
      {
        title: "排序",
        dataIndex: "sort",
        key: "id"
      },
      {
        title: "型号",
        dataIndex: "xinghao",
        key: "id"
      },
      {
        title: "规格",
        dataIndex: "guige",
        key: "id"
      },
      {
        title: "偏距",
        dataIndex: "pianju",
        key: "id"
      },
      {
        title: "H*PCD",
        dataIndex: "hpcd",
        key: "id"
      },
      {
        title: "中心孔",
        dataIndex: "zhongxinkong",
        key: "id"
      },
      {
        title: "颜色",
        dataIndex: "yanse",
        key: "id"
      },
      {
        title: "库存",
        dataIndex: "kucun",
        key: "id"
      },
      {
        title: "操作",
        key: "action",
        render: (_, record, index) => (
            <Space size="middle">
              <Button
                  type="primary"
                  block
                  onClick={() => editChapter(record, index)}
              >
                编辑
              </Button>
              <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delChapter(record)}
          okText="确定"
          cancelText="取消"
        >
              <Button type="danger" block>
                删除
              </Button></Popconfirm>
            </Space>
        )
      }
    ])
    setShowThreeDrawer(true)
    setthreeLevelInfo(obj);
  }

  const lbtManager=(obj)=>{
    settypeMsg("轮播图管理")
    setthreeColumn([
      {
        title: "序号",
        dataIndex: "sort",
        key: "id"
      },
      {
        title: "图片地址",
        dataIndex: "picUrl",
        render: (a) => (a ? <Image width={200} src={a} /> : "暂无链接"),
        key: "id"
      },
      {
        title: "操作",
        key: "action",
        render: (_, record, index) => (
            <Space size="middle">
              <Button
                  type="primary"
                  block
                  onClick={() => editChapter(record, index)}
              >
                编辑
              </Button>
              <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delChapter(record)}
          okText="确定"
          cancelText="取消"
        >
              <Button type="danger" block>
                删除
              </Button></Popconfirm>
            </Space>
        )
      }
    ])
    setShowThreeDrawer(true)
    setthreeLevelInfo(obj);
  };
  const delChapter = (obj) => {
    delBlogBrandCommonThirdPage({
      id: obj.id
    }).then((res) => {
      let arr = [...chapterList];
      let index = chapterList.findIndex((v) => v.id === obj.id);
      arr.splice(index, 1);
      getList();
      message.success("删除成功");
    });
  };

  const addDialog = () => {
    let arr = [...chapterList];
    setnowChapterInfo({
      indexsort: arr.length + 1
    });
    setTimeout(() => {
      setisModalVisible(true);
    }, 0);
  };

  const cancle = () => {
    setisModalVisible(false);
  };

  const addInviteSubmit = (vals) => {
    saveNewInviteCode({
      ...vals,
      ...nowChapterInfo,
      chapterId: nowChapterInfo.id,
      viewCount: 0
    });
  };

  const closeAddInviteDialog = () => {
    setisShowAddInvite(false);
  };

  const getList = () => {
    getBlogBrandCommonThirdPage({
      groupId: props.videoId ?props.videoId:null
    }).then((res) => {
      if (Array.isArray(res.data)) {
        // res.forEach((element, indexsort) => {
        //   element.key = element.id;
        //   element.indexsort = indexsort + 1;
        // });
        console.log("res");
        console.log(res);
        setchapterList([...res.data]);
      }
    });
  };

  useEffect(() => {
    if (!isModalVisible) {
      getList();
      setnowChapterInfo({});
    }
  }, [isModalVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="chapterContainer">
      <TwoLevelCom
        info={twoLevelInfo}
        closeTwoLevel={closeTwoLevel}
        isShowDrawer={isShowDrawer}
      ></TwoLevelCom>
      <ThreeLevelCom
          info={threeLevelInfo}
          closeThreeLevel={closeThreeLevel}
          isShowDrawer={ShowThreeDrawer}
          columns={threeColumn}
          typeMsg={typeMsg}
          lx={'third'}
      >
      </ThreeLevelCom>
      {isModalVisible && (
        <EditCom
          cancle={cancle}
          isModalVisible={isModalVisible}
          chapterInfo={nowChapterInfo}
          parentId={props.parentId}
          videoId={props.videoId}
          chapterList={chapterList}
        ></EditCom>
      )}

      <div className="addWrapper">
        <Button type="primary" block onClick={addDialog}>
          新增
        </Button>
      </div>
      <div className="tableList">
        <Table columns={columns} dataSource={chapterList} />
      </div>

      <Modal
        title="预览详情"
        footer={null}
        visible={isShowPreview}
        onOk={() => setisShowPreview(false)}
        onCancel={() => setisShowPreview(false)}
      >
        <div
          className=""
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        ></div>
      </Modal>
      <AddInvite
        close={closeAddInviteDialog}
        isShowAddInvite={isShowAddInvite}
        submit={addInviteSubmit}
      ></AddInvite>
    </div>
  );
};

const TwoLevelCom = (props) => {
  const onClose = () => {
    props.closeTwoLevel();
  };

  return (
    <Drawer
      title={props.info.title}
      placement="right"
      onClose={onClose}
      open={props.isShowDrawer}
    >
      {props.isShowDrawer && <ThreeChapter parentId={props.info.parentId+1} videoId={props.info.id} treeLimit={props.info.treeLimit} nowLimit={props.info.parentId+2} menuType={props.info.menuType}></ThreeChapter>}
    </Drawer>
  );
};
const ThreeLevelCom = (props) => {
  const onClose = () => {
    props.closeThreeLevel();
  };

  return (
      <div>
        <Drawer
            width={1500}
            title={props.info.title}
            placement="right"
            onClose={onClose}
            visible={props.isShowDrawer}
        >
          {props.isShowDrawer && <Msg parentId={0} lx={props.lx} videoId={props.info.id} typeMsg={props.typeMsg} msg={'third'} columns={props.columns}></Msg>}
        </Drawer>
      </div>
  );
};

// 编辑

const EditCom = (props) => {
  const htmlVal = useRef("");
  const [nowUser, setnowUser] = useState({});
  const [loading, setloading] = useState(false);

  const [htmlStr, sethtmlStr] = useState(
    BraftEditor.createEditorState("<p></p>")
  );

  useEffect(() => {
    setnowUser(props.chapterInfo);
  }, [props.chapterInfo]);

  const [myform] = Form.useForm();
  myform.setFieldsValue(props.chapterInfo);

  useEffect(() => {
    console.log("编辑....",props.chapterInfo)
    sethtmlStr(BraftEditor.createEditorState(props.chapterInfo.content || ""));
  }, [props.chapterInfo.content]);
  const handleChange=(val)=>{
    console.log("图片展示方式",val)
    setnowUser({ ...nowUser, picListType: val });
  }
  const handleChange2=(val)=>{
    console.log("视频展示方式",val)
    setnowUser({ ...nowUser, videoShowType: val });
  }
  const handleOk = () => {
    console.log("1");
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleCancel = () => {
    props.cancle();
  };

  const handleEditorChange = (editorValue) => {
    let html = editorValue.toHTML();
    htmlVal.current = html;
    sethtmlStr(html);
  };

  const beforeUpload = (file1) => {
    // console.log(file1)
        const isJpgOrPng =
          file1.type === "video/mp4";
        if (!isJpgOrPng) {
          message.error("只能上传视频!");
        }

        var forms = new FormData();
        var configs = {
          headers: { "Content-Type": "multipart/form-data" }
        };
        forms.append("file", file1);
        setloading(true);
        axios
          .post(`${apiPerfix}/upload/qiniu`, forms, configs)
          .then((res) => {
            setloading(false);
            setnowUser({ ...nowUser, appHref: res });
          })
          .catch(() => {
            setloading(true);
          });
    return false;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const onFinish = (vals) => {
    vals.sort=parseInt(vals.sort)
    // 分情况是编辑还是新增
    if (props.chapterInfo.id) {
      updateBlogBrandCommonThirdPage({
        ...vals,
        groupId: props.videoId,
        picListType: nowUser.picListType?nowUser.picListType:0,
        videoShowType:nowUser.videoShowType?nowUser.videoShowType:0,
        id:props.chapterInfo.id
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          appHref: "",
          content: ""
        });
        message.success("更新成功");
      });
    } else {
      addBlogBrandCommonThirdPage({
        ...vals,
        groupId: props.videoId,
        picListType: nowUser.picListType?nowUser.picListType:0,
        videoShowType:nowUser.videoShowType?nowUser.videoShowType:0,
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          appHref: "",
          content: ""
        });
        message.success("新增成功");
      });
    }
  };

  const controls = [
    "undo",
    "redo",
    "separator",
    "font-size",
    "line-height",
    "letter-spacing",
    "separator",
    "text-color",
    "bold",
    "italic",
    "underline",
    "strike-through",
    "separator",
    "superscript",
    "subscript",
    "remove-styles",
    "emoji",
    "separator",
    "text-indent",
    "text-align",
    "separator",
    "headings",
    "list-ul",
    "list-ol",
    "blockquote",
    "code",
    "separator",
    "link",
    "separator",
    "hr",
    "separator",
    "separator",
    "clear"
  ];


  const handleUpload = async (option) => {
    const formData = new FormData();
    formData.append("file", option.file);
    let configs = {
      headers: { "Content-Type": "multipart/form-data" }
    };
    axios
      .post(`${apiPerfix}/upload/qiniu`, formData, configs)
      .then((res) => {
        console.log("====================================");
        console.log(ContentUtils);
        console.log(
          ContentUtils.insertMedias(
            BraftEditor.createEditorState(htmlVal.current),
            [{ type: "IMAGE", url: res }]
          )
        );
        console.log("====================================");
        sethtmlStr(
          ContentUtils.insertMedias(
            BraftEditor.createEditorState(htmlVal.current),
            [{ type: "IMAGE", url: res }]
          )
        );
      })
      .catch(() => {});
  };

  const extendControls = [
    {
      key: "antd-uploader",
      type: "component",
      component: (
        <Upload
          showUploadList={false}
          customRequest={handleUpload}
          accept=".png,.jpg"
        >
          <Button
            className="control-item button upload-button"
            data-title="上传图片"
          >
            <PlusOutlined />
          </Button>
        </Upload>
      )
    }
  ];

  return (
    <Modal
      title={props.chapterInfo.title ? "编辑" : "新增"}
      footer={null}
      width={800}
      visible={props.isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {props.isModalVisible ? (
        <div className="editWrapper">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            form={{ ...myform }}
          >
            <Form.Item
                label="序号"
                name="sort"
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="标题"
              name="title"
            >
              <Input />
            </Form.Item>

            {/*<Form.Item*/}
            {/*    label="序号"*/}
            {/*    name="sort"*/}
            {/*    rules={[{ required: true, message: "请输入序号!" }]}*/}
            {/*  >*/}
            {/*    <Input type="number" />*/}
            {/*  </Form.Item>*/}

            <Form.Item
                label="图片展示方式"
            >
              <Select
                  defaultValue={props.chapterInfo.picListType?props.chapterInfo.picListType:0}
                  style={{ width: 120 }}
                  onChange={handleChange}
                  options={[
                    {
                      value: 0,
                      label: '垂直',
                    },
                    {
                      value: 1,
                      label: '横向',
                    },
                    {
                      value: 2,
                      label: '大图',
                    }
                  ]}
              />
            </Form.Item>

            <Form.Item
                label="视频展示方式"
            >
              <Select
                  defaultValue={props.chapterInfo.videoShowType?props.chapterInfo.videoShowType:0}
                  style={{ width: 120 }}
                  onChange={handleChange2}
                  options={[
                    {
                      value: 0,
                      label: '一列',
                    },
                    {
                      value: 1,
                      label: '二列',
                    }
                  ]}
              />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default ThreeChapter;
