import React, { useEffect, useRef, useState } from "react";
import { Space, Table, Button, Modal, Upload, message,Popconfirm } from "antd";
import { apiPerfix, resourceUrl } from "../../api/config";
import Resizer from "react-image-file-resizer";

import { getTyresList, addTyres, delTyres, updateTyres } from "../../api/demo";
import { Form, Input } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Image } from "antd";
import axios from "axios";
import "./index.scss";
const { TextArea } = Input;

const Video = () => {
  const [chapterList, setchapterList] = useState([]);
  const [isModalVisible, setisModalVisible] = useState(false);
  const [htmlContent, sethtmlContent] = useState("");
  const [isShowPreview, setisShowPreview] = useState(false);
  const [nowChapterInfo, setnowChapterInfo] = useState({});

  const columns = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "序号",
      dataIndex: "sort",
      key: "id"
    },
    {
      title: "标题",
      dataIndex: "title",
      key: "id"
    },
    {
      title: "描述",
      dataIndex: "remark",
      key: "id"
    },
    {
      title: "链接",
      dataIndex: "pics",
      key: "id",
      render: (a) => {
        if (!a) {
          return "";
        }
        return a.map((v, i) => (
          <Image
            key={i}
            style={{ marginLeft: "20px", borderRadius: "10px" }}
            width={80}
            src={v.href}
          />
          // <Image width={200} src={v} />
        ));
      }
    },
    // {
    //   title: "描述",
    //   dataIndex: "detail",
    //   key: "id"
    // },
    {
      title: "操作",
      key: "action",
      render: (_, record) => record.parentId?(<Space size="middle">
            <Button type="primary" block onClick={() => editChildren(record)}>
              编辑
            </Button>
            <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delChapter(record)}
          okText="确定"
          cancelText="取消"
        >
            <Button type="danger" block>
              删除
            </Button></Popconfirm></Space>):(
        <Space size="middle">
          <Button type="primary" block onClick={() => editChapter(record)}>
            编辑
          </Button>
          <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delChapter(record)}
          okText="确定"
          cancelText="取消"
        >
          <Button type="danger" block>
            删除
          </Button></Popconfirm>
          <Button type="primary" block onClick={() => addChildren(record)}>
            新增子级
          </Button>
          {/* <Button type="danger" block onClick={() => detailChapter(record)}>详情</Button> */}
        </Space>
      )
    }
  ];

  // 编辑 handler
  const editChapter = (obj) => {
    let imgs =[]
    if(obj.picList){
      imgs = obj.picList.split(",");
    }
    let arr = [];
    imgs.forEach((v, i) => {
      arr.push({
        uid: i,
        name: "image.png",
        status: "done",
        url: v
      });
    });
    obj.imgs = arr;
    let index = chapterList.findIndex((v) => v.id === obj.id);
    obj.indexsort = index + 1;

    console.log("obj");
    console.log(obj);
    setnowChapterInfo(obj);
    setisModalVisible(true);
  };
  const editChildren = (obj) => {
    let imgs=[];
    if(obj.picList){
      imgs = obj.picList.split(",");
    }
    let arr = [];
    imgs.forEach((v, i) => {
      arr.push({
        uid: i,
        name: "image.png",
        status: "done",
        url: v
      });
    });
    obj.imgs = arr;
    let index = chapterList.findIndex((v) => v.id === obj.parentId);
    let childrenIndex=chapterList[index].children.findIndex((v) => v.id === obj.id)
    obj.indexsort = childrenIndex + 1;

    console.log("obj");
    console.log(obj);
    setnowChapterInfo({...obj,parentTitle:chapterList[index].title,pics:chapterList[index].children[childrenIndex].pics});
    setisModalVisible(true);
  };

  // 详情
  const detailChapter = (obj) => {
    setisShowPreview(true);
    sethtmlContent(obj.content);
  };

  // 删除
  const delChapter = (obj) => {
    delTyres({
      id: obj.id
    }).then((res) => {
      let arr = [...chapterList];
      let index = chapterList.findIndex((v) => v.id === obj.id);
      arr.splice(index, 1);
      setchapterList([...arr]);
      message.success("删除成功");
    });
  };

  // 新增
  const addDialog = () => {
    let arr = [...chapterList];
    setnowChapterInfo({
      indexsort: arr.length + 1
    });
    setTimeout(() => {
      setisModalVisible(true);
    }, 0);
  };
  const addChildren=(obj)=>{
    let arr = [...chapterList];
    setnowChapterInfo({parentId:obj.id,indexsort: arr.length + 1,parentTitle:obj.title,pics:obj.pics})
    console.log(nowChapterInfo)
    setTimeout(() => {
      setisModalVisible(true);
    }, 0);
  }

  // 关闭Modal
  const cancle = () => {
    setisModalVisible(false);
  };

  // 列表
  const getList = () => {
    getTyresList().then((res) => {
      res.forEach((element, indexsort) => {
        element.key = element.id;
        element.indexsort = indexsort + 1;
      });
      setchapterList([...res]);
    });
  };

  // 监听Modal
  useEffect(() => {
    if (!isModalVisible) {
      getList();
      setnowChapterInfo({});
    }
  }, [isModalVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  // 初始化
  useEffect(() => {
    getList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="chapterContainer">
      {isModalVisible && (
        <EditCom
          cancle={cancle}
          isModalVisible={isModalVisible}
          chapterInfo={nowChapterInfo}
          chapterList={chapterList}
        ></EditCom>
      )}

      <div className="addWrapper">
        <Button type="primary" block onClick={addDialog}>
          新增
        </Button>
      </div>
      <div className="tableList">
        <Table columns={columns} dataSource={chapterList} />
      </div>

      <Modal
        title="预览详情"
        footer={null}
        visible={isShowPreview}
        onOk={() => setisShowPreview(false)}
        onCancel={() => setisShowPreview(false)}
      >
        <div
          className=""
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        ></div>
      </Modal>
    </div>
  );
};

// 编辑

const EditCom = (props) => {
  const htmlVal = useRef("");
  const [loading, setloading] = useState(false);
  const [pics,setpics]=useState([])
  const [myform] = Form.useForm();
  console.log("props.chapterInfo");
  console.log(props.chapterInfo);
  myform.setFieldsValue(props.chapterInfo);

  useEffect(() => {
    console.log(props.chapterInfo)
    setfileList(props.chapterInfo.imgs);
    if(props.chapterInfo.pics&&props.chapterInfo.pics.length>0){
      setpics(props.chapterInfo.pics);
      console.log("存在pics")
    }
    console.log(pics)
  }, []);

  useEffect(() => {}, [props.chapterInfo]);

  const handleOk = () => {
    console.log("1");
  };

  const handleCancel = () => {
    props.cancle();
  };

  const handleEditorChange = (editorValue) => {
    let html = editorValue.toHTML();
    htmlVal.current = html;
  };

  const onFinish = (vals) => {
  console.log(vals,pics)
    if(vals.parentId){
      // 分情况是编辑还是新增子级
      if (props.chapterInfo.id) {
        delete vals.upload;
        updateTyres({
          title:vals.title,
          remark:vals.remark,
          href: "",
          sort:Number(vals.sort),
          pics:pics,
          parentId:vals.parentId,
          id: props.chapterInfo.id
        }).then((res) => {
          props.cancle();
          myform.setFieldsValue({
            picList: "",
            content: ""
          });
          message.success("更新成功");
        });
      } else {
        addTyres({
          title:vals.title,
          href: "",
          remark:vals.remark,
          sort:Number(vals.sort),
          pics:pics,
          parentId:vals.parentId
        }).then((res) => {
          props.cancle();
          myform.setFieldsValue({
            picList: "",
            content: ""
          });
          message.success("新增成功");
        });
      }
    }else{
      // let VAL = 2000;
      // let sort = Math.floor(Number(vals.indexsort));
      // let allListl = [];
      // if (props.chapterInfo.id) {
      //   allListl = [...props.chapterList];
      // } else {
      //   allListl = [...props.chapterList].reverse();
      // }

      // let flag = false;
      // let maxVal = 0;
      // if (props.chapterInfo.id) {
      //   flag = sort > allListl.length;
      //   maxVal = allListl.length;
      // } else {
      //   flag = sort > allListl.length + 1;
      //   maxVal = allListl.length + 1;
      // }

      // if (sort == 0) {
      //   message.error("排序不能为0!");
      //   return;
      // } else if (flag) {
      //   message.error("目前排序最大值为" + maxVal);
      //   return;
      // }
      // let sortval = 0;
      // let ns = allListl[sort];
      // let ls = allListl[sort - 1];
      // if (!allListl[sort - 1]) {
      //   let l2 = allListl[sort - 2];
      //   sortval = Math.floor((l2 && l2.sort) || 0) + VAL;
      // } else if (!allListl[sort]) {
      //   sortval = Math.floor(allListl[sort - 1].sort / 2);
      // } else if (ns && ls) {
      //   sortval = Math.floor(
      //       (((ns && ns.sort) || 0) + ((ls && ls.sort) || 0)) / 2
      //   );
      // }
      console.log(vals)
      // 分情况是编辑还是新增
      if (props.chapterInfo.id) {
        delete vals.upload;
        let arr=[]
        if(fileList){
          arr = fileList.map((v) => v.url);
        }
        updateTyres({
          ...vals,
          sort: vals.sort,
          picList: arr.join(","),
          parentId:vals.parentId,
          id: props.chapterInfo.id
        }).then((res) => {
          props.cancle();
          myform.setFieldsValue({
            picList: "",
            content: ""
          });
          message.success("更新成功");
        });
      } else {
        console.log("====================================");
        console.log(fileList);
        console.log("====================================");
        let arr=[]
        if(fileList){
           arr = fileList.map((v) => v.url);
        }
        addTyres({
          ...vals,
          sort: vals.sort,
          picList: arr.join(",")
        }).then((res) => {
          props.cancle();
          myform.setFieldsValue({
            picList: "",
            content: ""
          });
          message.success("新增成功");
        });
      }
    }

  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const addPics=()=>{
    console.log("新增图片对象")
    let arr=JSON.parse(JSON.stringify(pics))
    arr.unshift({"sort":arr.length+1,"title":'',"href":''})
    setpics([...arr])
  }
  const handleTitleChange=(e,index)=>{
    let arr=JSON.parse(JSON.stringify(pics))
    arr[index].title=e.target.value;
    setpics([...arr])
  }
  const delPics=(index)=>{
    let arr=JSON.parse(JSON.stringify(pics))
    arr.splice(index,1);
    setpics([...arr])
  }
  const handleSortChange=(e,index)=>{
    let arr=JSON.parse(JSON.stringify(pics))
    arr[index].sort=e.target.value;
    setpics([...arr])
  }
  const beforeUpload = (file1,index) => {
        const isJpgOrPng =
          file1.type === "image/jpeg" || file1.type === "image/png";
        if (!isJpgOrPng) {
          message.error("只能上传图片!");
        }

        var forms = new FormData();
        var configs = {
          headers: { "Content-Type": "multipart/form-data" }
        };
        forms.append("file", file1);
        setloading(true);
        axios
          .post(`${apiPerfix}/upload/qiniu`, forms, configs)
          .then((res) => {
            console.log(111111);
            setloading(false);
            setfileList([
              ...(fileList || []),
              {
                uid: new Date().getTime(),
                name: "image.png",
                status: "done",
                url: res
              }
            ]);
            let arr=JSON.parse(JSON.stringify(pics))
            arr[index].href=res;
            setpics([...arr])
          })
          .catch((error) => {
            console.log("error");
            console.log(error);
            setloading(true);
          });

    return false;
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const [fileList, setfileList] = useState([]);

  return (
    <Modal
      title={props.chapterInfo.title ? "编辑" : "新增轮胎"}
      footer={null}
      width={800}
      visible={props.isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {props.isModalVisible ? (
        <div className="editWrapper">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            form={{ ...myform }}
          >
            {props.chapterInfo.parentId?<Form.Item
                label="父级"
                name="parentId"
            >
              <Input disabled />
            </Form.Item>:''}
            {props.chapterInfo.parentTitle?<Form.Item
                label="父级标题"
                name="parentTitle"
            >
              <Input disabled />
            </Form.Item>:''}
            <Form.Item
                label="序号"
                name="sort"
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="标题"
              name="title"
            >
              <Input />
            </Form.Item>
            {props.chapterInfo.parentId?<Form.Item
                label="描述"
                name="remark"
            >
              <Input />
            </Form.Item>:''}
            {props.chapterInfo.parentId?
            <Button type="primary" onClick={addPics}>
              新增图片对象
            </Button>:''}
            {props.chapterInfo.parentId&&pics.length>0?
                pics.map((item,index)=>{
                  return (
                    <div key={index}>
                      <Form.Item
                          label="序号"
                      >
                        <Input value={item.sort} onChange={(e) => handleSortChange(e,index)} />
                      </Form.Item>
                      <Form.Item
                          label="标题"
                      >
                        <Input value={item.title} onChange={(e) => handleTitleChange(e,index)} />
                      </Form.Item>
                      <Form.Item
                          label="链接"
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                      >
                        <Upload
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="/"
                            beforeUpload={(file)=>beforeUpload(file,index)}
                        >
                          {item.href ? (
                              <img
                                  src={item.href}
                                  alt="avatar"
                                  style={{
                                    width: "100%"
                                  }}
                              />
                          ) : (
                              uploadButton
                          )}
                        </Upload>
                        <div className="warnImgSize">图片比例为：1:1</div>
                      </Form.Item>
                      <Button onClick={()=>delPics(index)}>删除</Button>
                    </div>
                )})
                :''}

            {/* <Form.Item label="描述" name="detail">
              <TextArea rows={4} />
            </Form.Item> */}

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default Video;
