import "./index.css";
import { Layout, Menu } from "antd";
import React, { Suspense } from "react";
import { Route, Link } from "react-router-dom";
import ProjectPage from "../../pages/projectPage";
import ChapterPage from "../../pages/chapter";
import InvitetPage from "../../pages/invite";
import usePresenter from "./presenter";
import VideoPage from "../../pages/video";
import PicturePage from "../../pages/picture";
import TyresPage from "../../pages/tyres";
import AppPage from "../../pages/appList";
import PhoneList from "../../pages/phoneList";
import ThreeList from "../../pages/threeList";
import Dynamic from "../../pages/dynamic";
import ManufactorBrand from "../../pages/manufactorBrand";
import Kuanshi from "../../pages/kuanshi";
import Kaqian from "../../pages/kaqian";
import Qianyue from "../../pages/qianyue";
import Haibao from "../../pages/haibao";
import Pinglun from "../../pages/pinglun";
import Tj from "../../pages/tj";
import Wxuser from "../../pages/wxuser"
const { Header, Content, Sider } = Layout;
const LayoutCopotent = (props) => {
  const [states, events] = usePresenter();
  const { collapsed, menuList } = states;
  const { onCollapse } = events;

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <div className="logo">轮胎轮廓后台</div>
        <Menu theme="dark" defaultSelectedKeys={["0"]} mode="inline">
          {menuList.map((v, i) => (
            <Menu.Item key={i} icon={v.icon}>
              <Link to={v.path}>{v.name}</Link>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }} />
        <Content style={{ margin: "0 16px" }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            <Suspense fallback="正在加载中...">
              {/* <Route component={ProjectPage} path="/home/projectPage"></Route> */}
              <Route component={Tj} path="/home/tj"></Route>
              <Route component={Kuanshi} path="/home/chapter"></Route>
              <Route component={InvitetPage} path="/home/invite"></Route>
              <Route component={VideoPage} path="/home/video"></Route>
              <Route component={PicturePage} path="/home/picture"></Route>
              <Route component={TyresPage} path="/home/tyres"></Route>
              <Route component={AppPage} path="/home/app"></Route>
              <Route component={PhoneList} path="/home/phoneList"></Route>
              <Route component={ThreeList} path="/home/threeList"></Route>
              <Route component={Dynamic} path="/home/dynamic"></Route>
              <Route component={Kaqian} path="/home/kaqian"></Route>
              <Route
                component={ManufactorBrand}
                path="/home/manufactorBrand"
              ></Route>
              <Route
                  component={Qianyue}
                  path="/home/qianyue"
              ></Route>
              <Route
                  component={Haibao}
                  path="/home/haibao"
              ></Route>
              <Route
                  component={Pinglun}
                  path="/home/pinglun"
              ></Route>
              <Route
                  component={Wxuser}
                  path="/home/wxuser"
              ></Route>
            </Suspense>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutCopotent;
