import { useEffect, useState } from "react";
import { PageHeader } from 'antd';
import { getChapterDetail,updateInviteViewCount } from "../../api/demo";
import './index.scss'
const BlogDetail = (props) => {

  const [chapterId, setchapterId] = useState('')
  const [inviteCode, setinviteCode] = useState('')
  const [proxyId, setproxyId] = useState('')
  const [chapterDetail, setchapterDetail] = useState({})

  useEffect(() => {
    let hrefArr = window.location.hash.split('/')

    // 文章id
    if(hrefArr[2]) {
      getChapterDetail(
        {id: hrefArr[2]}
      ).then(res => {
        setchapterDetail(res)

        // 邀请码对应的id，为了统计访问量
        if(hrefArr[3]) {
          let l = localStorage.getItem('isPreview')
          if(!l) {
            updateInviteViewCount({
              id: hrefArr[3]
            }).then(res => {
              localStorage.setItem("isPreview", 1)
            })
          }
        }


      })
    }

    // 邀请码
    if(hrefArr[4]) {
      setinviteCode(hrefArr[4])
    }

    // 代理id
    if(hrefArr[5]) {
      setproxyId(hrefArr[5])
    }
  }, [])

  const goBack =() => {
    props.history.push('/blog')
  }

  return (
    <div className="blogDetail">
      {/* <PageHeader
        className="site-page-header"
        onBack={goBack}
        title="返回"
      /> */}
      <div className="main">
        <div className="blogTitle">{chapterDetail.title}</div>
        <div
          className="blogContent"
        >
          <div 
          dangerouslySetInnerHTML={{ __html: chapterDetail.content }}
          className="htmlContent">

          </div>
            {
            !(proxyId === '0' && inviteCode === '0') ?
              <div className="blogAppHref">
                <a href={chapterDetail.appHref}>
                  APP链接: {chapterDetail.appHref + (proxyId && proxyId !== '0' ? proxyId : '') }
                </a>
              </div>          
            : ''
          }


          {
            inviteCode && inviteCode !== '0' ?
              <div className="blogAppHref">
                邀请码: {inviteCode}
              </div>          
            : ''
          }
        </div>



      </div>

    </div>
  )
}

export default BlogDetail;