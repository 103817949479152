/*
 * @Author: your name
 * @Date: 2021-06-17 23:21:18
 * @LastEditTime: 2021-06-17 23:26:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /react-demo/src/redux/reducers/index.js
 */

//  对reducers 进行汇总
import { combineReducers } from "redux";
import sum from "./count";

const allReducer = combineReducers({
  sum,
});

export default allReducer;
