/*
 * @Author: shlw@toplion.com.cn shlw@toplion.com.cn
 * @Date: 2022-06-25 10:21:40
 * @LastEditors: shlw@toplion.com.cn shlw@toplion.com.cn
 * @LastEditTime: 2022-09-01 10:52:36
 * @FilePath: /project-manage/src/api/config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 生产
export const apiPerfix = "/blogServer";
export const basreUrl = "";
export const resourceUrl = window.location.origin;

// 开发
// export const apiPerfix = "/blogServer";
// export const basreUrl = "";
// export const resourceUrl = "";
