import React, { useEffect, useRef, useState } from "react";
import { Space, Table, Button, Modal, Upload, message,Popconfirm } from "antd";
import { apiPerfix, resourceUrl } from "../../api/config";
import Resizer from "react-image-file-resizer";

import {
  getpinglunList,
  addpinglun,
  delpinglun,
  toppinglun,
  unToppinglun,
  showpinglun,
  unShowpinglun
} from "../../api/demo";
import { Form, Input } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Image } from "antd";
import axios from "axios";
import "./index.scss";
const { TextArea } = Input;

const Video = () => {
  const [chapterList, setchapterList] = useState([]);
  const [isModalVisible, setisModalVisible] = useState(false);
  const [htmlContent, sethtmlContent] = useState("");
  const [isShowPreview, setisShowPreview] = useState(false);
  const [nowChapterInfo, setnowChapterInfo] = useState({});

  const columns = [
    {
      title: "用户ID",
      dataIndex: "openId",
      key: "id"
    },
    {
      title: "用户",
      dataIndex: "userName",
      key: "id"
    },
    {
      title: "评论内容",
      dataIndex: "content",
      key: "id"
    },
    {
      title: "是否置顶",
      dataIndex: "isTop",
      key: "id",
      render:(a) => a ?
        "是"
        : "否"
    },
    {
      title: "是否显示",
      dataIndex: "isShow",
      key: "id",
      render:(a) => a ?
          "是"
          : "否"
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "id"
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {
            record.isTop?
            <Button type="primary" block onClick={() => editChapter(record,'unTop')}>
              取消置顶
            </Button>:
            <Button type="primary" block onClick={() => editChapter(record,'top')}>
              置顶
            </Button>
          }
          {
            record.isShow?
                <Button type="primary" block onClick={() => editChapter(record,'unShow')}>
                  隐藏
                </Button>:
                <Button type="primary" block onClick={() => editChapter(record,'show')}>
                  显示
                </Button>
          }
          <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delChapter(record)}
          okText="确定"
          cancelText="取消"
        >
          <Button type="danger" block>
            删除
          </Button></Popconfirm>
          {/* <Button type="danger" block onClick={() => detailChapter(record)}>详情</Button> */}
        </Space>
      )
    }
  ];

  // 编辑 handler
  const editChapter = (obj,flag) => {
    if(flag==="top"){
      toppinglun({
        id: obj.id
      }).then((res) => {
        message.success("置顶成功");
        getList()
      })
    }else if(flag==="unTop"){
      unToppinglun({
        id: obj.id
      }).then((res) => {
        message.success("取消置顶成功");
        getList()
      })
    }else if(flag==="show"){
      showpinglun({
        id: obj.id
      }).then((res) => {
        message.success("显示成功");
        getList()
      })
    }else{
      unShowpinglun({
        id: obj.id
      }).then((res) => {
        message.success("隐藏成功");
        getList()
      })
    }
  };

  // 详情
  const detailChapter = (obj) => {
    setisShowPreview(true);
    sethtmlContent(obj.content);
  };

  // 删除
  const delChapter = (obj) => {
    delpinglun({
      id: obj.id
    }).then((res) => {
      let arr = [...chapterList];
      let index = chapterList.findIndex((v) => v.id === obj.id);
      arr.splice(index, 1);
      setchapterList([...arr]);
      message.success("删除成功");
    });
  };

  // 新增
  const addDialog = () => {
    setnowChapterInfo({});
    setTimeout(() => {
      setisModalVisible(true);
    }, 0);
  };

  // 关闭Modal
  const cancle = () => {
    setisModalVisible(false);
  };

  // 列表
  const getList = () => {
    getpinglunList().then((res) => {
      setchapterList([...res]);
    });
  };

  // 监听Modal
  useEffect(() => {
    if (!isModalVisible) {
      getList();
      setnowChapterInfo({});
    }
  }, [isModalVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  // 初始化
  useEffect(() => {
    getList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="chapterContainer">
      <EditCom
        cancle={cancle}
        isModalVisible={isModalVisible}
        chapterInfo={nowChapterInfo}
      ></EditCom>
      {/*<div className="addWrapper">*/}
      {/*  <Button type="primary" block onClick={addDialog}>*/}
      {/*    新增*/}
      {/*  </Button>*/}
      {/*</div>*/}
      <div className="tableList">
        <Table columns={columns} dataSource={chapterList} />
      </div>

      <Modal
        title="预览详情"
        footer={null}
        visible={isShowPreview}
        onOk={() => setisShowPreview(false)}
        onCancel={() => setisShowPreview(false)}
      >
        <div
          className=""
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        ></div>
      </Modal>
    </div>
  );
};

// 编辑

const EditCom = (props) => {
  const htmlVal = useRef("");
  const [loading, setloading] = useState(false);

  const [nowUser, setnowUser] = useState({});

  const [myform] = Form.useForm();
  myform.setFieldsValue(props.chapterInfo);

  useEffect(() => {
    setnowUser(props.chapterInfo);
  }, [props.chapterInfo]);

  const handleOk = () => {
    console.log("1");
  };

  const handleCancel = () => {
    props.cancle();
  };

  const handleEditorChange = (editorValue) => {
    let html = editorValue.toHTML();
    htmlVal.current = html;
  };

  const onFinish = (vals) => {
    console.log("vals");
    console.log(vals);
    // 分情况是编辑还是新增
    if (props.chapterInfo.id) {
      delete vals.upload;
      toppinglun({
        ...vals,
        detail:nowUser.detail,
        parentId:nowUser.parentId,
        parentTable:nowUser.parentTable,
        href: nowUser.href,
        id: props.chapterInfo.id
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          href: "",
          content: ""
        });
        message.success("更新成功");
      });
    } else {
      addpinglun({
        ...vals,
        href: nowUser.href
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          href: "",
          content: ""
        });
        message.success("新增成功");
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const beforeUpload = (file1) => {
        const isJpgOrPng =
          file1.type === "image/jpeg" || file1.type === "image/png";
        if (!isJpgOrPng) {
          message.error("只能上传图片!");
        }

        var forms = new FormData();
        var configs = {
          headers: { "Content-Type": "multipart/form-data" }
        };
        forms.append("file", file1);
        setloading(true);
        axios
          .post(`${apiPerfix}/upload/qiniu`, forms, configs)
          .then((res) => {
            setloading(false);
            setnowUser({ ...nowUser, href: res });
          })
          .catch(() => {
            setloading(true);
          });
    return false;
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Modal
      title={props.chapterInfo.id ? "编辑" : "新增轮播图"}
      footer={null}
      width={800}
      visible={props.isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {props.isModalVisible ? (
        <div className="editWrapper">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            form={{ ...myform }}
          >
            {/* <Form.Item
              label="标题"
              name="title"
              rules={[{ required: true, message: "请输入标题!" }]}
            >
              <Input />
            </Form.Item> */}

            <Form.Item
                label="序号"
                name="sort"
                rules={[{ required: true, message: "请输入序号!" }]}
            >
              <Input type="number" />
            </Form.Item>

            <Form.Item
              label="图片"
              name="upload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                name="href"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="/"
                beforeUpload={beforeUpload}
              >
                {nowUser.href ? (
                  <img
                    src={nowUser.href}
                    alt="avatar"
                    style={{
                      width: "100%"
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
              <div className="warnImgSize">图片比例为：2:1</div>
            </Form.Item>
            {/* <Form.Item label="描述" name="detail">
              <TextArea rows={4} />
            </Form.Item> */}

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default Video;
