/*
 * @Author: your name
 * @Date: 2020-08-27 08:34:40
 * @LastEditTime: 2021-06-18 10:22:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /sdzn-assist-line/src/mock/index.js
 */
import demo from "./demo";
/**
 * 模拟数据mock
 */

var mockOptions = { mock: false };
demo(mockOptions);
