/*
 * @Author: slw
 * @Date: 2020-08-14 09:44:53
 * @LastEditTime: 2021-06-22 14:54:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /sdzn-assist-line/src/util/request.js
 */
/**
 * 全站http配置
 *
 * axios参数说明
 * isSerialize是否开启form表单提交
 * isToken是否需要token
 */
import axios from "axios";
import { message } from "antd";
import qs from "qs";
import { basreUrl } from "../api/config";
axios.defaults.baseURL = basreUrl;
axios.defaults.timeout = 50000000000;
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
};
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  async (config) => {
    const meta = config.meta || {};

    if (meta.isSerialize) {
      config.data = qs.stringify({ ...config.data });
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
//HTTPresponse拦截
axios.interceptors.response.use(
  (res) => {
    const status = res.data && res.data.code;
    const msg = (res.data && res.data.msg) || "未知错误";
    // 如果请求为非0否者默认统一处理
    if (status&&status!==200) {
      message.error(msg);
      return Promise.reject(msg);
    }
    return res.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axios;
