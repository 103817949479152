/*
 * @Author: your name
 * @Date: 2021-05-29 15:20:36
 * @LastEditTime: 2021-06-18 10:12:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /react-demo/src/App.js
 */
import "./App.css";
import { Route, Redirect } from "react-router-dom";
import Layout from "./components/layout/index";
import Login from "./pages/login";
import Blog from "./pages/blog";
import BlogDetail from "./pages/blogDetail";
import ManufactorBrand from "./pages/manufactorBrand";
import PhoneList from "./pages/phoneList";
import { useEffect } from "react";

const App = () => {
  useEffect(() => {
    let href = window.location.hash;
    if (href.indexOf("blog") > -1) {
    } else {
      let local = localStorage.getItem("userInfo");
      if (!local) {
        window.open(`${window.location.origin}/#/login`, "_self");
      } else {
        window.open(`${window.location.origin}/#/home/tj`, "_self");
        console.error("12312");
      }
    }
  }, []);

  return (
    <div className="App">
      <Route component={Blog} path="/blog"></Route>
      <Route component={BlogDetail} path="/blogDetail"></Route>
      <Route component={Login} path="/login"></Route>
      <Route component={Layout} path="/home"></Route>
      <Route component={ManufactorBrand} path="/manufactorBrand"></Route>
      <Route component={PhoneList} path="/phoneList"></Route>
    </div>
  );
};

export default App;
