/*
 * @Author: your name
 * @Date: 2021-06-17 14:45:39
 * @LastEditTime: 2021-06-17 14:45:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /react-demo/src/redux/constant.js
 */

/**
 * 该模块适用于定义action对象中type类型的常量值，目的只有一个，便于管理的同时防止程序员单词写错
 */

export const INCREMENT = "increment";
export const DECREMENT = "decrement";
