import React, { useEffect, useRef, useState } from "react";
import { Space, Table, Button, Modal, Upload, message,Popconfirm } from "antd";
import { apiPerfix, resourceUrl } from "../../api/config";
import { getAppList, addApp, delApp, updateApp } from "../../api/demo";
import { Form, Input } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Image } from "antd";
import axios from "axios";
import "./index.scss";
const { TextArea } = Input;

const Video = () => {
  const [chapterList, setchapterList] = useState([]);
  const [isModalVisible, setisModalVisible] = useState(false);
  const [htmlContent, sethtmlContent] = useState("");
  const [isShowPreview, setisShowPreview] = useState(false);
  const [nowChapterInfo, setnowChapterInfo] = useState({});

  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "防伪码",
      dataIndex: "title",
      key: "id"
    },
    // {
    //   title: '链接',
    //   dataIndex: 'href',
    //   key: 'id'
    // },
    // {
    //   title: '图标',
    //   dataIndex: 'logo',
    //   key: 'id',
    //   render: (a) => a ?
    //   <Image
    //   width={200}
    //   src={ a }
    // />
    //   : "暂无链接"
    // },
    // {
    //   title: '描述',
    //   dataIndex: 'detail',
    //   key: 'id',
    // },
    {
      title: "操作",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <Button type="primary" block onClick={() => editChapter(record)}>
            编辑
          </Button> */}
          <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delChapter(record)}
          okText="确定"
          cancelText="取消"
        >
          <Button type="danger" block>
            删除
          </Button>
          </Popconfirm>
          {/* <Button type="danger" block onClick={() => detailChapter(record)}>详情</Button> */}
        </Space>
      )
    }
  ];

  // 编辑 handler
  const editChapter = (obj) => {
    setnowChapterInfo(obj);
    setisModalVisible(true);
  };

  // 详情
  const detailChapter = (obj) => {
    setisShowPreview(true);
    sethtmlContent(obj.content);
  };

  // 删除
  const delChapter = (obj) => {
    delApp({
      id: obj.id
    }).then((res) => {
      let arr = [...chapterList];
      let index = chapterList.findIndex((v) => v.id === obj.id);
      arr.splice(index, 1);
      setchapterList([...arr]);
      message.success("删除成功");
    });
  };

  // 新增
  const addDialog = () => {
    setnowChapterInfo({});
    setTimeout(() => {
      setisModalVisible(true);
    }, 0);
  };

  // 关闭Modal
  const cancle = () => {
    setisModalVisible(false);
  };

  // 列表
  const getList = () => {
    getAppList().then((res) => {
      res.forEach((element) => {
        element.key = element.id;
      });
      setchapterList([...res]);
    });
  };

  // 监听Modal
  useEffect(() => {
    if (!isModalVisible) {
      getList();
      setnowChapterInfo({});
    }
  }, [isModalVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  // 初始化
  useEffect(() => {
    getList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="chapterContainer">
      <EditCom
        cancle={cancle}
        isModalVisible={isModalVisible}
        chapterInfo={nowChapterInfo}
      ></EditCom>
      <div className="addWrapper">
        <Button type="primary" block onClick={addDialog}>
          新增
        </Button>
      </div>
      <div className="tableList">
        <Table columns={columns} dataSource={chapterList} />
      </div>

      <Modal
        title="预览详情"
        footer={null}
        visible={isShowPreview}
        onOk={() => setisShowPreview(false)}
        onCancel={() => setisShowPreview(false)}
      >
        <div
          className=""
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        ></div>
      </Modal>
    </div>
  );
};

// 编辑

const EditCom = (props) => {
  const htmlVal = useRef("");
  const [loading, setloading] = useState(false);

  const [nowUser, setnowUser] = useState({});

  const [myform] = Form.useForm();
  myform.setFieldsValue(props.chapterInfo);

  useEffect(() => {
    setnowUser(props.chapterInfo);
  }, [props.chapterInfo]);

  const handleOk = () => {
    console.log("1");
  };

  const handleCancel = () => {
    props.cancle();
  };

  const handleEditorChange = (editorValue) => {
    let html = editorValue.toHTML();
    htmlVal.current = html;
  };

  const onFinish = (vals) => {
    // 分情况是编辑还是新增
    if (Object.keys(props.chapterInfo || {}).length > 0) {
      delete vals.upload;
      updateApp({
        ...vals,
        logo: nowUser.logo,
        id: props.chapterInfo.id
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          href: "",
          logo: "",
          content: ""
        });
        message.success("更新成功");
      });
    } else {
      let arr = vals.title.split("\n");
      addApp(
        //   {
        //   title: arr,
        //   logo: nowUser.logo
        // }
        arr
      ).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          logo: "",
          href: "",
          content: ""
        });
        message.success("新增成功");
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("只能上传图片!");
    }

    var forms = new FormData();
    var configs = {
      headers: { "Content-Type": "multipart/form-data" }
    };
    forms.append("file", file);
    setloading(true);
    axios
      .post(`${apiPerfix}/file/upload`, forms, configs)
      .then((res) => {
        setloading(false);
        setnowUser({ ...nowUser, logo: `${resourceUrl}/` + res });
      })
      .catch(() => {
        setloading(true);
      });
    return false;
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Modal
      title={props.chapterInfo.title ? "编辑" : "新增防伪码"}
      footer={null}
      width={800}
      visible={props.isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {props.isModalVisible ? (
        <div className="editWrapper">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            form={{ ...myform }}
          >
            <Form.Item
              label="防伪码"
              name="title"
              rules={[{ required: true, message: "请输入标题!" }]}
            >
              <TextArea rows={5} placeholder="" />
              {/* <Input /> */}
            </Form.Item>
            {/* 
            <Form.Item
              label="下载链接"
              name="href"
              rules={[{ required: true, message: "请输入下载链接!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="图标"
              name="upload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                name="logo"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="/"
                beforeUpload={beforeUpload}
              >
                {nowUser.logo ? (
                  <img
                    src={nowUser.logo}
                    alt="avatar"
                    style={{
                      width: "100%"
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>

            <Form.Item label="描述" name="detail">
              <TextArea rows={4} />
            </Form.Item> */}

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default Video;
