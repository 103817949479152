import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Space,
  Table,
  Button,
  Popconfirm,
  Modal,
  Upload,
  message,
  Drawer,
  Switch,
  Image, Select,Cascader
} from "antd";
import Resizer from "react-image-file-resizer";

import { getVideoList, addVideo, delVideo, updateVideo,getPageGroup,getBlogBrandCommonThirdPage } from "../../api/demo";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { apiPerfix, resourceUrl } from "../../api/config";
import "./index.scss";

import Chapter from "../chapter/index";
import Msg from "../Msg/index";
const { TextArea } = Input;

const Video = () => {
  const [chapterList, setchapterList] = useState([]);
  const [threeList,setthreeList]=useState([])
  const [isModalVisible, setisModalVisible] = useState(false);
  const [htmlContent, sethtmlContent] = useState("");
  const [isShowPreview, setisShowPreview] = useState(false);
  const [nowChapterInfo, setnowChapterInfo] = useState({});

  const [isShowDrawer, setisShowDrawer] = useState(false);
  const [ShowThreeDrawer, setShowThreeDrawer] = useState(false);
  const [twoLevelInfo, settwoLevelInfo] = useState({});
  const [threeLevelInfo, setthreeLevelInfo] = useState({});
  const [threeColumn,setthreeColumn]=useState([])
  const [typeMsg,settypeMsg]=useState("明细管理")
  const columns = [
    {
      title: "id",
      dataIndex: "id"
    },
    {
      title: "序号",
      dataIndex: "sort",
      key: "id"
    },
    {
      title: "标题",
      dataIndex: "title",
      key: "id"
    },
    {
      title: "菜单层级限制",
      dataIndex: "treeLimit",
      key: "id"
    },
    {
      title: "类型",
      dataIndex: "type",
      key: "id",
      render: (a) => (a=='color' ? '颜色' : "正常"),
    },
    // {
    //   title: "图片展示方式",
    //   dataIndex: "picListType",
    //   key: "id",
    //   render: (a) => (a==0 ? "垂直": "横向")
    // },
    {
      title: "链接",
      dataIndex: "href",
      render: (a) => (a ? <Image width={200} src={a} /> : "暂无链接"),
      key: "id"
    },
    // {
    //   title: "描述",
    //   dataIndex: "detail",
    //   key: "id"
    // },
    {
      title: "操作",
      key: "action",
      render: (_, record, index) => record.menuType!=0?(
        <Space size="middle">
          <Button
            type="primary"
            block
            onClick={() => editChapter(record, index)}
          >
            编辑
          </Button>
          <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delChapter(record)}
          okText="确定"
          cancelText="取消"
        >
          <Button type="danger" block>
            删除
          </Button></Popconfirm>
          {/* <Button
              type="primary"
              block
              onClick={() => sjManager(record, index)}
          >
            明细管理
          </Button>
          <Button
              type="primary"
              block
              onClick={() => lbtManager(record, index)}
          >
            轮播图管理
          </Button> */}
          <Button type="danger" block onClick={() => manageTwoLevel(record)}>
            管理菜单
          </Button>
          {/* <Button type="danger" block onClick={() => detailChapter(record)}>详情</Button> */}
        </Space>
      ):(
          <Space size="middle">
            <Button
                type="primary"
                block
                onClick={() => editChapter(record, index)}
            >
              编辑
            </Button>
            <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delChapter(record)}
          okText="确定"
          cancelText="取消"
        >
            <Button type="danger" block>
              删除
            </Button></Popconfirm>
            <Button type="danger" block onClick={() => manageTwoLevel(record)}>
              管理菜单
            </Button>
            {/* <Button type="danger" block onClick={() => detailChapter(record)}>详情</Button> */}
          </Space>
      )
    }
  ];

  const manageTwoLevel = (obj) => {
    console.log(obj)
    setisShowDrawer(true);
    settwoLevelInfo(obj);
  };

  const closeTwoLevel = () => {
    setisShowDrawer(false);
  };
  const closeThreeLevel=()=>{
    setShowThreeDrawer(false)
  }

  // 编辑 handler
  const editChapter = (obj) => {
    let index = chapterList.findIndex((v) => v.id === obj.id);
    obj.indexsort = index + 1;
    if(obj.thirdPageIds){
      setnowChapterInfo({...obj,isShow:obj.isShow?obj.isShow:false,isTop:obj.isTop?obj.isTop:false,thirdPageIds:((obj.thirdPageIds).indexOf(',')!=-1?obj.thirdPageIds.split(',').map(Number):[parseInt(obj.thirdPageIds)])});
    }else{
      setnowChapterInfo({...obj,isShow:obj.isShow?obj.isShow:false,isTop:obj.isTop?obj.isTop:false})
    }
    setisModalVisible(true);
  };
  const sjManager=(obj)=>{
    settypeMsg("明细管理")
    setthreeColumn([
      {
        title: "id",
        dataIndex: "id"
      },{
        title: "序号",
        dataIndex: "sort",
        key: "id"
      },
      {
        title: "标题",
        dataIndex: "title",
        key: "id"
      },
      {
        title: "媒体类型",
        dataIndex: "mediaType",
        key: "id"
      },
      {
        title: "链接",
        dataIndex: "picUrl",
        key: "id"
      },
      {
        title: "备注",
        dataIndex: "remark",
        key: "id"
      },
      {
        title: "操作",
        key: "action",
        render: (_, record, index) => (
          <Space size="middle">
            <Button
              type="primary"
              block
              onClick={() => editChapter(record, index)}
            >
              编辑
            </Button>
            <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() =>  delmMore(record,"明细管理")}
          okText="确定"
          cancelText="取消"
        >
            <Button type="danger" block>
              删除
            </Button></Popconfirm>
          </Space>
        )
      }
    ])
    setShowThreeDrawer(true)
    setthreeLevelInfo(obj);
  };

  const lbtManager=(obj)=>{
    settypeMsg("轮播图管理")
    setthreeColumn([
      {
        title: "序号",
        dataIndex: "sort",
        key: "id"
      },
      {
        title: "图片地址",
        dataIndex: "picUrl",
        render: (a) => (a ? <Image width={200} src={a} /> : "暂无链接"),
        key: "id"
      },
      {
        title: "操作",
        key: "action",
        render: (_, record, index) => (
          <Space size="middle">
            <Button
              type="primary"
              block
              onClick={() => editChapter(record, index)}
            >
              编辑
            </Button>
            <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() =>  delmMore(record,"轮播图管理")}
          okText="确定"
          cancelText="取消"
        >
            <Button type="danger" block>
              删除
            </Button></Popconfirm>
          </Space>
        )
      }
    ])
    setShowThreeDrawer(true)
    setthreeLevelInfo(obj);
  };

  // 详情
  // const detailChapter = (obj) => {
  //   setisShowPreview(true);
  //   sethtmlContent(obj.content);
  // };

  // 删除
  const delChapter = (obj) => {
    delVideo({
      id: obj.id
    }).then((res) => {
      let arr = [...chapterList];
      let index = chapterList.findIndex((v) => v.id === obj.id);
      arr.splice(index, 1);
      setchapterList([...arr]);
      message.success("删除成功");
    });
  };
  const delmMore = (obj,msg) => {
    if(msg==="明细管理"){
      //删除明细
    }else{
      //删除轮播图
    }
  };


  // 新增
  const addDialog = () => {
    let arr = [...chapterList];
    console.log("arr");
    console.log(arr);
    setnowChapterInfo({
      sort: arr.length + 1,
      treeLimit:1
    });
    setTimeout(() => {
      setisModalVisible(true);
    }, 0);
  };

  // 关闭Modal
  const cancle = () => {
    setisModalVisible(false);
  };

  const getThree=()=>{
    getPageGroup().then(res=>{
      console.log('三级分组',res)
      let arr=[];
      res.data.forEach(item=>{
        arr.push(item)
      })
      setthreeList([...arr])
    })
  }

  // 列表
  const getList = () => {
    getVideoList().then((res) => {
      res.forEach((element, indexsort) => {
        element.key = element.id;
        element.indexsort = indexsort + 1;
      });
      setchapterList([...res]);
    });
  };

  // 监听Modal
  useEffect(() => {
    if (!isModalVisible) {
      getList();
      getThree();
      setnowChapterInfo({});
    }
  }, [isModalVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  // 初始化
  useEffect(() => {
    getList();
    getThree();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="chapterContainer">
      {isModalVisible && (
        <EditCom
          cancle={cancle}
          isModalVisible={isModalVisible}
          chapterInfo={nowChapterInfo}
          chapterList={chapterList}
          threeList={threeList}
        ></EditCom>
      )}

      <TwoLevelCom
        info={twoLevelInfo}
        closeTwoLevel={closeTwoLevel}
        isShowDrawer={isShowDrawer}
      ></TwoLevelCom>
      <ThreeLevelCom
      info={threeLevelInfo}
      closeThreeLevel={closeThreeLevel}
      isShowDrawer={ShowThreeDrawer}
      columns={threeColumn}
      typeMsg={typeMsg}
      lx={'video'}
      >
      </ThreeLevelCom>
      <div className="addWrapper">
        <Button type="primary" block onClick={addDialog}>
          新增
        </Button>
      </div>
      <div className="tableList">
        <Table columns={columns} dataSource={chapterList} />
      </div>

      <Modal
        title="预览详情"
        footer={null}
        visible={isShowPreview}
        onOk={() => setisShowPreview(false)}
        onCancel={() => setisShowPreview(false)}
      >
        <div
          className=""
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        ></div>
      </Modal>
    </div>
  );
};

const TwoLevelCom = (props) => {
  const onClose = () => {
    props.closeTwoLevel();
  };

  useEffect(() => {
    console.log(props.isShowDrawer);
  });

  return (
    <div>
      <Drawer
        width={1000}
        title={props.info.title}
        placement="right"
        onClose={onClose}
        visible={props.isShowDrawer}
      >
        {props.isShowDrawer && <Chapter parentId={props.info.id} videoId={props.info.id} menuType={props.info.menuType} treeLimit={props.info.treeLimit} nowLimit={1}></Chapter>}
      </Drawer>
    </div>
  );
};

const ThreeLevelCom = (props) => {
  const onClose = () => {
    props.closeThreeLevel();
  };

  return (
    <div>
      <Drawer
        width={1000}
        title={props.info.title}
        placement="right"
        onClose={onClose}
        visible={props.isShowDrawer}
      >
        {props.isShowDrawer && <Msg parentId={0} videoId={props.info.id} lx={props.lx} typeMsg={props.typeMsg} msg={'video'} columns={props.columns}></Msg>}
      </Drawer>
    </div>
  );
};

// 编辑

const EditCom = (props) => {
  const htmlVal = useRef("");
  const [loading, setloading] = useState(false);

  const [nowUser, setnowUser] = useState({});

  const [myform] = Form.useForm();
  myform.setFieldsValue(props.chapterInfo);

  useEffect(() => {
    console.log(props.chapterInfo.thirdPageIds)
    let group=props.chapterInfo.thirdPageIds;
    if(group?.length>0){
      getBlogBrandCommonThirdPage({
        groupId: group[group.length-1]
      }).then((res) => {
        if (Array.isArray(res.data)) {
          // res.forEach((element, indexsort) => {
          //   element.key = element.id;
          //   element.indexsort = indexsort + 1;
          // });
          setnowUser({...props.chapterInfo,picUrl:props.chapterInfo.picUrl,threeItemList:[...res.data],isThree:true});
        }
      });
    }else{
      setnowUser({...props.chapterInfo,picUrl:props.chapterInfo.picUrl,threeItemList:[],isThree:false});
    }
  }, [props.chapterInfo]);

  const handleChange=(val)=>{
    console.log("图片展示方式",val)
    setnowUser({ ...nowUser, picListType: val });
  }
  const handlemenuChange=(val)=>{
    console.log("菜单类型",val)
    if(val==0){
      props.chapterInfo.treeLimit=1;
    }
    setnowUser({ ...nowUser, menuType: val });
  }

  const handleOk = () => {
    console.log("1");
  };

  const handleCancel = () => {
    props.cancle();
  };

  const handleEditorChange = (editorValue) => {
    let html = editorValue.toHTML();
    htmlVal.current = html;
  };

  const onFinish = (vals) => {
    vals.treeLimit=parseInt(vals.treeLimit)
    // 分情况是编辑还是新增
    if (props.chapterInfo.id) {
      delete vals.upload;
      updateVideo({
        ...vals,
        type:props.chapterInfo.type,
        href: nowUser.href,
        isTop:nowUser.isTop?nowUser.isTop:false,
        isShow:nowUser.isShow?nowUser.isShow:false,
        picListType: nowUser.picListType?nowUser.picListType:0,
        menuType:nowUser.menuType?nowUser.menuType:0,
        thirdPageIds: nowUser.isThree?(nowUser.thirdPageIds?nowUser.thirdPageIds.join(","):null):null,
        id: props.chapterInfo.id
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          href: "",
          content: ""
        });
        message.success("更新成功");
      });
    } else {
      addVideo({
        ...vals,
        isTop:nowUser.isTop?nowUser.isTop:false,
        isShow:nowUser.isShow?nowUser.isShow:false,
        href: nowUser.href,
        menuType:nowUser.menuType?nowUser.menuType:0,
        thirdPageIds: nowUser.isThree?(nowUser.thirdPageIds?nowUser.thirdPageIds.join(","):null):null,
        picListType: nowUser.picListType?nowUser.picListType:0,
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          href: "",
          content: ""
        });
        message.success("新增成功");
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const handleChangeThree=(val)=>{
    getBlogBrandCommonThirdPage({
      groupId: val[val.length-1]
    }).then((res) => {
      if (Array.isArray(res.data)) {
        // res.forEach((element, indexsort) => {
        //   element.key = element.id;
        //   element.indexsort = indexsort + 1;
        // });
        setnowUser({ ...nowUser,thirdPageIds:val,threeItemList:[...res.data] });
      }
    });
  }
  const beforeUpload = (file1) => {
    // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    // if (!isJpgOrPng) {
    //   message.error('只能上传图片!');
    // }
        var forms = new FormData();
        var configs = {
          headers: { "Content-Type": "multipart/form-data" }
        };
        forms.append("file", file1);
        setloading(true);
        axios
          .post(`${apiPerfix}/upload/qiniu`, forms, configs)
          .then((res) => {
            setloading(false);
            setnowUser({ ...nowUser, href: res });
          })
          .catch(() => {
            setloading(true);
          });

    return false;
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onChange = (checked) => {
    setnowUser({...nowUser,isTop:checked})
    console.log(`switch to ${checked}`);
  };
  const onChange2 = (checked) => {
    setnowUser({...nowUser,isShow:checked})
    console.log(`switch to ${checked}`);
  };
  const onChangeThree = (checked) => {
    setnowUser({...nowUser,isThree:checked})
    console.log(`switch to ${checked}`);
  };
  return (
    <Modal
      title={props.chapterInfo.title ? "编辑" : "新增品牌"}
      footer={null}
      width={800}
      visible={props.isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {props.isModalVisible ? (
        <div className="editWrapper">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            form={{ ...myform }}
          >
            <Form.Item
              label="标题"
              name="title"
            >
              <Input />
            </Form.Item>

            <Form.Item
                label="序号"
                name="sort"
            >
              <Input type="number" />
            </Form.Item>

            <Form.Item
                label="顶部菜单"
                name="isTop"
            >
              <Switch checked={nowUser.isTop} onChange={onChange} />
            </Form.Item>

            <Form.Item
                label="是否显示菜单"
                name="isShow"
            >
              <Switch checked={nowUser.isShow} onChange={onChange2} />
            </Form.Item>

            <Form.Item
                label="菜单层级限制"
                name="treeLimit"
            >
              <Input type="number" disabled={nowUser.menuType!=1} />
            </Form.Item>

            {/*<Form.Item*/}
            {/*    label="图片展示方式"*/}
            {/*>*/}
            {/*  <Select*/}
            {/*      defaultValue="垂直"*/}
            {/*      style={{ width: 120 }}*/}
            {/*      onChange={handleChange}*/}
            {/*      options={[*/}
            {/*        {*/}
            {/*          value: 0,*/}
            {/*          label: '垂直',*/}
            {/*        },*/}
            {/*        {*/}
            {/*          value: 1,*/}
            {/*          label: '横向',*/}
            {/*        }*/}
            {/*      ]}*/}
            {/*  />*/}
            {/*</Form.Item>*/}

            <Form.Item
              label="链接"
              name="upload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                name="href"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="/"
                beforeUpload={beforeUpload}
              >
                {nowUser.href ? (
                  <img
                    src={nowUser.href}
                    alt="avatar"
                    style={{
                      width: "100%"
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
              <div className="warnImgSize">
                单个品牌图片比例为：1:1，多个品牌时图片比例为：2:1
              </div>
            </Form.Item>
            <Form.Item
                label="是否需要关联三级页面"
            >
            <Switch checked={nowUser.isThree} onChange={onChangeThree} />
            </Form.Item>

            {nowUser.isThree?<Form.Item
                label="关联的三级页面组"
            >
              <Cascader
                  changeOnSelect
                  defaultValue={props.chapterInfo.thirdPageIds}
                  fieldNames={{ label: 'title', value: 'id', children: 'children' }}
                  style={{ width: 250 }}
                  onChange={handleChangeThree}
                  options={props.threeList}
              />
            </Form.Item>:''}
            {nowUser.isThree?<Form.Item
                label="关联的三级页面项"
                name="thirdPageId"
            >
              <Select
                  fieldNames={{ label: 'title', value: 'id'}}
                  defaultValue={nowUser.threeItemList?.length>0?props.chapterInfo.thirdPageId:'请选择组'}
                  style={{ width: 120 }}
                  options={nowUser.threeItemList}
              />
            </Form.Item>:''}


            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default Video;
