import React, { useEffect, useRef, useState } from "react";
import { Space, Table, Button, Modal, message, Drawer,Popconfirm } from "antd";
import {
  addNewInvite,
  getbrandYear,
  addbrandYear,
  delbrandYear,
  updatebrandYear
} from "../../api/demo";
import { Form, Input, Image, Upload } from "antd";
import "./index.scss";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { apiPerfix, resourceUrl } from "../../api/config";

// 富文本
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
import BrandDisplacement from "../brandDisplacement/index";
import Resizer from "react-image-file-resizer";

const AddInvite = (props) => {
  const onFinish = (vals) => {
    console.log('valus=',vals)
    props.submit(vals);
  };

  return (
    <Modal
      title="添加邀请码"
      onCancel={props.close}
      footer={null}
      visible={props.isShowAddInvite}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="邀请码"
          name="inviteCode"
          rules={[{ required: true, message: "请输入邀请码!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="代理ID"
          name="proxyId"
          rules={[{ required: true, message: "请输入代理ID!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Chapter = (props) => {
  const [chapterList, setchapterList] = useState([]);
  const [isModalVisible, setisModalVisible] = useState(false);
  const [htmlContent, sethtmlContent] = useState("");
  const [isShowPreview, setisShowPreview] = useState(false);
  const [isShowAddInvite, setisShowAddInvite] = useState(false);
  const [nowChapterInfo, setnowChapterInfo] = useState({});
  const [isShowDrawer, setisShowDrawer] = useState(false);
  const [twoLevelInfo, settwoLevelInfo] = useState({});

  const manageYear = (obj) => {
    setisShowDrawer(true);
    settwoLevelInfo(obj);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "标题",
      dataIndex: "title",
      key: "id"
    },
    {
      title: "图片",
      dataIndex: "picUrl",
      key: "id",
      render: (a) => (a ? <Image width={200} src={a} /> : "暂无链接")
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" block onClick={() => editChapter(record)}>
            编辑
          </Button>
          <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delChapter(record)}
          okText="确定"
          cancelText="取消"
        >
          <Button type="danger" block>
            删除
          </Button></Popconfirm>
          <Button type="danger" block onClick={() => manageYear(record)}>
            管理排量
          </Button>
          {/* <Button type="danger" block onClick={() => detailChapter(record)}>
            详情
          </Button> */}
          {/* <Button type="danger" block onClick={() => setInviteCode(record)}>
            生成邀请码
          </Button> */}
        </Space>
      )
    }
  ];

  const manageTwoLevel = (obj) => {
    setisShowDrawer(true);
    console.log("obj");
    console.log(obj);
    settwoLevelInfo(obj);
  };

  const closeTwoLevel = () => {
    setisShowDrawer(false);
  };

  const editChapter = (obj) => {
    setnowChapterInfo(obj);
    setisModalVisible(true);
  };

  const setInviteCode = (obj) => {
    setnowChapterInfo(obj);
    setisShowAddInvite(true);
  };

  const saveNewInviteCode = (obj) => {
    addNewInvite({
      ...obj
    }).then((res) => {
      setisShowAddInvite(false);
      message.success("新增成功");
    });
  };

  const detailChapter = (obj) => {
    setisShowPreview(true);
    sethtmlContent(obj.content);
  };

  const delChapter = (obj) => {
    delbrandYear({
      id: obj.id
    }).then((res) => {
      let arr = [...chapterList];
      let index = chapterList.findIndex((v) => v.id === obj.id);
      arr.splice(index, 1);
      setchapterList([...arr]);
      message.success("删除成功");
    });
  };

  const addDialog = () => {
    setnowChapterInfo({});
    setTimeout(() => {
      setisModalVisible(true);
    }, 0);
  };

  const cancle = () => {
    setisModalVisible(false);
  };

  const addInviteSubmit = (vals) => {
    saveNewInviteCode({
      ...vals,
      ...nowChapterInfo,
      chapterId: nowChapterInfo.id,
      viewCount: 0
    });
  };

  const closeAddInviteDialog = () => {
    setisShowAddInvite(false);
  };

  const getList = () => {
    getbrandYear({
      parentId: props.parentId ? props.parentId : -1
    }).then((res) => {
      if (Array.isArray(res)) {
        res.forEach((element) => {
          element.key = element.id;
        });
        setchapterList([...res]);
      }
    });
  };

  useEffect(() => {
    if (!isModalVisible) {
      getList();
      setnowChapterInfo({});
    }
  }, [isModalVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="chapterContainer">
      <TwoLevelCom
        info={twoLevelInfo}
        closeTwoLevel={closeTwoLevel}
        isShowDrawer={isShowDrawer}
      ></TwoLevelCom>
      <EditCom
        cancle={cancle}
        isModalVisible={isModalVisible}
        chapterInfo={nowChapterInfo}
        parentId={props.parentId}
      ></EditCom>
      <div className="addWrapper">
        <Button type="primary" block onClick={addDialog}>
          新增
        </Button>
      </div>
      <div className="tableList">
        <Table columns={columns} dataSource={chapterList} />
      </div>

      <Modal
        title="预览详情"
        footer={null}
        visible={isShowPreview}
        onOk={() => setisShowPreview(false)}
        onCancel={() => setisShowPreview(false)}
      >
        <div
          className=""
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        ></div>
      </Modal>
      <AddInvite
        close={closeAddInviteDialog}
        isShowAddInvite={isShowAddInvite}
        submit={addInviteSubmit}
      ></AddInvite>
    </div>
  );
};

const TwoLevelCom = (props) => {
  const onClose = () => {
    props.closeTwoLevel();
  };

  return (
    <Drawer
      width={900}
      title={props.info.title}
      placement="right"
      onClose={onClose}
      visible={props.isShowDrawer}
      open={props.isShowDrawer}
    >
      {props.isShowDrawer && (
        <BrandDisplacement parentId={props.info.id}></BrandDisplacement>
      )}
    </Drawer>
  );
};

// 编辑

const EditCom = (props) => {
  const htmlVal = useRef("");
  const [nowUser, setnowUser] = useState({});
  const [loading, setloading] = useState(false);

  const [htmlStr, sethtmlStr] = useState(
    BraftEditor.createEditorState("<p></p>")
  );

  useEffect(() => {
    setnowUser(props.chapterInfo);
  }, [props.chapterInfo]);

  const [myform] = Form.useForm();
  myform.setFieldsValue(props.chapterInfo);

  useEffect(() => {
    sethtmlStr(BraftEditor.createEditorState(props.chapterInfo.content || ""));
  }, [props.chapterInfo.content]);

  const handleOk = () => {
    console.log("1");
  };

  const handleCancel = () => {
    props.cancle();
  };

  const handleEditorChange = (editorValue) => {
    let html = editorValue.toHTML();
    htmlVal.current = html;
  };

  const beforeUpload = (file1) => {
          const isJpgOrPng =
              file1.type === "image/jpeg" || file1.type === "image/png";
          if (!isJpgOrPng) {
            message.error("只能上传图片!");
          }

          var forms = new FormData();
          var configs = {
            headers: { "Content-Type": "multipart/form-data" }
          };
          forms.append("file", file1);
          setloading(true);
          axios
              .post(`${apiPerfix}/upload/qiniu`, forms, configs)
              .then((res) => {
                console.log(111111);
                setloading(false);
                setnowUser({ ...nowUser, picUrl: res });
              })
              .catch((error) => {
                console.log("error");
                console.log(error);
                setloading(true);
              });
    return false;
  };
  const onFinish = (vals) => {
    console.log("vals=",vals,props.parentId)
    // 分情况是编辑还是新增
    if (Object.keys(props.chapterInfo || {}).length > 0) {
      updatebrandYear({
        ...vals,
        picUrl: nowUser.picUrl,
        id: props.chapterInfo.id,
        // content: htmlVal.current
      }).then((res) => {
        props.cancle();
        // myform.setFieldsValue({
        //   appHref: "",
        //   content: ""
        // });
        message.success("更新成功");
      });
    } else {
      addbrandYear({
        ...vals,
        picUrl: nowUser.picUrl,
        // content: htmlVal.current,
        parentId: props.parentId
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          appHref: "",
          content: ""
        });
        message.success("新增成功");
      });
    }
  };

  const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
  );

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    console.log("e=",e)
    return e?.fileList;
  };

  const [fileList, setfileList] = useState([]);

  return (
    <Modal
      title={props.chapterInfo.title ? "编辑" : "新增年份"}
      footer={null}
      width={800}
      visible={props.isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {props.isModalVisible ? (
        <div className="editWrapper">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            form={{ ...myform }}
          >
            <Form.Item
              label="标题"
              name="title"
              rules={[{ required: true, message: "请输入年份!" }]}
            >
              <Input placeholder="请输入年份" />
            </Form.Item>
                  <Form.Item
              label="链接"
              name="picUrl"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              >
              <Upload
                  name="href"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="/"
                  beforeUpload={beforeUpload}
              >
                {nowUser.picUrl ? (
                    <img
                        src={nowUser.picUrl}
                        alt="avatar"
                        style={{
                          width: "100%"
                        }}
                    />
                ) : (
                    uploadButton
                )}
              </Upload>
              <div className="warnImgSize">图片比例为：1:1</div>
              </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default Chapter;
