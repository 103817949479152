/*
 * @Author: your name
 * @Date: 2021-06-16 14:28:39
 * @LastEditTime: 2021-06-21 13:47:58
 * @LastEditTime: 2021-06-18 14:05:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /react-demo/src/api/demo.js
 */
import request from "../util/request";
import { apiPerfix } from "./config.js";

export function login(data) {
  return request({
    url: `${apiPerfix}/login`,
    method: "post",
    data,
    meta: {
      isSerialize: true
    }
  });
}

export function uploadUserInfo(data) {
  return request({
    url: `${apiPerfix}/user/update`,
    method: "post",
    data
  });
}

export function uploadFile(data) {
  return request({
    url: `${apiPerfix}/file/upload`,
    method: "post",
    data
  });
}

export function getChapterList(params) {
  return request({
    url: `${apiPerfix}/chapter/list`,
    method: "get",
    params
  });
}

//三级管理
export function BlogBrandCommonThirdPage() {
  return request({
    url: `${apiPerfix}/BlogBrandCommonThirdPage/list`,
    method: "get"
  });
}

//明细管理
export function BlogBrandVideoMenuTreeDetail(video,parentId) {
  return request({
    url: `${apiPerfix}/BlogBrandVideoMenuTreeDetail/${video}/getList?parentId=${parentId}`,
    method: "get"
  });
}


export function addBlogBrandVideoMenuTreeDetail(data) {
  return request({
    url: `${apiPerfix}/BlogBrandVideoMenuTreeDetail/${data.type}/add`,
    method: "post",
    data
  });
}
export function copyBlogBrandVideoMenuTree(params) {
  return request({
    url: `${apiPerfix}/BlogBrandVideoMenuTree/copy`,
    method: "post",
    params
  });
}
export function updateBlogBrandVideoMenuTreeDetail(data) {
  return request({
    url: `${apiPerfix}/BlogBrandVideoMenuTreeDetail/${data.type}/update`,
    method: "post",
    data
  });
}

export function delBlogBrandVideoMenuTreeDetail(params) {
  return request({
    url: `${apiPerfix}/BlogBrandVideoMenuTreeDetail/${params.type}/del`,
    method: "get",
    params
  });
}


//轮播图管理
export function BlogBrandVideoMenuTreeLunbo(video,parentId) {
  return request({
    url: `${apiPerfix}/BlogBrandVideoMenuTreeLunbo/${video}/getList?parentId=${parentId}`,
    method: "get"
  });
}


export function addBlogBrandVideoMenuTreeLunbo(data) {
  return request({
    url: `${apiPerfix}/BlogBrandVideoMenuTreeLunbo/${data.type}/add`,
    method: "post",
    data
  });
}

export function updateBlogBrandVideoMenuTreeLunbo(data) {
  return request({
    url: `${apiPerfix}/BlogBrandVideoMenuTreeLunbo/${data.type}/update`,
    method: "post",
    data
  });
}

export function delBlogBrandVideoMenuTreeLunbo(params) {
  return request({
    url: `${apiPerfix}/BlogBrandVideoMenuTreeLunbo/${params.type}/del`,
    method: "get",
    params
  });
}

//数据管理

export function BlogBrandVideoMenuTreeData(params) {
  return request({
    url: `${apiPerfix}/BlogBrandVideoMenuTreeData/${params.type}/getList?parentId=${params.parentId}`,
    method: "get"
  });
}


export function addBlogBrandVideoMenuTreeData(data) {
  return request({
    url: `${apiPerfix}/BlogBrandVideoMenuTreeData/${data.type}/add`,
    method: "post",
    data
  });
}

export function updateBlogBrandVideoMenuTreeData(data) {
  return request({
    url: `${apiPerfix}/BlogBrandVideoMenuTreeData/${data.type}/update`,
    method: "post",
    data
  });
}

export function delBlogBrandVideoMenuTreeData(params) {
  return request({
    url: `${apiPerfix}/BlogBrandVideoMenuTreeData/${params.type}/del`,
    method: "get",
    params
  });
}

//菜单列表
export function BlogBrandVideoMenuTree(params) {
  return request({
    url: `${apiPerfix}/BlogBrandVideoMenuTree/fetch`,
    method: "get",
    params
  });
}

export function getChapterDetail(params) {
  return request({
    url: `${apiPerfix}/chapter/getChapterDetail`,
    method: "get",
    params
  });
}

export function addBlogBrandVideoMenuTree(data) {
  return request({
    url: `${apiPerfix}/BlogBrandVideoMenuTree/add`,
    method: "post",
    data
  });
}

export function updateBlogBrandVideoMenuTree(data) {
  return request({
    url: `${apiPerfix}/BlogBrandVideoMenuTree/update`,
    method: "post",
    data
  });
}

export function delBlogBrandVideoMenuTree(params) {
  return request({
    url: `${apiPerfix}/BlogBrandVideoMenuTree/del`,
    method: "get",
    params
  });
}

export function addNewChapter(data) {
  return request({
    url: `${apiPerfix}/chapter/add`,
    method: "post",
    data
  });
}
export function delNewChapter(params) {
  return request({
    url: `${apiPerfix}/chapter/del`,
    method: "get",
    params
  });
}

export function updateChapter(data) {
  return request({
    url: `${apiPerfix}/chapter/update`,
    method: "post",
    data
  });
}

export function getInviteList(params) {
  return request({
    url: `${apiPerfix}/invite/list`,
    method: "get",
    params
  });
}

export function addNewInvite(data) {
  return request({
    url: `${apiPerfix}/invite/add`,
    method: "post",
    data
  });
}

export function delNewInvite(params) {
  return request({
    url: `${apiPerfix}/invite/del`,
    method: "get",
    params
  });
}

export function updateInviteViewCount(params) {
  return request({
    url: `${apiPerfix}/invite/update`,
    method: "get",
    params
  });
}

export function updateInviteData(params) {
  return request({
    url: `${apiPerfix}/invite/updateData`,
    method: "get",
    params
  });
}

export function getUserInfo() {
  return request({
    url: `${apiPerfix}/getUserInfo`,
    method: "get"
  });
}
//卡钳测量
export function getKaQian() {
  return request({
    url: `${apiPerfix}/KaQian/getList`,
    method: "get"
  });
}
export function updateKaQian(data) {
  return request({
    url: `${apiPerfix}/KaQian/update`,
    method: "post",
    data
  });
}
//动态管理
export function getBlogBrandDynamic() {
  return request({
    url: `${apiPerfix}/BlogBrandDynamic/getList`,
    method: "get"
  });
}

export function updateBlogBrandDynamic(data) {
  return request({
    url: `${apiPerfix}/BlogBrandDynamic/update`,
    method: "post",
    data
  });
}

export function addBlogBrandDynamic(data) {
  return request({
    url: `${apiPerfix}/BlogBrandDynamic/add`,
    method: "post",
    data
  });
}

export function delBlogBrandDynamic(params) {
  return request({
    url: `${apiPerfix}/BlogBrandDynamic/del`,
    method: "get",
    params
  });
}

// 三级页面管理
export function getPageGroup() {
  return request({
    url: `${apiPerfix}/PageGroup/list`,
    method: "get"
  });
}

export function getBlogBrandCommonThirdPage(params) {
  return request({
    url: `${apiPerfix}/BlogBrandCommonThirdPage/list`,
    method: "get",
    params
  });
}
export function addBlogBrandCommonThirdPage(data) {
  return request({
    url: `${apiPerfix}/BlogBrandCommonThirdPage/add`,
    method: "post",
    data
  });
}
export function updateBlogBrandCommonThirdPage(data) {
  return request({
    url: `${apiPerfix}/BlogBrandCommonThirdPage/update`,
    method: "post",
    data
  });
}
export function delBlogBrandCommonThirdPage(params) {
  return request({
    url: `${apiPerfix}/BlogBrandCommonThirdPage/del`,
    method: "get",
    params
  });
}
export function delPageGroup(params) {
  return request({
    url: `${apiPerfix}/PageGroup/del`,
    method: "get",
    params
  });
}

export function addPageGroup(data) {
  return request({
    url: `${apiPerfix}/PageGroup/add`,
    method: "post",
    data
  });
}

export function updatePageGroup(data) {
  return request({
    url: `${apiPerfix}/PageGroup/update`,
    method: "post",
    data
  });
}


// 视频列表
export function getVideoList() {
  return request({
    url: `${apiPerfix}/video/getList`,
    method: "get"
  });
}
export function getAllVideoList() {
  return request({
    url: `${apiPerfix}/video/getAllList`,
    method: "get"
  });
}

export function addVideo(data) {
  return request({
    url: `${apiPerfix}/video/add`,
    method: "post",
    data
  });
}

export function getDetailVideo(params) {
  return request({
    url: `${apiPerfix}/video/getDetail`,
    method: "get",
    params
  });
}

export function delVideo(params) {
  return request({
    url: `${apiPerfix}/video/del`,
    method: "get",
    params
  });
}

export function updateVideo(data) {
  return request({
    url: `${apiPerfix}/video/update`,
    method: "post",
    data
  });
}
export function getwxuserList() {
  return request({
    url: `${apiPerfix}/wx/list`,
    method: "get"
  });
}
//统计
export function getTjBasicList() {
  return request({
    url: `${apiPerfix}/statistic/fetchBasic`,
    method: "get"
  });
}
export function getTjOnlineList() {
  return request({
    url: `${apiPerfix}/statistic/fetchOnline`,
    method: "get"
  });
}
// 评论管理
export function getpinglunList() {
  return request({
    url: `${apiPerfix}/pinglun/list`,
    method: "get"
  });
}

export function addpinglun(data) {
  return request({
    url: `${apiPerfix}/pinglun/addPinglun`,
    method: "post",
    data
  });
}

export function delpinglun(params) {
  return request({
    url: `${apiPerfix}/pinglun/del`,
    method: "get",
    params
  });
}
export function toppinglun(params) {
  return request({
    url: `${apiPerfix}/pinglun/top`,
    method: "get",
    params
  });
}
export function unToppinglun(params) {
  return request({
    url: `${apiPerfix}/pinglun/unTop`,
    method: "get",
    params
  });
}
export function showpinglun(params) {
  return request({
    url: `${apiPerfix}/pinglun/show`,
    method: "get",
    params
  });
}
export function unShowpinglun(params) {
  return request({
    url: `${apiPerfix}/pinglun/unShow`,
    method: "get",
    params
  });
}

// 海报管理
export function gethaibaoList() {
  return request({
    url: `${apiPerfix}/brandPoster/list`,
    method: "get"
  });
}

export function addhaibao(data) {
  return request({
    url: `${apiPerfix}/brandPoster/add`,
    method: "post",
    data
  });
}

export function delhaibao(params) {
  return request({
    url: `${apiPerfix}/brandPoster/del`,
    method: "get",
    params
  });
}
export function updatehaibao(data) {
  return request({
    url: `${apiPerfix}/brandPoster/update`,
    method: "post",
    data
  });
}

// 签约管理
export function getqianYueList() {
  return request({
    url: `${apiPerfix}/qianYue/list`,
    method: "get"
  });
}

export function addqianYue(data) {
  return request({
    url: `${apiPerfix}/qianYue/add`,
    method: "post",
    data
  });
}

export function delqianYue(params) {
  return request({
    url: `${apiPerfix}/qianYue/del`,
    method: "get",
    params
  });
}
export function updateqianYue(data) {
  return request({
    url: `${apiPerfix}/qianYue/update`,
    method: "post",
    data
  });
}

// 图片列表
export function getPictureList() {
  return request({
    url: `${apiPerfix}/picture/getList`,
    method: "get"
  });
}

export function addPicture(data) {
  return request({
    url: `${apiPerfix}/picture/main_list/add`,
    method: "post",
    data
  });
}

export function delPicture(params) {
  return request({
    url: `${apiPerfix}/picture/del`,
    method: "get",
    params
  });
}

export function getDetailPicture(params) {
  return request({
    url: `${apiPerfix}/picture/getDetail`,
    method: "get",
    params
  });
}

export function updatePicture(params) {
  return request({
    url: `${apiPerfix}/picture/update`,
    method: "get",
    params
  });
}

// app List

export function getAppList() {
  return request({
    url: `${apiPerfix}/app/getList`,
    method: "get"
  });
}

export function addApp(data) {
  return request({
    url: `${apiPerfix}/app/add`,
    method: "post",
    data
  });
}

export function getDetailApp(params) {
  return request({
    url: `${apiPerfix}/app/getDetail`,
    method: "get",
    params
  });
}

export function delApp(params) {
  return request({
    url: `${apiPerfix}/app/del`,
    method: "get",
    params
  });
}

export function updateApp(params) {
  return request({
    url: `${apiPerfix}/app/update`,
    method: "get",
    params
  });
}

// 图片列表
export function getTyresList() {
  return request({
    url: `${apiPerfix}/tyre/getList`,
    method: "get"
  });
}

export function addTyres(data) {
  return request({
    url: `${apiPerfix}/tyre/add`,
    method: "post",
    data
  });
}

export function updateTyres(data) {
  return request({
    url: `${apiPerfix}/tyre/update`,
    method: "post",
    data
  });
}

export function delTyres(params) {
  return request({
    url: `${apiPerfix}/tyre/del`,
    method: "get",
    params
  });
}

// 厂家品牌
export function updateManufactorBrand(data) {
  return request({
    url: `${apiPerfix}/ManufactorBrand/update`,
    method: "post",
    data
  });
}

// 图片列表
export function getManufactorBrandList() {
  return request({
    url: `${apiPerfix}/ManufactorBrand/list`,
    method: "get"
  });
}

export function addManufactorBrand(data) {
  return request({
    url: `${apiPerfix}/ManufactorBrand/add`,
    method: "post",
    data
  });
}

export function delManufactorBrand(params) {
  return request({
    url: `${apiPerfix}/ManufactorBrand/del`,
    method: "get",
    params
  });
}

// 厂家品牌-车系
export function updatebrandType(data) {
  return request({
    url: `${apiPerfix}/brandType/update`,
    method: "post",
    data
  });
}

// 图片列表
export function getbrandType(params) {
  return request({
    url: `${apiPerfix}/brandType/list`,
    method: "get",
    params
  });
}

export function addbrandType(data) {
  return request({
    url: `${apiPerfix}/brandType/add`,
    method: "post",
    data
  });
}

export function delbrandType(params) {
  return request({
    url: `${apiPerfix}/brandType/del`,
    method: "get",
    params
  });
}

// 厂家品牌-年份
export function updatebrandYear(data) {
  return request({
    url: `${apiPerfix}/brandYear/update`,
    method: "post",
    data
  });
}

// 图片列表
export function getbrandYear(params) {
  return request({
    url: `${apiPerfix}/brandYear/list`,
    method: "get",
    params
  });
}

export function addbrandYear(data) {
  return request({
    url: `${apiPerfix}/brandYear/add`,
    method: "post",
    data
  });
}

export function delbrandYear(params) {
  return request({
    url: `${apiPerfix}/brandYear/del`,
    method: "get",
    params
  });
}

// 厂家品牌-排量
export function updatebrandPower(data) {
  return request({
    url: `${apiPerfix}/brandPower/update`,
    method: "post",
    data
  });
}

// 图片列表
export function getbrandPower(params) {
  return request({
    url: `${apiPerfix}/brandPower/list`,
    method: "get",
    params
  });
}

export function addbrandPower(data) {
  return request({
    url: `${apiPerfix}/brandPower/add`,
    method: "post",
    data
  });
}

export function delbrandPower(params) {
  return request({
    url: `${apiPerfix}/brandPower/del`,
    method: "get",
    params
  });
}

// 厂家品牌-详情
export function updatebrandDetail(data) {
  return request({
    url: `${apiPerfix}/brandDetail/update`,
    method: "post",
    data
  });
}

// 图片列表
export function getbrandDetail(params) {
  return request({
    url: `${apiPerfix}/brandDetail/list`,
    method: "get",
    params
  });
}

export function addbrandDetail(data) {
  return request({
    url: `${apiPerfix}/brandDetail/add`,
    method: "post",
    data
  });
}

export function delbrandDetail(params) {
  return request({
    url: `${apiPerfix}/brandDetail/del`,
    method: "get",
    params
  });
}

// 图片列表
export function getUserListPhone(params) {
  return request({
    url: `${apiPerfix}/getUserList`,
    method: "get",
    params
  });
}

export function getChapterDetailDetail(params) {
  return request({
    url: `${apiPerfix}/chapter/detail`,
    method: "get",
    params
  });
}
