import React, { useEffect, useRef, useState } from "react";
import * as qiniu from "qiniu-js"
import {Space, Table, Button, Modal, message, Drawer, Select, Cascader, Popconfirm, Switch} from "antd";
import Resizer from "react-image-file-resizer";
import {
  getChapterList,
  addBlogBrandVideoMenuTree,
  addBlogBrandVideoMenuTreeDetail,
  addBlogBrandVideoMenuTreeLunbo,
  addBlogBrandVideoMenuTreeData,
  delBlogBrandVideoMenuTree,
  addNewInvite,
  updateBlogBrandVideoMenuTree,
  updateBlogBrandVideoMenuTreeDetail,
  delBlogBrandVideoMenuTreeDetail,
  updateBlogBrandVideoMenuTreeLunbo,
  updateBlogBrandVideoMenuTreeData,
  delBlogBrandVideoMenuTreeLunbo,
  delBlogBrandVideoMenuTreeData,
  getChapterDetailDetail,
  BlogBrandVideoMenuTreeDetail,
  getPageGroup,
  BlogBrandVideoMenuTreeLunbo,
  BlogBrandVideoMenuTreeData, getBlogBrandCommonThirdPage
} from "../../api/demo";
import { Form, Input, Image, Upload } from "antd";
import "./index.scss";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { apiPerfix, resourceUrl } from "../../api/config";
import { ContentUtils } from "braft-utils";
// 富文本
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";

const AddInvite = (props) => {
  const onFinish = (vals) => {
    console.log(vals)
    props.submit(vals);
  };

  return (
    <Modal
      title="添加邀请码"
      onCancel={props.close}
      footer={null}
      visible={props.isShowAddInvite}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="邀请码"
          name="inviteCode"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="代理ID"
          name="proxyId"
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Msg = (props) => {
  console.log(props)
  const [chapterList, setchapterList] = useState([]);
  const [threeList,setthreeList]=useState([])
  const [isModalVisible, setisModalVisible] = useState(false);
  const [htmlContent, sethtmlContent] = useState("");
  const [isShowPreview, setisShowPreview] = useState(false);
  const [isShowAddInvite, setisShowAddInvite] = useState(false);
  const [nowChapterInfo, setnowChapterInfo] = useState({});
  const [isShowDrawer, setisShowDrawer] = useState(false);
  const [threeColumn,setthreeColumn]=useState([])
  const [twoLevelInfo, settwoLevelInfo] = useState({});
  const [ShowThreeDrawer, setShowThreeDrawer] = useState(false);
  const [threeLevelInfo, setthreeLevelInfo] = useState({});
  const [columns,setcolumns]=useState([])
  const [typeMsg,settypeMsg]=useState("明细管理")
  // const columns = [
  //   {
  //     title: "序号",
  //     dataIndex: "sort",
  //     key: "id"
  //   },
  //   {
  //     title: "标题",
  //     dataIndex: "title",
  //     key: "id"
  //   },
  //   {
  //     title: "图片展示方式",
  //     dataIndex: "picListType",
  //     key: "id",
  //     render: (a) => (a==0 ? "垂直": "横向")
  //     // render: (a) =>
  //     //   a ? (
  //     //     <a href={a} target="_blank">
  //     //       {a}
  //     //     </a>
  //     //   ) : (
  //     //     "暂无链接"
  //     //   )
  //   },
  //   {
  //     title: "操作",
  //     key: "action",
  //     render: (_, record, index) => (
  //       <Space size="middle">
  //         <Button
  //           type="primary"
  //           block
  //           onClick={() => editChapter(record, index)}
  //         >
  //           编辑
  //         </Button>
  //         <Button type="danger" block onClick={() => delChapter(record)}>
  //           删除
  //         </Button>
  //         {/* <Button type="danger" block onClick={() => detailChapter(record)}>
  //           详情
  //         </Button> */}
  //         {/* <Button type="danger" block onClick={() => setInviteCode(record)}>
  //           生成邀请码
  //         </Button> */}
  //       </Space>
  //     )
  //   }
  // ];

  const manageTwoLevel = (obj) => {
    setisShowDrawer(true);
    console.log("obj");
    console.log(obj);
    settwoLevelInfo(obj);
  };

  const closeTwoLevel = () => {
    setisShowDrawer(false);
  };
  const closeThreeLevel=()=>{
    setShowThreeDrawer(false)
  }

  const editChapter = (obj) => {
    console.log("编辑",obj)
    if(obj.thirdPageIds){
      setnowChapterInfo({...obj,thirdPageIds:((obj.thirdPageIds).indexOf(',')!=-1?obj.thirdPageIds.split(',').map(Number):[parseInt(obj.thirdPageIds)])});
    }else{
      setnowChapterInfo({...obj})
    }
    setTimeout(() => {
      setisModalVisible(true);
    }, 0);
  };

  const shujuManager=(obj, index)=>{
    settypeMsg("数据管理")
    setthreeColumn([
      {
        title: "Id",
        dataIndex: "id",
        key: "id"
      },{
        title: "srcDataId",
        dataIndex: "srcDataId",
        key: "srcDataId"
      },{
        title: "srcDetailId",
        dataIndex: "srcDetailId",
        key: "srcDetailId"
      },{
        title: "排序",
        dataIndex: "sort",
        key: "id"
      },

      {
        title: "型号",
        dataIndex: "xinghao",
        key: "id"
      },
      {
        title: "规格",
        dataIndex: "guige",
        key: "id"
      },
      {
        title: "偏距",
        dataIndex: "pianju",
        key: "id"
      },
      {
        title: "H*PCD",
        dataIndex: "hpcd",
        key: "id"
      },
      {
        title: "中心孔",
        dataIndex: "zhongxinkong",
        key: "id"
      },
      {
        title: "颜色",
        dataIndex: "yanse",
        key: "id"
      },
      {
        title: "库存",
        dataIndex: "kucun",
        key: "id"
      },
      {
        title: "操作",
        key: "action",
        render: (_, record, index) => (
            <Space size="middle">
              <Button
                  type="primary"
                  block
                  onClick={() => editChapter(record, index)}
              >
                编辑
              </Button>
              <Popconfirm
                  title="是否确认删除这条数据?"
                  onConfirm={() => delChapter(record)}
                  okText="确定"
                  cancelText="取消"
              >
                <Button type="danger" block>
                  删除
                </Button></Popconfirm>
            </Space>
        )
      }
    ])
    setShowThreeDrawer(true)
    setthreeLevelInfo(obj);
  }

  const setInviteCode = (obj) => {
    setnowChapterInfo(obj);
    setisShowAddInvite(true);
  };

  const saveNewInviteCode = (obj) => {
    addNewInvite({
      ...obj
    }).then((res) => {
      setisShowAddInvite(false);
      message.success("新增成功");
    });
  };

  const detailChapter = (obj) => {
    setisShowPreview(true);
    sethtmlContent(obj.content);
  };

  const delChapter = (obj) => {
    if(props.typeMsg==="明细管理"){
      delBlogBrandVideoMenuTreeDetail({
        id: obj.id,
        type:props.lx
      }).then((res) => {
        let arr = [...chapterList];
        let index = chapterList.findIndex((v) => v.id === obj.id);
        arr.splice(index, 1);
        getList();
        message.success("删除成功");
      });
    }else if(props.typeMsg==="轮播图管理"){
      delBlogBrandVideoMenuTreeLunbo({
        id: obj.id,
        type:props.lx
      }).then((res) => {
        let arr = [...chapterList];
        let index = chapterList.findIndex((v) => v.id === obj.id);
        arr.splice(index, 1);
        getList();
        message.success("删除成功");
      });
    }else{
      delBlogBrandVideoMenuTreeData({
        id: obj.id,
      }).then((res) => {
        let arr = [...chapterList];
        let index = chapterList.findIndex((v) => v.id === obj.id);
        arr.splice(index, 1);
        getList();
        message.success("删除成功");
      });
    }
    // delBlogBrandVideoMenuTree({
    //   id: obj.id
    // }).then((res) => {
    //   let arr = [...chapterList];
    //   let index = chapterList.findIndex((v) => v.id === obj.id);
    //   arr.splice(index, 1);
    //   getList();
    //   message.success("删除成功");
    // });
  };

  const addDialog = () => {
    let arr = [...chapterList];
    setnowChapterInfo({
      indexsort: arr.length + 1
    });
    setTimeout(() => {
      setisModalVisible(true);
    }, 0);
  };

  const cancle = () => {
    setisModalVisible(false);
  };

  const addInviteSubmit = (vals) => {
    saveNewInviteCode({
      ...vals,
      ...nowChapterInfo,
      chapterId: nowChapterInfo.id,
      viewCount: 0
    });
  };

  const closeAddInviteDialog = () => {
    setisShowAddInvite(false);
  };
  let parentId=props.videoId ? props.videoId : 0
  const getThree=()=>{
    getPageGroup().then(res=>{
      console.log('三级分组',res)
      let arr=[];
      res.data.forEach(item=>{
        arr.push(item)
      })
      setthreeList([...arr])
    })
  }
  const getList = () => {
    console.log(props)
    if(props.typeMsg==="明细管理"){
      BlogBrandVideoMenuTreeDetail(props.lx,parentId).then((res) => {
        if (Array.isArray(res.data)) {
          setchapterList([...res.data]);
        }
      }).then(err=>{
        console.log(err)
      });
    }else if(props.typeMsg==="轮播图管理"){
      BlogBrandVideoMenuTreeLunbo(props.lx,parentId).then((res) => {
        if (Array.isArray(res.data)) {
          // res.forEach((element, indexsort) => {
          //   element.key = element.id;
          //   element.indexsort = indexsort + 1;
          // });
          console.log("res");
          console.log(res);
          setchapterList([...res.data]);
        }
      }).then(err=>{
        console.log(err)
      });
    }else{
      //数据管理
      BlogBrandVideoMenuTreeData({parentId:parentId,type:props.lx}).then((res) => {
        if (Array.isArray(res.data)) {
          // res.forEach((element, indexsort) => {
          //   element.key = element.id;
          //   element.indexsort = indexsort + 1;
          // });
          console.log("res");
          console.log(res);
          setchapterList([...res.data]);
        }
      }).then(err=>{
        console.log(err)
      });
    }
  };

  useEffect(() => {
    if (!isModalVisible) {
      settypeMsg(props.typeMsg)
      let modifiedColumns = props.columns.map(column=>{
        if (column.title === '操作') {
          if(props.typeMsg=='明细管理'){
                return {
                  ...column,
                  render: (_, record, index) => (
                      <Space size="middle">
                        <Button
                            type="primary"
                            block
                            onClick={() => editChapter(record, index)}
                        >
                            编辑
                        </Button>
                        <Popconfirm
                            title="是否确认删除这条数据?"
                            onConfirm={() => delChapter(record)}
                            okText="确定"
                            cancelText="取消"
                        >
                          <Button type="danger" block>
                            删除
                          </Button></Popconfirm>
                        <Button
                            type="primary"
                            block
                            onClick={() => shujuManager(record, index)}
                        >
                          数据管理
                        </Button>
                      </Space>
                  )
                };
          }else{
            return {
              ...column,
              render: (_, record, index) => (
                  <Space size="middle">
                    <Button
                        type="primary"
                        block
                        onClick={() => editChapter(record, index)}
                    >
                      编辑
                    </Button>
                    <Popconfirm
                        title="是否确认删除这条数据?"
                        onConfirm={() => delChapter(record)}
                        okText="确定"
                        cancelText="取消"
                    >
                      <Button type="danger" block>
                        删除
                      </Button></Popconfirm>
                  </Space>
              )
            };
          }
        } else {
          return column;
        }
      })

      setcolumns(modifiedColumns)
      getList();
      setnowChapterInfo({});
      getThree();//获取三级
    }
  }, [isModalVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   getList();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="chapterContainer">
      <ThreeLevelCom
          info={threeLevelInfo}
          closeThreeLevel={closeThreeLevel}
          isShowDrawer={ShowThreeDrawer}
          columns={threeColumn}
          typeMsg={typeMsg}
          lx={'detail'}
      >
      </ThreeLevelCom>
      <TwoLevelCom
        info={twoLevelInfo}
        closeTwoLevel={closeTwoLevel}
        isShowDrawer={isShowDrawer}
      ></TwoLevelCom>
      {isModalVisible && (
        <EditCom
          cancle={cancle}
          isModalVisible={isModalVisible}
          chapterInfo={nowChapterInfo}
          threeList={threeList}
          typeMsg={props.typeMsg}
          msg={props.msg}
          parentId={props.parentId}
          videoId={props.videoId}
          lx={props.lx}
          chapterList={chapterList}
        ></EditCom>
      )}

      <div className="addWrapper">
        <Button type="primary" block onClick={addDialog}>
          新增
        </Button>
      </div>
      <div className="tableList">
        {props.typeMsg=='明细管理'?
        <Table dataSource={chapterList} rowClassName={(record, index) => record.isShow ? 'completed-row' : ''}>
          <Table.Column title="id" dataIndex="id" key="id" />
          <Table.Column title="srcTreeId" dataIndex="srcTreeId" key="id" />
          <Table.Column title="srcDetailId" dataIndex="srcDetailId" key="id" />
          <Table.Column title="序号" dataIndex="sort" key="id" />
          <Table.Column title="标题" dataIndex="title" key="id" />
          <Table.Column title="是否显示明细" dataIndex="isShow" key="id" render={(a, record) => a?'显示':'不显示'}  />
          <Table.Column title="是否显示明细数据表" dataIndex="isDataShow" key="id" render={(a, record) => a?'显示':'不显示'} />
          <Table.Column title="媒体类型" dataIndex="mediaType" key="id" />
          <Table.Column title="关联的三级ID" dataIndex="thirdPageId" key="id" />
          <Table.Column title="链接" dataIndex="picUrl" key="id" render={(a, record) => record.mediaType=='image'?(a ? <Image width={200} src={a} /> : "暂无链接"):(a ?<video width={200} height={100} controls><source src={a} /></video>:'暂无链接')} />
          <Table.Column title="视频封面" dataIndex="thums" key="id" render={(a, record) => record.mediaType=='image'?"图片没有封面":(a ?<Image width={200} src={a} /> :'暂无链接')} />
          <Table.Column title="备注" dataIndex="remark" key="id" />
          <Table.Column title="操作" key="action" render={(record, index) => (
          <Space size="middle">
            <Button
                type="primary"
                block
                onClick={() => editChapter(record, index)}
            >
              编辑
            </Button>
            <Popconfirm
                title="是否确认删除这条数据?"
                onConfirm={() => delChapter(record)}
                okText="确定"
                cancelText="取消"
            >
              <Button type="danger" block>
                删除
              </Button></Popconfirm>
              <Button
                  type="primary"
                  block
                  onClick={() => shujuManager(record, index)}
              >
                数据管理
              </Button>
          </Space>
          )} />
        </Table>:<Table columns={columns} dataSource={chapterList} />}
      </div>

      <Modal
        title="预览详情"
        footer={null}
        visible={isShowPreview}
        onOk={() => setisShowPreview(false)}
        onCancel={() => setisShowPreview(false)}
      >
        <div
          className=""
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        ></div>
      </Modal>
      <AddInvite
        close={closeAddInviteDialog}
        isShowAddInvite={isShowAddInvite}
        submit={addInviteSubmit}
      ></AddInvite>
    </div>
  );
};

const TwoLevelCom = (props) => {
  const onClose = () => {
    props.closeTwoLevel();
  };

  return (
    <Drawer
      title="Basic Drawer"
      placement="right"
      onClose={onClose}
      open={props.isShowDrawer}
    >
      <div>{}</div>
    </Drawer>
  );
};
const ThreeLevelCom = (props) => {
  const onClose = () => {
    props.closeThreeLevel();
  };

  return (
      <div>
        <Drawer
            width={1000}
            title={props.info.title}
            placement="right"
            onClose={onClose}
            visible={props.isShowDrawer}
        >
          {props.isShowDrawer && <Msg parentId={0} lx={props.lx} videoId={props.info.id} typeMsg={props.typeMsg} msg={'detail'} columns={props.columns}></Msg>}
        </Drawer>
      </div>
  );
};
// 编辑

const EditCom = (props) => {
  console.log("编辑")
  const htmlVal = useRef("");
  const [nowUser, setnowUser] = useState({});
  const [loading, setloading] = useState(false);
  const [loading2, setloading2] = useState(false);

  const [htmlStr, sethtmlStr] = useState(
    BraftEditor.createEditorState("<p></p>")
  );

  useEffect(() => {
    console.log(props.chapterInfo)
    let group=props.chapterInfo.thirdPageIds;
    if(group?.length>0){
      getBlogBrandCommonThirdPage({
        groupId: group[group.length-1]
      }).then((res) => {
        if (Array.isArray(res.data)) {
          // res.forEach((element, indexsort) => {
          //   element.key = element.id;
          //   element.indexsort = indexsort + 1;
          // });
          setnowUser({...props.chapterInfo,thums:props.chapterInfo.thums,appHref:props.chapterInfo.picUrl,threeItemList:[...res.data],isThree:true});
        }
      });
    }else{
      setnowUser({...props.chapterInfo,thums:props.chapterInfo.thums,appHref:props.chapterInfo.picUrl,threeItemList:[],isThree:false});
    }
  }, [props.chapterInfo]);

  const [myform] = Form.useForm();
  myform.setFieldsValue(props.chapterInfo);

  // useEffect(() => {
  //   console.log("编辑....",props.chapterInfo)
  //   sethtmlStr(BraftEditor.createEditorState(props.chapterInfo.content || ""));
  // }, [props.chapterInfo.content]);
  const handleChange=(val)=>{
    console.log("图片展示方式",val)
    setnowUser({ ...nowUser, mediaType: val });
  }
  const handleChange2=(val)=>{
    setnowUser({ ...nowUser, isShow: val });
  }
  const handleChange3=(val)=>{
    setnowUser({ ...nowUser, isDataShow: val });
  }
  const onChangeThree = (checked) => {
    setnowUser({...nowUser,isThree:checked})
    console.log(`switch to ${checked}`);
  };
  const handleChangeThree=(val)=>{
    getBlogBrandCommonThirdPage({
      groupId: val[val.length-1]
    }).then((res) => {
      if (Array.isArray(res.data)) {
        // res.forEach((element, indexsort) => {
        //   element.key = element.id;
        //   element.indexsort = indexsort + 1;
        // });
        setnowUser({ ...nowUser,thirdPageIds:val,threeItemList:[...res.data] });
      }
    });
  }
  const handleOk = () => {
    console.log("1");
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleCancel = () => {
    props.cancle();
  };

  const handleEditorChange = (editorValue) => {
    let html = editorValue.toHTML();
    htmlVal.current = html;
    sethtmlStr(html);
  };

  const beforeUpload = (file1) => {
    setloading(true);
    axios
        .post(`${apiPerfix}/upload/token`)
        .then((res) => {
          console.log("token=",res)
          let uploadToken=res;
          let config = {
            useCdnDomain: true,         // 表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
            region: null     // 上传域名区域（z1为华北）,当为 null 或 undefined 时，自动分析上传域名区域
          };

          let putExtra = {
            params: {},         // 放置自定义变量： 'x:name': 'sex'
            mimeType: null      // 限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
          };

          // observable是一个带有 subscribe 方法的类实例
          var observable = qiniu.upload(file1, null, uploadToken, putExtra, config);
          var subscription = observable.subscribe({   // 上传开始
            next: (result) => {
              // 接收上传进度信息，result是带有total字段的 Object
              // loaded: 已上传大小; size: 上传总信息; percent: 当前上传进度
              console.log("上传中",result);
            },
            error: (errResult) => {
              // 上传错误后失败报错
              message.error(errResult)
              console.log(errResult)

              setloading(false);
            },
            complete: (result) => {
              // 接收成功后返回的信息
              console.log(result);

              setloading(false);
              setnowUser({ ...nowUser, appHref:  "http://tyreqiniu.maerodisc.cn/"+result.key });
            }
          })
        })
        .catch(() => {
          message.error("token获取失败")

          setloading(false);
        });
    // console.log(file1)
        // const isJpgOrPng =
        //   file1.type === "video/mp4";

        // var forms = new FormData();
        // var configs = {
        //   headers: { "Content-Type": "multipart/form-data" }
        // };
        // forms.append("file", file1);
        // setloading(true);
        // axios
        //   .post(`${apiPerfix}/upload/qiniu`, forms, configs)
        //   .then((res) => {
        //     setloading(false);
        //     setnowUser({ ...nowUser, appHref: res });
        //   })
        //   .catch(() => {
        //     setloading(true);
        //   });
    return false;
  };


  const beforeUpload2 = (file1) => {
    setloading2(true);
    axios
        .post(`${apiPerfix}/upload/token`)
        .then((res) => {
          console.log("token=",res)
          let uploadToken=res;
          let config = {
            useCdnDomain: true,         // 表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
            region: null     // 上传域名区域（z1为华北）,当为 null 或 undefined 时，自动分析上传域名区域
          };

          let putExtra = {
            params: {},         // 放置自定义变量： 'x:name': 'sex'
            mimeType: null      // 限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
          };

          // observable是一个带有 subscribe 方法的类实例
          var observable = qiniu.upload(file1, null, uploadToken, putExtra, config);
          var subscription = observable.subscribe({   // 上传开始
            next: (result) => {
              // 接收上传进度信息，result是带有total字段的 Object
              // loaded: 已上传大小; size: 上传总信息; percent: 当前上传进度
              console.log("上传中",result);
            },
            error: (errResult) => {
              // 上传错误后失败报错
              message.error(errResult)
              console.log(errResult)

              setloading2(false);
            },
            complete: (result) => {
              // 接收成功后返回的信息
              console.log(result);

              setloading2(false);
              setnowUser({ ...nowUser, thums:  "http://tyreqiniu.maerodisc.cn/"+result.key });
            }
          })
        })
        .catch(() => {
          message.error("token获取失败")

          setloading2(false);
        });
    return false;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const uploadButton2 = (
      <div>
        {loading2 ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
  );

  const onFinish = (vals) => {
    if(props.typeMsg==="明细管理"){

    vals.sort=parseInt(vals.sort)
      // 分情况是编辑还是新增
    if (props.chapterInfo.id) {
      updateBlogBrandVideoMenuTreeDetail({
        ...vals,
        parentId: props.videoId,
        type:props.lx,
        videoId: props.parentId,
        picUrl:nowUser.appHref,
        thirdPageIds: nowUser.isThree?(nowUser.thirdPageIds?nowUser.thirdPageIds.join(","):null):null,
        // thirdPageId:nowUser.thirdPageIds?nowUser.thirdPageIds[nowUser.thirdPageIds.length-1]:null,
        mediaType: nowUser.mediaType?nowUser.mediaType:"image",
        thums:nowUser.mediaType=='video'?nowUser.thums:null,
        isShow:nowUser.isShow?nowUser.isShow:false,
        isDataShow:nowUser.isDataShow?nowUser.isDataShow:false,
        id:props.chapterInfo.id
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          appHref: "",
          content: ""
        });
        message.success("更新成功");
      });
    } else {
      addBlogBrandVideoMenuTreeDetail({
        ...vals,
        parentId: props.videoId,
        type:props.lx,
        videoId: props.parentId,
        thirdPageIds: nowUser.isThree?(nowUser.thirdPageIds?nowUser.thirdPageIds.join(","):null):null,
        // thirdPageId:nowUser.thirdPageIds?nowUser.thirdPageIds[nowUser.thirdPageIds.length-1]:null,
        picUrl:nowUser.appHref,
        isShow:props.lx=='third'?(nowUser.isShow==false?nowUser.isShow:true):(nowUser.isShow?nowUser.isShow:false),
        isDataShow:nowUser.isDataShow?nowUser.isDataShow:false,
        thums:nowUser.mediaType=='video'?nowUser.thums:null,
        mediaType: nowUser.mediaType?nowUser.mediaType:"image"
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          appHref: "",
          content: ""
        });
        message.success("新增成功");
      });
    }
    }else if(props.typeMsg=="轮播图管理"){
      //轮播图管理
      vals.sort=parseInt(vals.sort)
      // 分情况是编辑还是新增
    if (props.chapterInfo.id) {
      updateBlogBrandVideoMenuTreeLunbo({
        ...vals,
        parentId: props.videoId,
        type:props.lx,
        videoId: props.parentId,
        picUrl:nowUser.appHref,
        id:props.chapterInfo.id
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          appHref: "",
          content: ""
        });
        message.success("更新成功");
      });
    } else {
      addBlogBrandVideoMenuTreeLunbo({
        ...vals,
        parentId: props.videoId,
        type:props.lx,
        videoId: props.parentId,
        picUrl:nowUser.appHref,
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          appHref: "",
          content: ""
        });
        message.success("新增成功");
      });
    }
    }else{
      console.log(props)
      //数据管理
      vals.sort=parseInt(vals.sort)
      // 分情况是编辑还是新增
      if (props.chapterInfo.id) {
        updateBlogBrandVideoMenuTreeData({
          ...vals,
          parentId: props.videoId,
          id:props.chapterInfo.id,
          type:props.lx
        }).then((res) => {
          props.cancle();
          myform.setFieldsValue({
            appHref: "",
            content: ""
          });
          message.success("更新成功");
        });
      } else {
        addBlogBrandVideoMenuTreeData({
          ...vals,
          parentId: props.videoId,
          type:props.lx
        }).then((res) => {
          props.cancle();
          myform.setFieldsValue({
            appHref: "",
            content: ""
          });
          message.success("新增成功");
        });
      }

    }
  };

  const controls = [
    "undo",
    "redo",
    "separator",
    "font-size",
    "line-height",
    "letter-spacing",
    "separator",
    "text-color",
    "bold",
    "italic",
    "underline",
    "strike-through",
    "separator",
    "superscript",
    "subscript",
    "remove-styles",
    "emoji",
    "separator",
    "text-indent",
    "text-align",
    "separator",
    "headings",
    "list-ul",
    "list-ol",
    "blockquote",
    "code",
    "separator",
    "link",
    "separator",
    "hr",
    "separator",
    "separator",
    "clear"
  ];


  const handleUpload = async (option) => {
    const formData = new FormData();
    formData.append("file", option.file);
    let configs = {
      headers: { "Content-Type": "multipart/form-data" }
    };
    axios
      .post(`${apiPerfix}/upload/qiniu`, formData, configs)
      .then((res) => {
        console.log("====================================");
        console.log(ContentUtils);
        console.log(
          ContentUtils.insertMedias(
            BraftEditor.createEditorState(htmlVal.current),
            [{ type: "IMAGE", url: res }]
          )
        );
        console.log("====================================");
        sethtmlStr(
          ContentUtils.insertMedias(
            BraftEditor.createEditorState(htmlVal.current),
            [{ type: "IMAGE", url: res }]
          )
        );
      })
      .catch(() => {});
  };

  const extendControls = [
    {
      key: "antd-uploader",
      type: "component",
      component: (
        <Upload
          showUploadList={false}
          customRequest={handleUpload}
          accept=".png,.jpg"
        >
          <Button
            className="control-item button upload-button"
            data-title="上传图片"
          >
            <PlusOutlined />
          </Button>
        </Upload>
      )
    }
  ];

  return (
    <Modal
      title={props.chapterInfo.title ? "编辑" : "新增"}
      footer={null}
      width={800}
      visible={props.isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {props.isModalVisible ? (
        props.typeMsg=="明细管理"?(<div className="editWrapper">
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          form={{ ...myform }}
        >
          <Form.Item
            label="标题"
            name="title"
          >
            <Input />
          </Form.Item>

          <Form.Item
              label="序号"
              name="sort"
            >
              <Input type="number" />
            </Form.Item>

            <Form.Item
            label="链接"
            name="upload"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              name="href"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action="/"
              beforeUpload={beforeUpload}
            >
              {nowUser.appHref ? (nowUser.mediaType=='video'?
                      <video width={100} height={100} controls autoPlay><source src={nowUser.appHref} /></video>
                :<img
                          src={nowUser.appHref}
                          alt="avatar"
                          style={{
                            width: "100%",
                            height: "100px"
                          }}
                      />
              ) : (
                uploadButton
              )}
            </Upload>
            <div className="warnImgSize">
              单个品牌图片比例为：1:1，多个品牌时图片比例为：2:1
            </div>
          </Form.Item>

          {nowUser.mediaType=='video'?<Form.Item
              label="视频封面"
              name="upload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
          >
            <Upload
                name="thums"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="/"
                beforeUpload={beforeUpload2}
            >
              {nowUser.thums!=0&&nowUser.thums!=null ? (
                  <img
                      src={nowUser.thums}
                      alt="avatar"
                      style={{
                        width: "100%",
                        height: "100px"
                      }}
                  />
              ) : (
                  uploadButton2
              )}
            </Upload>
          </Form.Item>:''}

          <Form.Item
              label="媒体类型"
          >
            <Select
                defaultValue={props.chapterInfo.mediaType?props.chapterInfo.mediaType:'image'}
                style={{ width: 120 }}
                onChange={handleChange}
                options={[
                  {
                    value: "video",
                    label: 'video',
                  },
                  {
                    value: "image",
                    label: 'image',
                  }
                ]}
            />
          </Form.Item>

          <Form.Item
              label="是否显示明细"
          >
            <Select
                defaultValue={props.lx=='third'?(props.chapterInfo.isShow==false?props.chapterInfo.isShow:true):props.chapterInfo.isShow?props.chapterInfo.isShow:false}
                style={{ width: 120 }}
                onChange={handleChange2}
                options={[
                  {
                    value: true,
                    label: '显示',
                  },
                  {
                    value: false,
                    label: '不显示',
                  }
                ]}
            />
          </Form.Item>

          <Form.Item
              label="是否显示明细数据表"
          >
            <Select
                defaultValue={props.chapterInfo.isDataShow?props.chapterInfo.isDataShow:false}
                style={{ width: 120 }}
                onChange={handleChange3}
                options={[
                  {
                    value: true,
                    label: '显示',
                  },
                  {
                    value: false,
                    label: '不显示',
                  }
                ]}
            />
          </Form.Item>

          {props.lx!='third'? <Form.Item
              label="是否需要关联三级页面"
          >
            <Switch checked={nowUser.isThree} onChange={onChangeThree} />
          </Form.Item>:''}

          {props.lx!='third'&&nowUser.isThree?<Form.Item
              label="关联的三级页面组"
          >
            <Cascader
                changeOnSelect
                defaultValue={props.chapterInfo.thirdPageIds}
                fieldNames={{ label: 'title', value: 'id', children: 'children' }}
                style={{ width: 250 }}
                onChange={handleChangeThree}
                options={props.threeList}
            />
          </Form.Item>:''}

          {props.lx!='third'&&nowUser.isThree?<Form.Item
              label="关联的三级页面项"
              name="thirdPageId"
          >
            <Select
                fieldNames={{ label: 'title', value: 'id'}}
                defaultValue={nowUser.threeItemList?.length>0?props.chapterInfo.thirdPageId:'请选择组'}
                style={{ width: 120 }}
                options={nowUser.threeItemList}
            />
          </Form.Item>:''}

          <Form.Item
            label="备注"
            name="remark"
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>):(props.typeMsg=="轮播图管理"?(
        // 轮播图管理
        <div className="editWrapper">
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          form={{ ...myform }}
        >

          <Form.Item
              label="序号"
              name="sort"
            >
              <Input type="number" />
            </Form.Item>

            <Form.Item
            label="链接"
            name="upload"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              name="href"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action="/"
              beforeUpload={beforeUpload}
            >
              {nowUser.appHref ? (
                <img
                  src={nowUser.appHref}
                  alt="avatar"
                  style={{
                    width: "100%"
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
            <div className="warnImgSize">
              单个品牌图片比例为：1:1，多个品牌时图片比例为：2:1
            </div>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
      ):(// 数据管理
          <div className="editWrapper">
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
                form={{ ...myform }}
            >

              <Form.Item
                  label="排序"
                  name="sort"
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item
                  label="型号"
                  name="xinghao"
              >
                <Input />
              </Form.Item>

              <Form.Item
                  label="规格"
                  name="guige"
              >
                <Input />
              </Form.Item>

              <Form.Item
                  label="偏距"
                  name="pianju"
              >
                <Input />
              </Form.Item>

              <Form.Item
                  label="H*PCD"
                  name="hpcd"
              >
                <Input />
              </Form.Item>

              <Form.Item
                  label="中心孔"
                  name="zhongxinkong"
              >
                <Input />
              </Form.Item>

              <Form.Item
                  label="颜色"
                  name="yanse"
              >
                <Input />
              </Form.Item>

              <Form.Item
                  label="库存"
                  name="kucun"
              >
                <Input />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  提交
                </Button>
              </Form.Item>
            </Form>
          </div>))
      ) : (
        ""
      )}
    </Modal>
  );
};

export default  Msg;
