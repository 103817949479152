import './index.scss'
import { Button } from 'antd';

const ChpaterItem = (props) => {
  return (
    <div className="ChpaterItem">
      <div className='top line'>
        <div className='name'>{props.info.title}</div>
        <div className='date'>{new Date(props.info.createTime).toLocaleString()}</div>
      </div>
      <div className='bottom line'>
        <div className='src'>{props.info.appHref}</div>
        <Button size='small' type="danger" onClick={() => props.intoDetail(props.info)}>详情</Button>
      </div>
    </div>
  )
}
export default ChpaterItem;