/*
 * @Author: your name
 * @Date: 2021-06-17 14:57:18
 * @LastEditTime: 2021-06-18 15:00:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /react-demo/src/redux/store.js
 */
import { createStore, applyMiddleware } from "redux";
import allReducer from "./reducers/index";

// 引入redux-devtools-extension redux的调试工具
import { composeWithDevTools } from "redux-devtools-extension";

// 异步需要
import thunk from "redux-thunk";

export default createStore(
  allReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
