/*
 * @Author: your name
 * @Date: 2021-05-29 15:20:36
 * @LastEditTime: 2021-06-22 14:54:20
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /react-demo/src/index.js
 */
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import store from "./redux/store";
import { Provider } from "react-redux";
import "./mock/index";
ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      {/* provider的作用是 让后代所有的子组件都可以接受到store */}
      <Provider store={store}>
        <App />
      </Provider>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
