/*
 * @Author: your name
 * @Date: 2021-06-15 14:15:57
 * @LastEditTime: 2021-06-23 14:39:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /react-demo/src/components/layout/presenter.js
 */
import { useState } from "react";
import {
  FileTextOutlined,
  UserOutlined,
  PieChartOutlined,
  AndroidOutlined,
  VideoCameraAddOutlined,
  PictureOutlined,
  LinkedinOutlined,
  TeamOutlined,
  DatabaseOutlined
} from "@ant-design/icons";

const usePresenter = () => {
  const [collapsed, setCollapsed] = useState(false);
  const menuList = [
    // {
    //   name: "个人信息",
    //   path: "/home/projectPage",
    //   icon: <UserOutlined />
    // },

    {
      name: "统计",
      path: "/home/tj",
      icon: <TeamOutlined />
    },
    {
      name: "定制款式",
      path: "/home/chapter",
      icon: <FileTextOutlined />
    },
    // {
    //   name: "防伪码",
    //   path: "/home/invite",
    //   icon: <LinkedinOutlined />
    // },
    {
      name: "轮播图",
      path: "/home/picture",
      icon: <PictureOutlined />
    },
    {
      name: "海报轮胎",
      path: "/home/tyres",
      icon: <PictureOutlined />
    },
    {
      name: "品牌列表",
      path: "/home/video",
      icon: <VideoCameraAddOutlined />
    },
    {
      name: "防伪码",
      path: "/home/app",
      icon: <AndroidOutlined />
    },
    {
      name: "原厂数据",
      path: "/home/manufactorBrand",
      icon: <DatabaseOutlined />
    },
    {
      name: "用户信息",
      path: "/home/phoneList",
      icon: <TeamOutlined />
    },
    {
      name: "通用三级管理",
      path: "/home/threeList",
      icon: <TeamOutlined />
    },
    {
      name: "动态管理",
      path: "/home/dynamic",
      icon: <TeamOutlined />
    },
    {
      name: "卡钳测量",
      path: "/home/kaqian",
      icon: <TeamOutlined />
    },
    {
      name: "签约管理",
      path: "/home/qianyue",
      icon: <TeamOutlined />
    },
    {
      name: "海报管理",
      path: "/home/haibao",
      icon: <TeamOutlined />
    },
    {
      name: "评论管理",
      path: "/home/pinglun",
      icon: <TeamOutlined />
    },
    {
      name: "微信用户管理",
      path: "/home/wxuser",
      icon: <TeamOutlined />
    },
  ];

  const onCollapse = (flag) => {
    setCollapsed(flag);
  };

  return [
    {
      collapsed,
      menuList
    },
    {
      onCollapse
    }
  ];
};

export default usePresenter;
