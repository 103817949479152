import UserInfo from "../components/userInfo";
import { getUserInfo, getChapterList, getPictureList,getVideoList,getAppList } from '../../api/demo.js'
import ChpaterItem from "../components/chapterItem";
import { useEffect, useState } from "react";
import MediuItem from "../components/mediuItem";
import './index.scss'
const Blog = (props) => {

  const [userInfo, setuserInfo] = useState({})
  const [chapterList, setchapterList] = useState([])
  const [typeValue, settypeValue] = useState(0)
  const [pictureList, setpictureList] = useState([])
  const [videoList, setvideoList] = useState([])
  const [appList, setappList] = useState([])

  useEffect(() => {
    // 个人信息
    getUserInfo().then(res => {
      setuserInfo(res)
    })

    getChapterList().then(res => {
      setchapterList(res)
    })

    getPictureList().then(res => {
      setpictureList(res)
    })
    
    getVideoList().then(res => {
      setvideoList(res)
    })

    getAppList().then(res => {
      setappList(res)
    })
  }, [])





  const changeTypeValue = (val) =>  {
    console.log(val);
    settypeValue(val)
  }

  const intoDetail = (obj) => {
    props.history.push(`/blogDetail/${obj.id}`)
  }

  return (
    <div className="blogContainer">
      <div className="leftArea">
        <UserInfo userInfo={userInfo} changeType={changeTypeValue} ></UserInfo>
      </div>
      {
        typeValue === 0 ? 
          <div className="rightArea">
            {
              chapterList.map(v => (
                <ChpaterItem key={v.id} info={v} intoDetail={intoDetail}></ChpaterItem>
              ))
            }
          </div>
        : ''
      }

      {
        typeValue === 1 ? 
          <div className="rightArea">
            <MediuItem type={typeValue} list={pictureList}></MediuItem>
          </div>
        : ''
      }

      {
        typeValue === 2 ? 
          <div className="rightArea">
            <MediuItem type={typeValue} list={videoList}></MediuItem>
          </div>
        : ''
      }

      {
        typeValue === 3 ? 
          <div className="rightArea">
            <MediuItem type={typeValue} list={appList}></MediuItem>
          </div>
        : ''
      }

    </div>
  )
}

export default Blog