/*
 * @Author: slw
 * @Date: 2020-08-14 09:44:53
 * @LastEditTime: 2021-06-18 10:25:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /sdzn-assist-line/src/mock/user.js
 */
import Mock from 'mockjs';
var Random = Mock.Random;

const mockFun = ({ mock }) => {
  if (!mock) return;
  // 用户登录
  Mock.mock('/item/list', 'post', (data) => {
    let obj = Mock.mock({
      'arr|10': [
        {
          'name|+1': Random.word(),
          'key|1-1000': 100,
          'age|1-100': 88,
          'address|+1': Random.word(),
        },
      ],
    });
    return {
      code: 200,
      message: '',
      result: obj.arr,
    };
  });

  // 资源的
  Mock.mock(/\/item\/envList/g, 'get', (params) => {
    let obj = Mock.mock({
      'arr|10': [
        {
          'name|+1': Random.word(),
          'key|1-1000': 100,
          'age|1-100': 88,
          'address|+1': Random.word(),
        },
      ],
    });
    return {
      code: 200,
      message: '',
      result: obj.arr,
    };
  });

  // 服务的
  // 资源的
  Mock.mock(/\/service\/envList/g, 'get', (params) => {
    let obj = Mock.mock({
      'arr|10': [
        {
          'name|+1': Random.word(),
          'key|1-1000': 100,
          'age|1-100': 88,
          'address|+1': Random.word(),
        },
      ],
    });
    return {
      code: 200,
      message: '',
      result: obj.arr,
    };
  });
};

export default mockFun;
