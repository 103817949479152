import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Space,
  Table,
  Button,
  Modal,
  Upload,
  message,
  Drawer,
  Popconfirm,
  Image
} from "antd";
import {
  getManufactorBrandList,
  addManufactorBrand,
  delManufactorBrand,
  updateManufactorBrand
} from "../../api/demo";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { apiPerfix, resourceUrl } from "../../api/config";
import "./index.scss";

import BrandType from "../brandType/index";

const { TextArea } = Input;

const ManufactorBrand = () => {
  const [chapterList, setchapterList] = useState([]);
  const [isModalVisible, setisModalVisible] = useState(false);
  const [htmlContent, sethtmlContent] = useState("");
  const [isShowPreview, setisShowPreview] = useState(false);
  const [nowChapterInfo, setnowChapterInfo] = useState({});

  const [isShowDrawer, setisShowDrawer] = useState(false);
  const [twoLevelInfo, settwoLevelInfo] = useState({});

  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "标题",
      dataIndex: "title",
      key: "id"
    },
    {
      title: "英文标题",
      dataIndex: "titleEn",
      key: "titleEn"
    },
    {
      title: "首字母",
      dataIndex: "firstWord",
      key: "id"
    },
    // {
    //   title: "描述",
    //   dataIndex: "detail",
    //   key: "id"
    // },
    {
      title: "操作",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" block onClick={() => editChapter(record)}>
            编辑
          </Button>
          <Popconfirm
          title="是否确认删除这条数据?"
          onConfirm={() => delChapter(record)}
          okText="确定"
          cancelText="取消"
        >
          <Button type="danger" block>
            删除
          </Button></Popconfirm>

          <Button type="danger" block onClick={() => manageTwoLevel(record)}>
            管理车系
          </Button>
          {/* <Button type="danger" block onClick={() => detailChapter(record)}>详情</Button> */}
        </Space>
      )
    }
  ];

  const manageTwoLevel = (obj) => {
    setisShowDrawer(true);
    settwoLevelInfo(obj);
  };

  const closeTwoLevel = () => {
    setisShowDrawer(false);
  };

  // 编辑 handler
  const editChapter = (obj) => {
    setnowChapterInfo(obj);
    setisModalVisible(true);
  };

  // 详情
  const detailChapter = (obj) => {
    setisShowPreview(true);
    sethtmlContent(obj.content);
  };

  // 删除
  const delChapter = (obj) => {
    delManufactorBrand({
      id: obj.id
    }).then((res) => {
      let arr = [...chapterList];
      let index = chapterList.findIndex((v) => v.id === obj.id);
      arr.splice(index, 1);
      setchapterList([...arr]);
      message.success("删除成功");
    });
  };

  // 新增
  const addDialog = () => {
    setnowChapterInfo({});
    setTimeout(() => {
      setisModalVisible(true);
    }, 0);
  };

  // 关闭Modal
  const cancle = () => {
    setisModalVisible(false);
  };

  // 列表
  const getList = () => {
    getManufactorBrandList().then((res) => {
      res.forEach((element) => {
        element.key = element.id;
      });
      setchapterList([...res]);
    });
  };

  // 监听Modal
  useEffect(() => {
    if (!isModalVisible) {
      getList();
      setnowChapterInfo({});
    }
  }, [isModalVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  // 初始化
  useEffect(() => {
    console.log("21312");
    getList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="chapterContainer">
      <EditCom
        cancle={cancle}
        isModalVisible={isModalVisible}
        chapterInfo={nowChapterInfo}
      ></EditCom>
      <TwoLevelCom
        info={twoLevelInfo}
        closeTwoLevel={closeTwoLevel}
        isShowDrawer={isShowDrawer}
      ></TwoLevelCom>
      <div className="addWrapper">
        <Button type="primary" block onClick={addDialog}>
          新增
        </Button>
      </div>
      <div className="tableList">
        <Table columns={columns} dataSource={chapterList} />
      </div>

      <Modal
        title="预览详情"
        footer={null}
        visible={isShowPreview}
        onOk={() => setisShowPreview(false)}
        onCancel={() => setisShowPreview(false)}
      >
        <div
          className=""
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        ></div>
      </Modal>
    </div>
  );
};

const TwoLevelCom = (props) => {
  const onClose = () => {
    props.closeTwoLevel();
  };

  useEffect(() => {
    console.log(props.isShowDrawer);
  });

  return (
    <div>
      <Drawer
        width={1000}
        title={props.info.title}
        placement="right"
        onClose={onClose}
        visible={props.isShowDrawer}
      >
        {props.isShowDrawer && <BrandType parentId={props.info.id}></BrandType>}
      </Drawer>
    </div>
  );
};

// 编辑

const EditCom = (props) => {
  const htmlVal = useRef("");
  const [loading, setloading] = useState(false);

  const [nowUser, setnowUser] = useState({});

  const [myform] = Form.useForm();
  myform.setFieldsValue(props.chapterInfo);

  useEffect(() => {
    setnowUser(props.chapterInfo);
  }, [props.chapterInfo]);

  const handleOk = () => {
    console.log("1");
  };

  const handleCancel = () => {
    props.cancle();
  };

  const handleEditorChange = (editorValue) => {
    let html = editorValue.toHTML();
    htmlVal.current = html;
  };

  const onFinish = (vals) => {
    console.log("vals");
    console.log(vals);
    // 分情况是编辑还是新增
    if (Object.keys(props.chapterInfo || {}).length > 0) {
      delete vals.upload;
      updateManufactorBrand({
        ...vals,
        id: props.chapterInfo.id
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          href: "",
          content: ""
        });
        message.success("更新成功");
      });
    } else {
      addManufactorBrand({
        ...vals
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          href: "",
          content: ""
        });
        message.success("新增成功");
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const beforeUpload = (file) => {
    // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    // if (!isJpgOrPng) {
    //   message.error('只能上传图片!');
    // }

    var forms = new FormData();
    var configs = {
      headers: { "Content-Type": "multipart/form-data" }
    };
    forms.append("file", file);
    setloading(true);
    axios
      .post(`${apiPerfix}/upload/qiniu`, forms, configs)
      .then((res) => {
        setloading(false);
        setnowUser({ ...nowUser, href: res });
      })
      .catch(() => {
        setloading(true);
      });
    return false;
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Modal
      title={props.chapterInfo.title ? "编辑" : "新增原厂数据"}
      footer={null}
      width={800}
      visible={props.isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {props.isModalVisible ? (
        <div className="editWrapper">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            form={{ ...myform }}
          >
            <Form.Item
              label="标题"
              name="title"
              rules={[{ required: true, message: "请输入标题!" }]}
            >
              <Input placeholder="请输入标题" />
            </Form.Item>

            <Form.Item
                label="英文标题"
                name="titleEn"
                rules={[{ required: true, message: "请输入英文标题!" }]}
            >
              <Input placeholder="请输入英文标题" />
            </Form.Item>

            <Form.Item
              label="品牌首字母"
              name="firstWord"
              rules={[{ required: true, message: "请输入品牌首字母!" }]}
            >
              <Input placeholder="请输入品牌首字母" />
            </Form.Item>
            {/*
            <Form.Item
              label="链接"
              name="upload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                name="href"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="/"
                beforeUpload={beforeUpload}
              >
                {nowUser.href ? (
                  <img
                    src={nowUser.href}
                    alt="avatar"
                    style={{
                      width: "100%"
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item> */}
            {/* <Form.Item
            label="描述"
            name="detail"
          >
            <TextArea rows={4} />
          </Form.Item> */}

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default ManufactorBrand;
