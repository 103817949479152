import './index.scss'
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { FileTextFilled, FileImageFilled, VideoCameraFilled, AndroidFilled } from '@ant-design/icons';

const UserInfo = (props) => {


  const changeType =(val) => {
    props.changeType(val)
  }

  return (
    <section className='right'>
      <div className='top'>
        <div className='position'>
          {
            props.userInfo.avatar ?
            <Avatar src={props.userInfo.avatar} size={100} />
            : 
            <Avatar size={64} icon={<UserOutlined />} />
          }
        </div>

      </div>

      {
        props.userInfo.account ? <p>{props.userInfo.account}</p> : ''
      }
      {
        props.userInfo.name ? <p>{props.userInfo.name}</p> : ''
      }
      {
        props.userInfo.phone ? <p>{props.userInfo.phone}</p> : ''
      }
      {
        props.userInfo.address ? <p>{props.userInfo.address}</p> : ''
      }
      {
        props.userInfo.autograph ? <p>{props.userInfo.autograph}</p> : ''
      }
      {
        props.userInfo.userDetail ? <p>{props.userInfo.userDetail}</p> : ''
      }

      <div className='contentList'>
      <div className='item' onClick={() => changeType(0)}>
        <FileTextFilled style={{color: "#1890ff"}} />
        <span>文章专区</span>
        </div>


        <div className='item' onClick={() => changeType(1)}>
        <FileImageFilled style={{color: "#1890ff"}} />
        <span>图片专区</span>
        </div>
        
        <div className='item' onClick={() => changeType(2)}>
        <VideoCameraFilled style={{color: "#1890ff"}} />
        <span>视频专区</span>
        </div>

        <div className='item' onClick={() => changeType(3)}>
        <AndroidFilled style={{color: "#1890ff"}} />
        <span>APP专区</span>
        </div>
      </div>
    </section>
  )
}

export default UserInfo;